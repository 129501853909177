@charset "utf-8";

/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
  &__item {
    font-size: 1.6rem;
    font-weight: 400;
    color: $font-color-base;
    padding: 13px 24px;
    text-align: center;
    width: 100%;
    &::before {
      content: "";
      border-style: solid;
      border-width: 4px 0 4px 8px;
      border-color: transparent transparent transparent $color-theme;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-top: -5px;
    }
    &:not(.active) {
      cursor: pointer;
    }
    &.active {
      background: $color-theme;
      color: #fff;
      &::before {
        border-color: transparent transparent transparent $color-blue;
      }
    }
    @include mq-desktop {
      &:hover:not(.active) {
        background: $color-blue;
      }
    }
  }
}
.tabItem {
  background: #fff;
  min-height: 190px;
  .tabList {
    display: none;
  }
  .tabList.show {
    display: block!important;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    tab
  =================================*/
  .tabMenu {
    &__item {
      font-size: 1.5rem;
      padding: 10px 18px;
    }
  }
  .tabItem {
    min-height: inherit;
  }
}