@charset "utf-8";
/*=================================
  page--article
=================================*/
@mixin singleStyle-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  p:not([class]) {
    font-size: 1.8rem;
    line-height: 1.88888;
    & + p:not([class]) {
      margin-top: 40px;
    }
  }
  h1:not([class]) {
    font-size: 3.6rem;
    font-weight: 500;
    color: $color-theme;
    line-height: 1.44444;
    margin-bottom: 30px;
  }
  h2:not([class]) {
    font-size: 3.4rem;
    font-weight: 400;
    color: #fff;
    line-height: 1.23529;
    padding: 38px 86px;
    width: calc(100% + 160px);
    background: #4aa6e5;
    border-top: 8px solid #0a73bb;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    margin-top: 78px;
    margin-bottom: 60px;
    margin-left: -80px;
  }
  h3:not([class]) {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.41666;
    background: $color-blue;
    border-top: 7px solid $color-theme;
    padding: 25px 30px;
    margin-top: 60px;
    margin-bottom: 50px;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  }
  h4:not([class]) {
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.3636;
    color: $color-theme;
    background: #fff;
    padding: 25px 0;
    margin-top: 35px;
    margin-bottom: 25px;
    border-top: 5px solid $color-theme;
  }
  h5:not([class]) {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.44444;
    background: #fff;
    padding: 8px 12px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-left: 4px solid $color-theme;
  }
  h6:not([class]) {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.44444;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
  blockquote {
    position: relative;
    padding: 40px 35px;
    margin: 30px 0;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 3px solid #ccc;
    &::before {
      content: "“";
      font-size: 8rem;
      font-weight: 700;
      line-height: 1;
      color: $color-theme;
      display: inline-block;
      font-style: normal;
      width: 80px;
      height: 50px;
      text-align: center;
      background: #fff;
      position: absolute;
      top: -28px;
      left: 0;
    }
    h4, h5, h6 {
      margin: 0 0 20px;
      font-size: 1.6rem;
      line-height: 1.75;
      letter-spacing: .06em;
      position: relative;
      &::after {
        content: "";
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        display: inline-block;
        height: 10px;
        background-image: repeating-linear-gradient(-45deg, $color-blue, $color-theme 1px, transparent 2px, transparent 5px);
        background-size: 7px 7px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]) {
    display: block;
    margin: 15px auto 20px;
  }
  ul:not([class]) {
    margin-top: 30px;
    margin-bottom: 50px;
    li {
      font-size: 1.8rem;
      font-weight: 400;
      &::before {
        content: "";
        width: 13px;
        height: 3px;
        display: inline-block;
        background: $color-theme;
        margin: 0 15px 3px 0;
      }
      & + li {
        margin-top: 15px;
      }
    }
    h6::before {
      content: none;
    }
  }
  ol:not([class]) {
    margin-top: 30px;
    margin-bottom: 50px;
    counter-reset: listNum;
    li {
      font-size: 1.8rem;
      font-weight: 400;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        @include font-roboto;
        font-size: 1.5rem;
        width: 22px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        background: $color-theme;
        display: inline-block;
        border-radius: 50%;
        margin: 0 15px 3px 0;
      }
      & + li {
        margin-top: 15px;
      }
    }
    h6::before {
      content: none;
    }
  }
  @include mq-sp {
    p:not([class]) {
      font-size: 1.5rem;
      line-height: 1.5;
      & + p:not([class]) {
        margin-top: 15px;
      }
    }
    h1:not([class]) {
      font-size: 2.1rem;
      line-height: 1.2380;
      margin-bottom: 15px;
    }
    h2:not([class]) {
      font-size: 2rem;
      line-height: 1.3;
      padding: 15px;
      width: calc(100% + 8%);
      border-top: 5px solid #0a73bb;
      margin-top: 40px;
      margin-bottom: 30px;
      margin-left: -4%;
    }
    h3:not([class]) {
      font-size: 1.6rem;
      line-height: 1.3125;
      border-top: 4px solid $color-theme;
      padding: 15px;
      margin-top: 35px;
      margin-bottom: 33px;
    }
    h4:not([class]) {
      font-size: 1.5rem;
      line-height: 1.30769;
      padding: 12px 0;
      margin-top: 35px;
      margin-bottom: 25px;
      border-top: 4px solid $color-theme;
    }
    h5:not([class]) {
      font-size: 1.5rem;
      padding: 3px 10px;
    }
    h6:not([class]) {
      font-size: 1.5rem;
      padding-left: 15px;
      &::before {
        width: 8px;
        height: 8px;
      }
    }
    blockquote {
      padding: 25px 3% 20px;
      margin: 20px 0;
      &::before {
        font-size: 6rem;
        width: 50px;
        top: -22px;
      }
      h4, h5, h6 {
        margin: 0 0 15px;
        font-size: 1.4rem;
        line-height: 1.2;
        &::after {
          height: 7px;
        }
      }
    }
    img:not([class]) {
      display: block;
      margin: 15px auto;
    }
    ul:not([class]) {
      margin-bottom: 15px;
      padding: 15px 3%;
      li {
        font-size: 1.5rem;
        &::before {
          width: 10px;
          margin: 0 10px 3px 0;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
    ol:not([class]) {
      margin-bottom: 15px;
      padding: 15px 3%;
      li {
        font-size: 1.5rem;
        &::before {
          font-size: 1.4rem;
          margin: 0 8px 3px 0;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}
@mixin singleStyle-parts {
  // 目次
  .tocBox {
    margin: 70px 0 75px;
    padding: 40px;
    background: url(../img/toc_bg.jpg) repeat left top;
    &__inner {
      background: #fff;
      padding: 30px 38px;
    }
    &__ttl {
      font-size: 2.4rem;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .tocList {
      counter-reset: tocNum;
      &__item {
        position: relative;
        &::before {
          counter-increment: tocNum;
          content: counter(tocNum)".";
          font-size: 1.4rem;
          font-weight: 400;
          display: inline-block;
          position: absolute;
          top: 10px;
          left: 0;
        }
        a {
          font-size: 1.6rem;
          color: $font-color-base;
          text-decoration: none;
          display: block;
          padding: 8px 24px;
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .tocList {
          counter-reset: tocChildNum;
          padding-left: 26px;
          &__item {
            &::before {
              counter-increment: tocChildNum;
              content: counter(tocNum) " - " counter(tocChildNum)".";
            }
            a {
              padding-left: 36px;
            }
          }
        }
      }
    }
    .tocMore {
      font-size: 1.5rem;
      font-weight: 500;
      color: #fff;
      background: $color-theme;
      border: 1px solid $color-theme;
      text-align: center;
      margin-top: 20px;
      padding: 8px 16px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        content: "";
        width: 12px;
        height: 8px;
        display: inline-block;
        background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
        background-size: contain;
        margin-left: 10px;
        transform: rotate(180deg);
      }
      @include mq-desktop {
        &:hover {
          color: $color-theme;
          background: #fff;
          &::after {
            background: url(../img/icon/icon_arrow_b.png) no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
    &:not(.open) {
      .tocBox__inner {
        > .tocList > .tocList__item:nth-child(n + 4) {
          display: none;
        }
        > .tocList > .tocList__item > .tocList > .tocList__item:nth-child(n + 4) {
          display: none;
        }
      }
      .tocMore {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
  .supervised {
    border: 1px solid #aacce6;
    max-width: 818px;
    padding: 16px 48px;
    margin: 28px auto 0;
    clear: both;
    .thumbWrap {
      width: 104px;
      height: 104px;
      border-radius: 50px;
      overflow: hidden;
      margin-right: 36px;
      img {
        width: 100%;
      }
    }
    &__info {
      display: flex;
    }
    &__cont {
      width: calc(100% - 140px);
    }
    &__label {
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      background: $color-blue;
      margin-bottom: 12px;
      padding: 3px;
    }
    &__name {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.55555;
      text-align: center;
    }
    &__text {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.55555;
      padding: 15px;
      margin: 16px 0 0;
      background: #f3f3f3;
    }
    .btn {
      margin: 20px auto 0;
      text-align: center;
      a {
        font-size: 1.8rem;
        color: #fff;
        background: $color-theme;
        text-decoration: none;
        padding: 12px 40px;
        display: inline-block;
        &::before {
          content: "";
          width: 24px;
          height: 24px;
          display: inline-block;
          background: url(../img/link_arrow_w.png) no-repeat center center;
          background-size: contain;
          vertical-align: middle;
          margin-right: 8px;
        }
        @include mq-desktop {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
  .infoWrap {
    margin-bottom: 26px;
    .data {
      display: flex;
      .date {
        font-size: 1.8rem;
        padding-left: 20px;
        background: url(../img/icon/icon_clock.png) no-repeat left center;
        background-size: 16px auto;
        & + .date {
          margin-left: 1rem;
          background-size: 16px auto;
        }
      }
    }
  }
  .articleMv {
    margin: 30px auto 60px;
    text-align: center;
    .caption {
      font-size: 1.6rem;
      color: #545454;
      margin-top: 25px;
    }
    &.cover {
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(230, 230, 230, 0.6);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        width: 100%;
        margin: 0;
      }
      .understand {
        width: 100%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }
  //この記事で分かること
  .understand {
    margin: 60px auto 30px;
    text-align: center;
    p {
      font-size: 3.4rem;
      font-weight: 500;
    }
  }
  .lead {
    font-size: 2.4rem;
    line-height: 1.4166;
    color: $color-theme;
    margin: 0 0 30px;
  }
  .point {
    background: #feefef;
    border: 1px solid #fdbec0;
    padding: 30px 40px;
    margin: 70px auto 60px;
    position: relative;
    &__ttl {
      font-size: 1.8rem;
      font-weight: 500;
      color: #f84444;
      background: #fff;
      border: 1px solid #f84444;
      padding: 10px 40px;
      position: absolute;
      top: -30px;
      left: 38px;
    }
    &__text {
      font-size: 1.8rem;
      line-height: 1.77777;
    }
  }
  .related {
    background: $color-blue;
    border: 1px solid #aacce6;
    padding: 30px 40px;
    margin: 70px auto 60px;
    position: relative;
    &__ttl {
      font-size: 1.8rem;
      font-weight: 500;
      color: $color-theme;
      background: #fff;
      border: 1px solid $color-theme;
      padding: 10px 40px;
      position: absolute;
      top: -30px;
      left: 38px;
    }
    &__text {
      font-size: 1.8rem;
      line-height: 1.77777;
    }
  }
  .related--simple {
    margin: 70px auto;
    border: 3px solid #b9dbf4;
    padding: 45px 37px;
    position: relative;
    &__ttl {
      font-size: 1.8rem;
      font-weight: 500;
      color: $color-theme;
      background: $color-blue;
      border: 1px solid $color-theme;
      padding: 10px 40px;
      position: absolute;
      top: -30px;
      left: 38px;
    }
    &__cont {
      display: flex;
      align-items: flex-start;
      .thumb {
        width: 300px;
        margin-right: 40px;
      }
      .textWrap {
        width: calc(100% - 340px);
        &__ttl {
          font-size: 2.6rem;
          font-weight: 500;
          color: $color-theme;
          line-height: 1.3846;
          text-decoration: none;
          display: block;
          margin-bottom: 25px;
        }
        &__text {
          font-size: 1.8rem;
          line-height: 1.8888;
        }
      }
      .btn {
        text-align: right;
        .text {
          font-size: 1.8rem;
          font-weight: 600;
          color: #fff;
          background: $color-theme;
          display: inline-block;
          text-decoration: none;
          padding: 16px 45px;
          margin-top: 28px;
          &::before {
            content: "";
            width: 24px;
            height: 24px;
            display: inline-block;
            background: url(../img/link_arrow_w.png) no-repeat center center;
            background-size: contain;
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }
    }
    @include mq-desktop {
      &:hover {
        .btn {
          .text {
            opacity: .8;
          }
        }
      }
    }
  }
  .relatedBox {
    max-width: 820px;
    margin: 65px auto 80px;
    &__ttl {
      font-size: 3.4rem;
      font-weight: 500;
      color: $color-theme;
      text-align: center;
      margin-bottom: 60px;
    }
  }
  @include mq-sp {
    // 目次
    .tocBox {
      margin: 35px 0 40px;
      padding: 20px;
      &__inner {
        padding: 25px 15px;
      }
      &__ttl {
        font-size: 1.6rem;
        margin-bottom: 25px;
      }
      .tocList {
        &__item {
          &::before {
            top: 8px;
          }
          a {
            font-size: 1.4rem;
            padding: 8px 0 8px 20px;
            position: relative;
          }
        }
      }
      .tocMore {
        font-size: 1.3rem;
        margin-top: 15px;
        padding: 5px 8px;
      }
    }
    .supervised {
      padding: 17px 20px;
      margin: 28px auto 0;
      .thumbWrap {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        position: absolute;
        top: 0;
        left: 0;
      }
      &__info {
        flex-wrap: wrap;
        position: relative;
      }
      &__cont {
        width: 100%;
      }
      &__name {
        font-size: 1.5rem;
        padding: 15px 0 0 100px;
        min-height: 80px;
      }
      &__text {
        font-size: 1.5rem;
      }
      .btn {
        margin: 12px 0 0;
        a {
          font-size: 1.2rem;
          padding: 10px 25px;
          &::before {
            width: 17px;
            height: 17px;
            margin-right: 6px;
          }
        }
      }
    }
    .infoWrap {
      margin-bottom: 30px;
      .data {
        .date {
          font-size: 1.4rem;
          padding-left: 17px;
          background-size: 12px auto;
          & + .date {
            background-size: 12px auto;
          }
        }
      }
    }
    .articleMv {
      margin: 30px auto 45px;
      .caption {
        font-size: 1.2rem;
        margin-top: 15px;
      }
      &.cover {
        .understand {
          text-align: left;
          padding: 3rem 3% 0;
        }
      }
    }
    //この記事で分かること
    .understand {
      margin: 45px auto 35px;
      p {
        font-size: 2.1rem;
      }
    }
    .lead {
      font-size: 1.5rem;
      line-height: 1.4;
    }
    .point {
      padding: 25px 20px 20px;
      margin: 40px auto 35px;
      &__ttl {
        font-size: 1.4rem;
        padding: 6px 18px;
        top: -19px;
        left: 19px;
      }
      &__text {
        font-size: 1.3rem;
      }
    }
    .related {
      padding: 25px 20px 20px;
      margin: 40px auto 35px;
      &__ttl {
        font-size: 1.4rem;
        padding: 6px 18px;
        top: -19px;
        left: 19px;
      }
      &__text {
        font-size: 1.3rem;
      }
    }
    .related--simple {
      margin: 40px auto 30px;
      border: 2px solid #b9dbf4;
      padding: 35px 20px 15px;
      &__ttl {
        font-size: 1.4rem;
        padding: 6px 18px;
        top: -19px;
        left: 19px;
      }
      &__cont {
        .thumb {
          width: 125px;
          margin-right: 10px;
        }
        .textWrap {
          width: calc(100% - 135px);
          &__ttl {
            font-size: 1.5rem;
            line-height: 1.5384;
            margin-bottom: 0;
          }
          &__text {
            display: none;
          }
        }
        .btn {
          text-align: center;
          .text {
            font-size: 1.2rem;
            padding: 10px 20px;
            margin-top: 10px;
            &::before {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .relatedBox {
      margin: 37px auto 60px;
      &__ttl {
        font-size: 2rem;
        margin-bottom: 30px;
      }
    }
  }
}
/* singlePage */
.singlePage {
  &__in {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0 100px;
    background: #fff;
    @include singleStyle-base;
    h2:not([class]) {
      width: 100%;
      margin-left: 0;
      padding-left: 30px;
      padding-right: 30px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  @include singleStyle-parts;
}
/* 記事詳細ページ */
.articleDetail {
  padding-bottom: 95px;
  background: #f9f9f9;
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    padding: 60px 80px 100px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    border-top: 9px solid $color-theme;
    @include singleStyle-base;
    .cvBnr {
      margin: 80px auto;
    }
  }
  @include singleStyle-parts;
  .komon {
    background: transparent;
    margin: 65px auto;
    padding: 60px 0!important;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    .secWrap__inner {
      max-width: inherit;
    }
  }
}
/* 記事一覧ページ */
.articleWrap {
  padding-bottom: 95px;
  background: #f9f9f9;
  .articleList {
    margin-top: 80px;
  }
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    padding: 60px 5.5% 100px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    border-top: 9px solid $color-theme;
    @include singleStyle-base;
  }
  @include singleStyle-parts;
}

@media screen and (max-width: $display-width-s) {
    /* singlePage */
  .singlePage {
    &__in {
      padding: 10px 4% 40px;
      & > {
        h2 {
          width: calc(100% + 8%);
          margin-left: -4%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding-bottom: 0;
    &__in {
      padding: 35px 4% 40px;
      border-top: 5px solid $color-theme;
      .cvBnr {
        margin: 50px auto;
      }
    }
    .komon {
      margin: 30px auto;
      padding: 35px 0!important;
    }
  }
  /* 記事一覧ページ */
  .articleWrap {
    padding-bottom: 0;
    .articleList {
      margin-top: 35px;
    }
    &__in {
      padding: 35px 4% 40px;
      border-top: 5px solid $color-theme;
    }
  }
}