@charset "utf-8";
/*=================================
  overview
=================================*/
.overview {
  padding: 75px 1% 80px;
  background: url(../img/img_overview_pc.png) no-repeat right bottom $color-blue;
  background-size: 42% auto;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__cont {
    width: 60%;
  }
  .ttl--base {
    display: inline-block;
    border-bottom: 4px solid $color-theme;
    letter-spacing: .1em;
    padding: 0 0 12px;
  }
  p + p {
    margin-top: 40px;
  }
  .overviewTable {
    background: none;
    box-shadow: none;
    tr {
      &:not(:first-child) {
        border-top: 1px solid $color-theme;
      }
      &:first-child {
        border-top: 1px solid $color-theme;
      }
      &:last-child {
        border-bottom: 1px solid $color-theme;
      }
    }
    th {
      font-weight: 500;
      color: $color-theme;
      background: none;
      border-right: none;
      padding: 20px 15px;
      width: 8em;
      &::after {
        content: none!important;
      }
    }
    td {
      text-align: left;
      padding: 20px 15px;
    }
  }
}
@media screen and (min-width: 1700px) {
  /*=================================
    overview
  =================================*/
  .overview {
    background: url(../img/img_overview_pc.png) no-repeat right top $color-blue;
    background-size: 40% auto;
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    overview
  =================================*/
  .overview {
    padding: 35px 5% 30px;
    background: $color-blue;
    position: relative;
    &::before {
      content: "";
      width: 170px;
      height: 206px;
      display: inline-block;
      background: url(../img/img_overview_sp.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 1%;
    }
    &__cont {
      width: 100%;
    }
    .ttl--base {
      font-size: 2.2rem;
      text-align: left;
      padding: 50px 0;
    }
    p + p {
      margin-top: 30px;
    }
    .overviewTable {
      display: table;
      white-space: unset;
      margin-top: 50px;
      th {
        padding: 15px 10px;
      }
      td {
        padding: 15px 10px;
      }
    }
  }
}