@charset "utf-8";

/*=================================
  pager
=================================*/
.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0 20px;
  &List {
    display: flex;
    &__item {
      margin-right: 6px;
      a,span {
        @include font-roboto;
        font-size: 2rem;
        line-height: 1;
        padding: 12px 14px;
        display: block;
      }
      a {
        color: $font-color-base;
        text-decoration: none;
        background: #f6f6f6;
        @include mq-desktop {
          &:hover {
            color: #fff;
            background: $color-theme;
          }
        }
      }
      span {
        color: #fff;
        background: $color-theme;
      }
    }
  }
  .output {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    padding: 20px 0 10px;
    &List {
      justify-content: center;
      &__item {
        margin-right: 10px;
        a,span {
          font-size: 1.5rem;
          padding: 10px 11px;
        }
      }
    }
    .output {
      font-size: 1.3rem;
      margin-top: 25px;
    }
  }
}