@charset "utf-8";
/*=================================
  access
=================================*/
.access {
  padding: 84px 0 0;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .imgList {
    display: flex;
    justify-content: center;
    margin: 0 auto 60px;
  }
  .gMap {
    height: 600px;
    overflow: hidden;
    position: relative;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    access
  =================================*/
  .access {
    padding: 38px 0 0;
    &__inner {
      padding: 0 3% 0;
    }
    .imgList {
      margin: 0 auto 25px;
    }
    .gMap {
      height: 350px;
    }
  }
}