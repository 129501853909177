@charset "utf-8";
/*=================================
  下層ページ共通
=================================*/
/* 記事一覧 */
.articleList {
  &__item {
    border: 1px solid #92bddd;
    padding: 2.5% 3%;
    .itemWrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .thumb {
        width: 40%;
        max-width: 400px;
        margin-right: 4%;
        img {
          margin: 0 auto;
        }
        & + .textWrap {
          width: calc(60% - 4%);
        }
      }
      .textWrap {
        width: 100%;
        &__ttl {
          font-size: 2.6rem;
          font-weight: 500;
          line-height: 1.3846;
          text-decoration: none;
          display: block;
          margin-bottom: 25px;
        }
        &__text {
          font-size: 1.8rem;
        }
      }
    }
    & + .articleList__item {
      margin-top: 20px;
    }
  }
  &--simple {
    .articleList__item {
      padding: 2%;
      .thumb {
        width: 290px;
        margin-right: 25px;
        img {
          margin: 0 auto;
        }
      }
      .textWrap {
        width: calc(100% - 315px);
        &__ttl {
          font-size: 2.2rem;
          line-height: 1.454545;
          margin-bottom: 25px;
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.5;
        }
      }
    }
  }
}
/* その他のお悩み事例 */
.otherCase {
  background: #f9f9f9;
  padding: 40px;
  margin: 70px auto;
  &__inner {
    box-shadow: 0px 0px 21.25px 3.75px rgba(11, 3, 6, 0.05);
    background: #fff;
  }
  &__ttl {
    font-size: 2.2rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: 22px 15px;
    background: $color-theme;
    &::before {
      content: "";
      width: 25px;
      height: 19px;
      display: inline-block;
      background: url(../img/icon/icon_check.png) no-repeat center center;
      background-size: contain;
      margin-right: 12px;
    }
  }
  &List {
    padding: 24px;
    &__item {
      a {
        font-size: 1.6rem;
        color: $font-color-base;
        text-decoration: none;
        &::before {
          content: "";
          width: 19px;
          height: 19px;
          display: inline-block;
          background: url(../img/link_arrow.png) no-repeat center center;
          background-size: contain;
          margin-right: 8px;
          vertical-align: middle;
        }
        @include mq-desktop {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      & + .otherCaseList__item {
        margin-top: 15px;
      }
    }
  }
}
/* メリット */
.pushBox {
  width: calc(100% + 160px);
  margin-left: -80px;
  margin-top: 50px;
  .merit {
    padding: 50px 120px 45px;
    background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom 30px;
    position: relative;
    &__inner {
      background: url(../img/merit_bg_pc.jpg) no-repeat center center;
      background-size: contain;
    }
    &__ttl {
      font-size: 3.4rem;
      font-weight: 400;
      color: #fff;
      text-align: center;
      margin-bottom: 40px;
      .num {
        @include font-roboto;
        font-size: 4.4rem;
        color: #ffea00;
      }
    }
    &List {
      text-align: center;
      padding: 7% 10px;
      min-height: 500px;
      max-width: 555px;
      margin: 0 auto;
      &__item {
        font-size: 3rem;
        font-weight: 500;
        color: $color-theme;
        display: inline-block;
        &::before {
          content: "";
          width: 28px;
          height: 24px;
          display: inline-block;
          background: url(../img/icon/icon_check_b.png) no-repeat center center;
          background-size: contain;
          vertical-align: middle;
        }
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          display: block;
          background: $color-theme;
        }
        & + .meritList__item {
          margin-top: 10px;
        }
      }
    }
    &::after {
      content: "";
      width: 235px;
      height: 430px;
      display: inline-block;
      background: url(../img/merit_img.png) no-repeat center bottom;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 60px;
    }
  }
  .cvBnr {
    &__ttl {
      padding: 30px;
    }
    .contact {
      padding: 16px 30px 25px 30px;
    }
    &::after {
      width: 31%;
      height: 346px;
      background: url(../img/cvBnr_img02.png) no-repeat center bottom;
      background-size: contain;
      right: 10px;
    }
    @include mq-tab {
      &::after {
        content: none;
      }
    }
  }
  .pushLink {
    padding: 70px 80px 0;
    &__ttl {
      text-align: center;
      .text {
        font-size: 3.4rem;
        font-weight: 500;
        padding-bottom: 5px;
        margin-bottom: 35px;
        display: inline-block;
        border-bottom: 3px solid $color-theme;
      }
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 20px) / 2);
        margin-top: 20px;
        position: relative;
        &:not(:nth-child(2n - 1)) {
          margin-left: 20px;
        }
        .img {
          img {
            width: 100%;
          }
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: rgba(0, 84, 142, .7);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            @include animation-base;
          }
        }
        .text {
          font-size: 2.2rem;
          line-height: 1.454545;
          font-weight: 500;
          color: #fff;
          text-decoration: none;
          text-align: center;
          width: 100%;
          padding: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        @include mq-desktop {
          &:hover {
            .img {
              &::after {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                background: rgba(0, 84, 142, .5);
              }
            }
          }
        }
      }
    }
  }
}

.accessWrap {
  display: flex;
  gap: 40px;
  margin-top: 50px;
}
.accessItem {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  &__ttl {
    font-size: 1.7rem;
    font-weight: 600;
    border-bottom: 4px solid $color-theme;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
  &__directions {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  &__gMap {
    margin-top: auto;
    iframe {
      max-height: 260px;
    }
    &.tokyo {
      position: relative;
      padding-top: 260px;
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        max-height: initial;
      }
    }
  }
}

@media screen and (min-width: $display-width-s) and (max-width: 1180px) {
  /* メリット */
  .pushBox {
    .merit {
      &List {
        padding: 4% 10px;
        min-height: inherit;
        &__item {
          font-size: 1.8rem;
          & + .meritList__item {
            margin-top: 8px;
          }
        }
      }
      &::after {
        width: 180px;
        height: 338px;
        left: 30px;
      }
    }
    .pushLink {
      padding: 50px 40px 0;
      &__ttl {
        .text {
          font-size: 2.8rem;
          margin-bottom: 25px;
        }
      }
      &List {
        &__item {
          width: calc((100% - 10px) / 2);
          margin-top: 10px;
          &:not(:nth-child(2n - 1)) {
            margin-left: 10px;
          }
          .text {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    下層ページ共通
  =================================*/
  /* 記事一覧 */
  .articleList {
    &__item {
      padding: 3.5% 3.5%;
      .itemWrap {
        align-items: flex-start;
        .thumb {
          width: 30%;
          max-width: 100%;
          margin-right: 3%;
          & + .textWrap {
            width: calc(70% - 3%);
          }
        }
        .textWrap {
          &__ttl {
            font-size: 1.4rem;
            margin-bottom: 0;
          }
          &__text {
            display: none;
          }
        }
      }
      & + .articleList__item {
        margin-top: 15px;
      }
    }
  }
  /* その他のお悩み事例 */
  .otherCase {
    padding: 15px;
    width: calc(100% + 8%);
    margin: 37px auto;
    margin-left: -4%;
    &__ttl {
      font-size: 1.5rem;
      padding: 12px 10px;
      &::before {
        width: 15px;
        height: 12px;
        margin-right: 5px;
      }
    }
    &List {
      padding: 20px 13px;
      &__item {
        a {
          font-size: 1.4rem;
          padding-left: 18px;
          display: block;
          position: relative;
          &::before {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
        & + .otherCaseList__item {
          margin-top: 10px;
        }
      }
    }
  }
  /* メリット */
  .pushBox {
    width: calc(100% + 8%);
    margin-left: -4%;
    .merit {
      padding: 30px 0;
      background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom;
      background-size: 80% auto;
      &__inner {
        background: url(../img/merit_bg_sp.jpg) no-repeat center center;
        background-size: cover;
      }
      &__ttl {
        font-size: 2rem;
        margin-bottom: 28px;
        .num {
          font-size: 2.6rem;
        }
      }
      &List {
        padding: 6% 10px;
        min-height: 250px;
        max-width: inherit;
        &__item {
          font-size: 1.7rem;
          &::before {
            width: 16px;
            height: 14px;
          }
          & + .meritList__item {
            margin-top: 8px;
          }
        }
      }
      &::after {
        content: none;
      }
    }
    .cvBnr {
      &__ttl {
        padding: 15px 15px 15px 90px;
        &::after {
          width: 90px;
          height: 165px;
          background: url(../img/merit_img.png) no-repeat center bottom;
          background-size: contain;
          right: inherit;
          left: -10px;
        }
      }
      .contact {
        padding: 0;
      }
    }
    .pushLink {
      padding: 40px 8% 0;
      &__ttl {
        .text {
          font-size: 1.9rem;
          margin-bottom: 18px;
          border-bottom: 2px solid $color-theme;
        }
      }
      &List {
        display: block;
        &__item {
          width: 100%;
          margin-top: 12px;
          &:not(:nth-child(2n - 1)) {
            margin-left: 0;
          }
          .text {
            font-size: 1.4rem;
            line-height: 1.4230;
          }
        }
      }
    }
  }
  .accessWrap {
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
  }
  .accessItem {
    width: 100%;
    display: block;
    &__ttl {
      font-size: 1.6rem;
      border-bottom: 3px solid $color-theme;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }
    &__directions {
      margin-bottom: 20px;
    }
  }
}