@charset "utf-8";

/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  padding: 0 1%;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  &__inner {
    max-width: $base-width;
    padding: 10px 0;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .ttl--site {
    max-width: 303px;
    display: block;
    margin-right: auto;
    @include mq-desktop {
      &:hover {
        .logo {
          opacity: .8;
        }
      }
    }
  }
  .contact {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    &__wrap {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      & + .contact__wrap {
        margin-right: 0;
      }
    }
    &__ttl {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.125;
      color: #3f3f3f;
      border-radius: 50px;
      width: 70px;
      text-align: center;
      .num {
        @include font-roboto;
        font-size: 2rem;
        font-weight: 600;
      }
    }
    &__cont {
      display: inline-block;
    }
    .tel {
      .contact__ttl {
        background: #e4f4ff;
        padding: 8px 0;
        margin-right: 4px;
        min-height: 70px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .national {
          font-size: 1.1rem;
          font-weight: 700;
          line-height: 1;
          color: $color-pink;
          display: block;
        }
      }
      .telNum {
        @include font-roboto;
        font-size: 3.8rem;
        font-weight: 500;
        line-height: 1;
        color: $color-theme;
        text-decoration: none;
        display: block;
        &::before {
          content: "";
          width: 25px;
          height: 25px;
          display: inline-block;
          background: url(../img/icon/icon_phone.png) no-repeat center center;
          background-size: contain;
          margin-right: 2px;
        }
      }
      .telText {
        font-size: 1.4rem;
        line-height: 1;
        color: #484848;
        display: block;
        width: 100%;
        margin-left: 30px;
      }
    }
    .reservation {
      .contact__ttl {
        background: #faeacf;
        padding: 11px 0;
        margin-right: 16px;
      }
      .btn {
        &--mail {
          font-size: 1.8rem;
          font-weight: 600;
          color: #fff;
          background: #f4b038;
          box-shadow: 0 5px 0 #e49102;
          border-radius: 5px;
          text-decoration: none;
          padding: 14px 20px;
          display: inline-block;
          vertical-align: middle;
          transition: none;
          &::before {
            content: "";
            width: 25px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/icon_mail.png) no-repeat center center;
            background-size: contain;
            margin-right: 6px;
            vertical-align: middle;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
        }
        &--line {
          font-size: 1.8rem;
          font-weight: 600;
          color: #fff;
          background: #19d24e;
          box-shadow: 0 5px 0 #15b944;
          border-radius: 5px;
          text-decoration: none;
          padding: 14px 20px;
          display: inline-block;
          vertical-align: middle;
          transition: none;
          &::before {
            content: "";
            width: 29px;
            height: 28px;
            display: inline-block;
            background: url(../img/icon/icon_line.png) no-repeat center center;
            background-size: contain;
            margin-right: 6px;
            vertical-align: middle;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
        }
      }
      a + a {
        margin-left: 8px;
      }
    }
  }
  .contactBtn {
    display: flex!important;
    gap: 8px;
    margin: 0 10px 0 5px;
    .mail {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      background: #f4b038;
      box-shadow: 0 5px 0 #e49102;
      border-radius: 5px;
      text-decoration: none;
      width: 60px;
      height: 60px;
      padding: 12px 0;
      display: block;
      transition: none;
      text-align: center;
      &::before {
        content: "";
        width: 22px;
        height: 17px;
        display: block;
        background: url(../img/icon/icon_mail.png) no-repeat center center;
        background-size: contain;
        margin: 0 auto 8px;
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(5px);
        }
      }
    }
    .line {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      background: #19d24e;
      box-shadow: 0 5px 0 #15b944;
      border-radius: 5px;
      text-decoration: none;
      width: 60px;
      height: 60px;
      padding: 12px 0;
      display: block;
      transition: none;
      text-align: center;
      &::before {
        content: "";
        width: 24px;
        height: 24px;
        display: block;
        background: url(../img/icon/icon_line.png) no-repeat center center;
        background-size: contain;
        text-align: center;
        margin: 0 auto 2px;
      }
      @include mq-desktop {
        &:hover {
          box-shadow: none;
          transform: translateY(5px);
        }
      }
    }
  }
  .kuchikomi {
    img {
      box-shadow: 0px 1px 8px 0px rgba(27, 27, 27, 0.15);
    }
    a {
      @include mq-desktop {
        &:hover {
          opacity: .8;
        }
      }
    }
  }
  .pc {
    display: inline-block;
  }
  .sp {
    display: none;
  }
  .gNav {
    background: #fff url(../img/gNav_line.png) no-repeat center top;
    text-align: center;
    &List {
      max-width: $base-width;
      margin: 0 auto;
      &__item {
        display: inline-block;
        a {
          font-size: 1.8rem;
          font-weight: 500;
          color: $font-color-base;
          padding: 18px 20px;
          text-decoration: none;
          display: block;
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 0;
            display: block;
            background: $color-theme;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            @include animation-base;
          }
          @include mq-desktop {
            &:hover {
              color: $color-theme;
              &::after {
                height: 4px;
              }
            }
          }
        }
        &.current-menu-item {
          a {
            color: $color-theme;
            &::after {
              height: 4px;
            }
          }
        }
      }
    }
  }
}
// .home {
//   .gNav {
//     display: none;
//   }
// }
@media screen and (min-width: 920px) {
  .gNav {
    &.fixedNav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
      background: #fff;
      z-index: 999;
    }
  }
}
@media screen and (min-width: 921px) and (max-width: 1200px) {
  /* =================================
    header
  ================================= */
  .header {
    .ttl--site {
      max-width: 180px;
    }
    .contact {
      padding: 0 10px;
      &__ttl {
        font-size: 1.2rem;
        width: 70px;
      }
      .tel {
        .contact__ttl {
          min-height: 70px;
        }
        .telNum {
          font-size: 3rem;
          &::before {
            width: 22px;
            height: 22px;
          }
        }
        .telText {
          font-size: 1.4rem;
          margin-left: 28px;
        }
      }
      .reservation {
        .contact__ttl {
          padding: 9px 0;
          margin-right: 10px;
        }
        .btn {
          &--mail {
            font-size: 1.5rem;
            box-shadow: 0 3px 0 #e49102;
            padding: 10px 12px;
            &::before {
              width: 20px;
              height: 15px;
            }
          }
          &--line {
            font-size: 1.5rem;
            box-shadow: 0 3px 0 #15b944;
            padding: 10px 12px;
            &::before {
              width: 22px;
              height: 21px;
            }
          }
        }
        a + a {
          margin-left: 5px;
        }
      }
    }
    .contactBtn {
      .mail {
        font-size: 1rem;
        box-shadow: 0 3px 0 #e49102;
        width: 45px;
        height: 45px;
        padding: 10px 0;
        &::before {
          width: 18px;
          height: 13px;
          margin: 0 auto 5px;
        }
        @include mq-desktop {
          &:hover {
            transform: translateY(3px);
          }
        }
      }
      .line {
        font-size: 1rem;
        box-shadow: 0 3px 0 #15b944;
        width: 45px;
        height: 45px;
        padding: 6px 0;
        &::before {
          width: 20px;
          height: 20px;
          display: block;
          margin: 0 auto 3px;
        }
        @include mq-desktop {
          &:hover {
            transform: translateY(3px);
          }
        }
      }
    }
    .kuchikomi {
      max-width: 250px;
    }
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    position: fixed;
    padding: 0 2.5%;
    z-index: 99;
    &__inner {
      width: calc(100% - 45px);
      padding: 10px 0;
      margin: 0;
      position: relative;
    }
    .ttl--site {
      max-width: initial;
      width: 40%;
      margin: 0;
    }
    .contactBtn {
      display: none!important;
    }
    .kuchikomi {
      width: 58%;
      margin-left: 1%;
    }
    .contact {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
      &__wrap {
        display: block;
        margin-right: 0;
        margin-bottom: 0;
      }
      &__ttl {
        display: none!important;
      }
      &__cont {
        display: flex;
        align-items: flex-start;
        margin-left: 7px;
      }
      .tel {
        .telNum {
          font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: 1.2rem;
          color: #fff;
          padding: 10px 0 7px;
          border-radius: 5px;
          background: #47adf3;
          box-shadow: 0 4px 0 $color-theme;
          text-align: center;
          width: 52px;
          &::before {
            width: 17px;
            height: 17px;
            display: block;
            background: url(../img/icon/icon_phone_w.png) no-repeat center center;
            background-size: contain;
            margin: 0 auto 5px;
          }
        }
        .telText {
          display: none;
        }
      }
      .reservation {
        .btn {
          &--mail {
            font-size: 1.2rem;
            line-height: 1;
            box-shadow: 0 4px 0 #e49102;
            padding: 12px 0 7px;
            text-align: center;
            width: 52px;
            &::before {
              width: 17px;
              height: 14px;
              display: block;
              margin: 0 auto 6px;
            }
          }
          &--line {
            font-size: 1.2rem;
            line-height: 1;
            box-shadow: 0 4px 0 #15b944;
            padding: 10px 0 6px;
            text-align: center;
            width: 52px;
            &::before {
              width: 20px;
              height: 18px;
              display: block;
              margin: 0 auto 5px;
            }
          }
        }
        a + a {
          margin-left: 7px;
        }
      }
    }
    .pc {
      display: none;
    }
    .sp {
      display: block;
      line-height: 1;
    }
    .gNav {
      width: 80%;
      background: #fff url(../img/burger_bg.png) repeat-x center bottom -15px;
      background-size: contain;
      overflow-y: scroll;
      max-height: 80vh;
      position: fixed;
      top: 35px;
      left: 50%;
      margin: 0;
      padding: 35px 5% 150px;
      z-index: 9998;
      text-align: left;
      transform: translateX(-50%) scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &__ttl {
        @include font-roboto;
        font-size: 2rem;
        font-weight: 500;
        color: $color-theme;
      }
      &List {
        &__item {
          width: 100%;
          display: block;
          margin-top: 10px;
          a {
            font-size: 1.5rem;
            padding: 10px 10px 10px 40px;
            border: 1px solid $color-theme;
            color: $color-theme;
            background: #fff;
            &::after {
              width: 17px;
              height: 17px;
              display: inline-block;
              background: url(../img/btn_arrow.png) no-repeat center center;
              background-size: contain;
              top: 50%;
              bottom: inherit;
              left: 15px;
              transform: translate(0, -50%);
            }
          }
          &.current-menu-item {
            a {
              color: #fff;
              background: $color-theme;
              &::after {
                background: url(../img/arrow_btn.png) no-repeat center center;
                background-size: contain;
                height: 17px;
              }
            }
          }
        }
      }
      &.show {
        transform: translateX(-50%) scale(1);

        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: translateX(-50%) scale(.7);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover{
      height: 0;
      width: 100vw;
      background: url(../img/burgerCover_bg.jpg) no-repeat center center;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 9997;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 55px;
      height: 55px;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // left: -100%;
      right: -55px;
      cursor: pointer;
      z-index: 9999;
      background: url(../img/btn_burger_open.png) no-repeat center center;
      background-size: contain;
      // @include animation-base(left,.3s);
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: url(../img/btn_burger_close.png) no-repeat center center;
        background-size: contain;
      }
      // &.show {
      //   left: 10px;
      // }
    }
  }
  // .home {
  //   .gNav {
  //     display: block;
  //   }
  // }
}