@charset "utf-8";
/*=================================
  komon
=================================*/
.komon {
  background: #f0f8fe;
  padding: 90px 1% 75px;
  .ttl--base {
    margin-bottom: 60px;
  }
  .secWrap__inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 0 auto;
  }
  &__text {
    width: calc(100% - 295px);
    .secWrap__text + .secWrap__text {
      margin-top: 40px;
    }
  }
  &__img {
    width: 255px;
    margin: 0 40px 40px 0;
    .caption {
      margin: 20px 0 0;
    }
  }
  &__table {
    width: 100%;
    th {
      color: #fff;
      text-align: center;
      background: $color-theme;
    }
    td {
      text-align: left;
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    komon
  =================================*/
  .komon {
    padding: 30px 2% 45px!important;
    .ttl--base {
      margin-bottom: 25px;
    }
    .secWrap__inner {
      display: block;
    }
    &__text {
      width: 100%;
      .secWrap__text + .secWrap__text {
        margin-top: 30px;
      }
    }
    &__img {
      width: 100%;
      margin: 0 0 20px;
      display: flex;
      align-items: center;
      .img {
        width: 30%;
        margin-right: 20px;
      }
      .caption {
        width: calc(70% - 20px);
        margin: 0;
      }
    }
  }
}