@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

a:hover {
  outline: none;
}

a:active {
  outline: none;
}

a:focus {
  outline: none;
}

.widelink--pc {
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: .06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #ebfafa;
  }
}

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
}

.btnWrap.center {
  text-align: center;
}

.btnWrap.right {
  text-align: right;
}

.btnWrap > * + * {
  margin-top: 9px;
}

.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #1e8edb;
  padding: 15px;
}

.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #3da7f0;
  box-shadow: 0 3px 0 #1e8edb;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}

.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: #1e8edb;
  border: 3px solid #1e8edb;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
}

.btn--border::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(../img/btn_arrow.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 0 0 20px;
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
  }
  .btn--blue:hover {
    opacity: .9;
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 #1e8edb;
    padding: 24px 30px;
    transition: none;
  }
  .btn--blueShadow:hover {
    transform: translateY(5px);
    box-shadow: none;
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
  }
  .btn--border::before {
    width: 24px;
    height: 24px;
    margin: 0 6px 3px 0;
  }
  .btn--border:hover {
    background: #f0f8fe;
  }
}

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.52941;
  color: #000;
  margin-bottom: 70px;
}

.ttl--base .st {
  font-style: normal;
  display: inline;
  color: #1e8edb;
  background: linear-gradient(transparent 60%, #fff697 0%);
}

.ttl--base:not(.left) {
  text-align: center;
}

.ttl--base.white {
  color: #fff;
}

.ttl--base.white .line {
  display: inline-block;
  border-bottom: 3px solid #ffea00;
}

.ttl--base .yellow {
  color: #ffea00;
}

.ttl--page {
  font-size: 3.6rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  background: #1e8edb url(../img/deco_repeat.png) repeat-x left bottom 15px;
  background-size: auto 155px;
  padding: 79px 1%;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .ttl--base .st {
    font-size: 2rem;
    background: linear-gradient(transparent 60%, #fff697 0%);
  }
  .ttl--base.left {
    text-align: center;
  }
  .ttl--page {
    font-size: 2.1rem;
    background: #1e8edb url(../img/deco_repeat.png) repeat-x left bottom 6px;
    background-size: auto 100px;
    padding: 38px 3%;
  }
}

/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}

.tabMenu__item {
  font-size: 1.6rem;
  font-weight: 400;
  color: #303030;
  padding: 13px 24px;
  text-align: center;
  width: 100%;
}

.tabMenu__item::before {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #1e8edb;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
}

.tabMenu__item:not(.active) {
  cursor: pointer;
}

.tabMenu__item.active {
  background: #1e8edb;
  color: #fff;
}

.tabMenu__item.active::before {
  border-color: transparent transparent transparent #f0f8fe;
}

@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #f0f8fe;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}

.tabItem .tabList {
  display: none;
}

.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.5rem;
    padding: 10px 18px;
  }
  .tabItem {
    min-height: inherit;
  }
}

/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 50px 0 30px;
  width: 100%;
  box-shadow: 0px 0px 21.25px 3.75px rgba(11, 3, 6, 0.05);
  background: #fff;
}

table caption {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

table thead th {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  background: #1e8edb;
}

table thead th:not(:last-child) {
  border-right: 1px solid #89cbf8;
}

table th {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: left;
  background: #f1f9fe;
  padding: 12px 15px;
}

table td {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.25;
  padding: 12px 15px;
}

table tbody th:not(:last-child), table tbody td:not(:last-child) {
  border-right: 1px solid #d6d6d6;
}

table tbody tr:not(:first-child) {
  border-top: 1px solid #d6d6d6;
}

table tbody tr:not(:last-child) th {
  position: relative;
}

table tbody tr:not(:last-child) th::after {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}

table.scroll ::-webkit-scrollbar {
  height: 5px;
}

table.scroll ::-webkit-scrollbar-track {
  background: #F1F1F1;
}

table.scroll ::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
    /* スクロールのつまみ部分の設定 */
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
    width: 94vw;
  }
  table th {
    font-size: 1.3rem;
    padding: 10px;
  }
  table td {
    font-size: 1.3rem;
    padding: 10px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #f4b038;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #f4b038;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #f4b038 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  padding: 30px 0;
}

.breadcrumb ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.breadcrumb ul li {
  font-size: 1.6rem;
  line-height: 1;
  position: relative;
}

.breadcrumb ul li a, .breadcrumb ul li span {
  font-size: 1.6rem;
  line-height: 1;
  display: block;
  padding: 10px 8px 12px;
  text-decoration: none;
}

@media screen and (min-width: 767px) {
  .breadcrumb ul li a:hover {
    text-decoration: underline;
  }
}

.breadcrumb ul li + li {
  padding-left: 20px;
}

.breadcrumb ul li + li::before {
  content: ">";
  font-size: 1.6rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 15px 3%;
  }
  .breadcrumb ul {
    flex-wrap: initial;
  }
  .breadcrumb ul li {
    font-size: 1.2rem;
  }
  .breadcrumb ul li a, .breadcrumb ul li span {
    font-size: 1.2rem;
    padding: 8px 6px 10px;
  }
  .breadcrumb ul li + li::before {
    font-size: 1rem;
  }
}

/*=================================
  pager
=================================*/
.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0 20px;
}

.pagerList {
  display: flex;
}

.pagerList__item {
  margin-right: 6px;
}

.pagerList__item a, .pagerList__item span {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  line-height: 1;
  padding: 12px 14px;
  display: block;
}

.pagerList__item a {
  color: #303030;
  text-decoration: none;
  background: #f6f6f6;
}

@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #1e8edb;
  }
}

.pagerList__item span {
  color: #fff;
  background: #1e8edb;
}

.pager .output {
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    padding: 20px 0 10px;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 10px;
  }
  .pagerList__item a, .pagerList__item span {
    font-size: 1.5rem;
    padding: 10px 11px;
  }
  .pager .output {
    font-size: 1.3rem;
    margin-top: 25px;
  }
}

/*=================================
  topPage
=================================*/
.home .mv + .secWrap, .page-template-page-areaLp .mv + .secWrap {
  margin-top: 0;
}

.home .merit, .page-template-page-areaLp .merit {
  counter-reset: merit;
  padding-top: 90px !important;
  padding-bottom: 75px !important;
  background: #f0f8fe;
}

.home .merit > .ttl--base, .page-template-page-areaLp .merit > .ttl--base {
  margin-bottom: 50px !important;
}

.home .merit .deco--merit, .page-template-page-areaLp .merit .deco--merit {
  position: relative;
}

.home .merit .deco--merit::before, .page-template-page-areaLp .merit .deco--merit::before {
  counter-increment: merit;
  content: "MERIT0" counter(merit);
  font-size: 7rem;
  font-weight: 700;
  font-style: italic;
  color: #fff;
  line-height: 1;
  background: linear-gradient(90deg, #7ae8ff, #dae986);
  display: block;
  margin-bottom: 20px;
  max-width: 350px;
  text-align: center;
  padding: 5px 0;
}

.home .merit .deco--merit:not(.left)::before, .page-template-page-areaLp .merit .deco--merit:not(.left)::before {
  text-align: center;
  margin: 0 auto 20px;
}

.home .secWrap, .page-template-page-areaLp .secWrap {
  padding: 0 1%;
  margin: 90px 0 75px;
}

.home .secWrap .secWrap, .page-template-page-areaLp .secWrap .secWrap {
  padding-left: 0;
  padding-right: 0;
}

.home .secWrap__inner, .page-template-page-areaLp .secWrap__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.home .secWrap .btnWrap, .page-template-page-areaLp .secWrap .btnWrap {
  margin-bottom: 0;
}

.home .secWrap .textWrap, .page-template-page-areaLp .secWrap .textWrap {
  position: relative;
}

.home .secWrap .ttl--base, .page-template-page-areaLp .secWrap .ttl--base {
  font-size: 3.8rem;
  line-height: 1.36842;
  margin-bottom: 30px;
}

.home .secWrap .ttl--base .st, .page-template-page-areaLp .secWrap .ttl--base .st {
  font-size: 3.8rem;
}

.home .secWrap .ttl--base .lg, .page-template-page-areaLp .secWrap .ttl--base .lg {
  font-size: 5rem;
}

.home .secWrap .ttl--base .marker, .page-template-page-areaLp .secWrap .ttl--base .marker {
  background: linear-gradient(transparent 60%, #fff697 0%);
}

.home .secWrap .ttl--base.colorText, .page-template-page-areaLp .secWrap .ttl--base.colorText {
  color: #1e8edb;
  margin-bottom: 50px;
}

.home .secWrap .ttl--base.whiplash, .page-template-page-areaLp .secWrap .ttl--base.whiplash {
  padding-right: 15%;
  position: relative;
}

.home .secWrap .ttl--base.whiplash::after, .page-template-page-areaLp .secWrap .ttl--base.whiplash::after {
  content: "";
  width: 18%;
  height: 86px;
  display: inline-block;
  background: url(../img/top_img02_deco_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
}

.home .secWrap__text, .page-template-page-areaLp .secWrap__text {
  font-size: 1.8rem;
}

.home .secWrap__text + .secWrap__text, .page-template-page-areaLp .secWrap__text + .secWrap__text {
  margin-top: 2rem;
}

.home .secWrap__text em, .page-template-page-areaLp .secWrap__text em {
  background: none;
}

.home .secWrap.imgRight .secWrap__inner, .page-template-page-areaLp .secWrap.imgRight .secWrap__inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0 40px;
}

.home .secWrap.imgRight .textWrap, .page-template-page-areaLp .secWrap.imgRight .textWrap {
  width: calc(45% - 20px);
}

.home .secWrap.imgRight .img, .page-template-page-areaLp .secWrap.imgRight .img {
  width: calc(55% - 20px);
}

.home .secWrap.imgRight .btnWrap, .page-template-page-areaLp .secWrap.imgRight .btnWrap {
  margin: 30px 0 0;
  justify-content: flex-start;
}

.home .secWrap.imgLeft .secWrap__inner, .page-template-page-areaLp .secWrap.imgLeft .secWrap__inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  gap: 0 40px;
}

.home .secWrap.imgLeft .textWrap, .page-template-page-areaLp .secWrap.imgLeft .textWrap {
  width: calc(45% - 20px);
  margin: 0 0 0 auto;
}

.home .secWrap.imgLeft .img, .page-template-page-areaLp .secWrap.imgLeft .img {
  width: calc(55% - 20px);
}

.home .secWrap.imgLeft .btnWrap, .page-template-page-areaLp .secWrap.imgLeft .btnWrap {
  margin: 30px 0 0 auto;
  width: calc(45% - 20px);
  justify-content: flex-start;
}

.home .secWrap.imgRight--sm .secWrap__inner, .page-template-page-areaLp .secWrap.imgRight--sm .secWrap__inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0 40px;
}

.home .secWrap.imgRight--sm .textWrap, .page-template-page-areaLp .secWrap.imgRight--sm .textWrap {
  padding-right: 40%;
}

.home .secWrap.imgRight--sm .img, .page-template-page-areaLp .secWrap.imgRight--sm .img {
  width: calc(40% - 40px);
  position: absolute;
  top: .7em;
  right: 0;
}

.home .secWrap.imgRight--sm .btnWrap, .page-template-page-areaLp .secWrap.imgRight--sm .btnWrap {
  margin: 30px 0 0;
  justify-content: flex-start;
}

.home .secWrap.imgLeft--sm .secWrap__inner, .page-template-page-areaLp .secWrap.imgLeft--sm .secWrap__inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  gap: 0 40px;
}

.home .secWrap.imgLeft--sm .textWrap, .page-template-page-areaLp .secWrap.imgLeft--sm .textWrap {
  margin: 0 0 0 auto;
  padding-left: 40%;
}

.home .secWrap.imgLeft--sm .img, .page-template-page-areaLp .secWrap.imgLeft--sm .img {
  width: calc(40% - 40px);
  position: absolute;
  top: .7em;
  left: 0;
}

.home .secWrap.imgLeft--sm .btnWrap, .page-template-page-areaLp .secWrap.imgLeft--sm .btnWrap {
  margin: 30px 0 0 auto;
  width: calc(60% - 20px);
  justify-content: flex-start;
}

.home .secWrap.menu, .page-template-page-areaLp .secWrap.menu {
  background: #1e8edb;
  padding-top: 64px;
  padding-bottom: 80px;
}

.home .secWrap.menu .menuList, .page-template-page-areaLp .secWrap.menu .menuList {
  background: #f2faff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}

.home .secWrap.menu .menuList__item, .page-template-page-areaLp .secWrap.menu .menuList__item {
  width: calc((100% - 120px) / 3);
  margin: 20px;
  position: relative;
}

.home .secWrap.menu .menuList__item .img, .page-template-page-areaLp .secWrap.menu .menuList__item .img {
  position: relative;
}

.home .secWrap.menu .menuList__item .img::after, .page-template-page-areaLp .secWrap.menu .menuList__item .img::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 84, 142, 0.7);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.home .secWrap.menu .menuList__item .img img, .page-template-page-areaLp .secWrap.menu .menuList__item .img img {
  width: 100%;
}

.home .secWrap.menu .menuList__item .text, .page-template-page-areaLp .secWrap.menu .menuList__item .text {
  font-size: 2.4rem;
  line-height: 1.41666;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1), .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1) {
  position: relative;
}

.home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before, .home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after {
  content: "";
  width: 100px;
  height: 100px;
  display: inline-block;
  background: url(../img/top_menu_cross.png) no-repeat center center;
  position: absolute;
  top: -65px;
}

.home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before {
  left: -70px;
}

.home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after {
  right: -70px;
}

@media screen and (min-width: 767px) {
  .home .secWrap.menu .menuList__item:hover .img::after, .page-template-page-areaLp .secWrap.menu .menuList__item:hover .img::after {
    width: 80%;
    height: 80%;
    background: rgba(0, 84, 142, 0.3);
  }
}

.home .secWrap.menu + .attorneysFee, .page-template-page-areaLp .secWrap.menu + .attorneysFee {
  margin-top: -90px;
}

.home .secWrap.komon, .page-template-page-areaLp .secWrap.komon {
  background: transparent;
  margin-bottom: 0;
}

.home .secWrap.komon .secWrap__inner, .page-template-page-areaLp .secWrap.komon .secWrap__inner {
  max-width: 1240px;
}

.home .secWrap.bnrArea, .page-template-page-areaLp .secWrap.bnrArea {
  background: #f9f9f9;
  padding-top: 90px;
  padding-bottom: 75px;
}

.home .secWrap.bnrArea .bnrList, .page-template-page-areaLp .secWrap.bnrArea .bnrList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}

.home .secWrap.bnrArea .bnrList__item, .page-template-page-areaLp .secWrap.bnrArea .bnrList__item {
  width: calc((100% - 30px) / 2);
  margin-bottom: 30px;
}

@media screen and (min-width: 767px) {
  .home .secWrap.bnrArea .bnrList__item a:hover, .page-template-page-areaLp .secWrap.bnrArea .bnrList__item a:hover {
    opacity: .8;
  }
}

.home .secWrap.introduction .introduction__text, .page-template-page-areaLp .secWrap.introduction .introduction__text {
  padding-left: 30%;
  position: relative;
  z-index: 1;
}

.home .secWrap.introduction .introduction__text::after, .page-template-page-areaLp .secWrap.introduction .introduction__text::after {
  content: "";
  width: clamp(199px, 28%, 399px);
  height: clamp(209px, 73%, 409px);
  display: inline-block;
  background: url(../img/introduction_main.png) no-repeat center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: -5px;
  z-index: 0;
}

.home .secWrap.introduction .introduction__text p + p, .page-template-page-areaLp .secWrap.introduction .introduction__text p + p {
  margin-top: 20px;
}

.home .secWrap.introduction .introduction__video, .page-template-page-areaLp .secWrap.introduction .introduction__video {
  margin-top: 80px;
}

.home .secWrap.introduction .introduction__video-ttl, .page-template-page-areaLp .secWrap.introduction .introduction__video-ttl {
  font-size: 2.4rem;
  font-weight: 500;
  border-left: 5px solid #1e8edb;
  padding-left: 20px;
  margin-bottom: 40px;
}

.home .secWrap.introduction .introduction__video .videoWrap, .page-template-page-areaLp .secWrap.introduction .introduction__video .videoWrap {
  width: 100%;
  max-width: 980px;
  margin: 40px auto 0;
  aspect-ratio: 16 / 9;
}

.home .secWrap.introduction .introduction__video .videoWrap iframe, .page-template-page-areaLp .secWrap.introduction .introduction__video .videoWrap iframe {
  width: 100%;
  height: 100%;
}

.home .secWrap.introduction .introduction__video p + p, .page-template-page-areaLp .secWrap.introduction .introduction__video p + p {
  margin-top: 20px;
}

.home .secWrap.introduction .btnWrap, .page-template-page-areaLp .secWrap.introduction .btnWrap {
  justify-content: flex-start;
  margin-top: 50px;
}

.home .secWrap.introduction + .bnrArea, .page-template-page-areaLp .secWrap.introduction + .bnrArea {
  margin-top: -75px;
}

.home .secWrap.trafficAccidentFlow, .page-template-page-areaLp .secWrap.trafficAccidentFlow {
  background: #f0f8fe;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 0;
}

.home .secWrap.trafficAccidentFlow .processFlow, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow {
  counter-reset: processFlowNum;
}

.home .secWrap.trafficAccidentFlow .processFlow__item, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item {
  width: 100%;
  background: #fff;
  position: relative;
  padding: 40px 30px 50px;
  margin-top: 80px;
  min-height: 350px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.08);
}

.home .secWrap.trafficAccidentFlow .processFlow__item + .processFlow__item, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item + .processFlow__item {
  margin-top: 150px;
}

.home .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::after, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  border-top: 50px solid #1e8edb;
  border-bottom: 0;
  display: inline-block;
  position: absolute;
  bottom: -100px;
  left: 155px;
  transform: translateX(-50%);
}

.home .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::before, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::before {
  content: "";
  width: 40px;
  height: 100%;
  display: inline-block;
  background: #1e8edb;
  position: absolute;
  bottom: -50px;
  left: 135px;
  z-index: 0;
}

.home .secWrap.trafficAccidentFlow .processFlow__ttl, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__ttl {
  font-size: 3rem;
  font-weight: 700;
  color: #1e8edb;
  padding-left: 280px;
  margin-bottom: 25px;
}

.home .secWrap.trafficAccidentFlow .processFlow__ttl::before, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__ttl::before {
  counter-increment: processFlowNum;
  content: "0" counter(processFlowNum);
  font-size: 3rem;
  font-weight: 500;
  line-height: 50px;
  color: #fff;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  background: #1e8edb;
  border-radius: 50%;
  margin-right: 15px;
}

.home .secWrap.trafficAccidentFlow .processFlow__text, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__text {
  padding-left: 280px;
}

.home .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]), .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]) {
  font-size: 1.8rem;
}

.home .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]) + p, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]) + p {
  margin-top: 1em;
}

.home .secWrap.trafficAccidentFlow .processFlow__img, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__img {
  position: absolute;
  top: 30px;
  left: 30px;
}

.home .secWrap.trafficAccidentFlow .processFlow__img img, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__img img {
  width: 250px;
  height: 250px;
  display: inline-block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.home .attorneysFee, .page-template-page-areaLp .attorneysFee {
  padding-top: 70px;
  padding-bottom: 75px;
  background: url(../img/top_attorneysFeeBg.jpg) no-repeat center center;
  background-size: cover;
}

.home .attorneysFee__box, .page-template-page-areaLp .attorneysFee__box {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.home .attorneysFee__item, .page-template-page-areaLp .attorneysFee__item {
  padding: 50px 40px 45px 50px;
  display: flex;
}

.home .attorneysFee__item:nth-child(2n), .page-template-page-areaLp .attorneysFee__item:nth-child(2n) {
  background: #f9f9f9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f9f9f9 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f9f9f9 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
}

.home .attorneysFee__item .bnrWrap, .page-template-page-areaLp .attorneysFee__item .bnrWrap {
  margin-top: 20px;
}

@media screen and (min-width: 767px) {
  .home .attorneysFee__item .bnrWrap a:hover, .page-template-page-areaLp .attorneysFee__item .bnrWrap a:hover {
    opacity: .8;
  }
}

.home .attorneysFee__item .imgWrap, .page-template-page-areaLp .attorneysFee__item .imgWrap {
  margin-right: 40px;
  width: 50%;
}

.home .attorneysFee__item .textWrap, .page-template-page-areaLp .attorneysFee__item .textWrap {
  max-width: 545px;
  width: 50%;
}

.home .attorneysFee__item .tableWrap, .page-template-page-areaLp .attorneysFee__item .tableWrap {
  margin-left: 55px;
}

.home .attorneysFee__item .tableWrap .priceTable tbody th, .page-template-page-areaLp .attorneysFee__item .tableWrap .priceTable tbody th {
  color: #1e8edb;
  background: transparent;
}

.home .attorneysFee__item .tableWrap .btnWrap, .page-template-page-areaLp .attorneysFee__item .tableWrap .btnWrap {
  justify-content: flex-start;
}

.home .attorneysFee__item .tableWrap .btnWrap .btn--border, .page-template-page-areaLp .attorneysFee__item .tableWrap .btnWrap .btn--border {
  margin-top: 10px;
}

.home .caseStudyList, .page-template-page-areaLp .caseStudyList {
  border-left: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 70px;
}

.home .caseStudyList__item, .page-template-page-areaLp .caseStudyList__item {
  width: calc(100% / 6);
  text-align: center;
  display: inline-block;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.home .caseStudyList__item a, .page-template-page-areaLp .caseStudyList__item a {
  font-size: 1.8rem;
  color: #303030;
  text-decoration: none;
  display: block;
  padding: 20px;
}

@media screen and (min-width: 767px) {
  .home .caseStudyList__item a:hover, .page-template-page-areaLp .caseStudyList__item a:hover {
    color: #1e8edb;
    background: #f0f8fe;
  }
  .home .caseStudyList__item a:hover .img, .page-template-page-areaLp .caseStudyList__item a:hover .img {
    transform: scale(0.9);
  }
}

.home .caseStudyList__item .img, .page-template-page-areaLp .caseStudyList__item .img {
  display: block;
  margin: 0 auto 10px;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.home .marketPriceWrap, .page-template-page-areaLp .marketPriceWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.home .marketPriceWrap .textWrap, .page-template-page-areaLp .marketPriceWrap .textWrap {
  width: 35%;
  margin-right: 3%;
}

.home .marketPrice__box, .page-template-page-areaLp .marketPrice__box {
  width: 62%;
}

.home .marketPrice__ttl, .page-template-page-areaLp .marketPrice__ttl {
  font-size: 2.2rem;
  color: #fff;
  letter-spacing: .2em;
  padding: 18px 15px;
  text-align: center;
  background: #1e8edb;
}

.home .marketPrice__ttl::before, .page-template-page-areaLp .marketPrice__ttl::before {
  content: "";
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  background: url(../img/icon/icon_legal.png) no-repeat center center;
  background-size: contain;
}

.home .marketPrice__cont, .page-template-page-areaLp .marketPrice__cont {
  background: #f0f8fe;
  padding: 15px 3%;
}

.home .marketPriceList, .page-template-page-areaLp .marketPriceList {
  display: flex;
  flex-wrap: wrap;
}

.home .marketPriceList__item, .page-template-page-areaLp .marketPriceList__item {
  margin: 10px 0;
  width: calc(100% / 3);
}

.home .marketPriceList__item a, .page-template-page-areaLp .marketPriceList__item a {
  color: #303030;
  text-decoration: none;
  padding-left: 24px;
  display: block;
  position: relative;
}

@media screen and (min-width: 767px) {
  .home .marketPriceList__item a:hover, .page-template-page-areaLp .marketPriceList__item a:hover {
    text-decoration: underline;
    color: #1e8edb;
  }
}

.home .marketPriceList__item a::before, .page-template-page-areaLp .marketPriceList__item a::before {
  content: "";
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url(../img/link_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 7px;
  left: 0;
}

.home .faq, .page-template-page-areaLp .faq {
  padding-top: 0;
}

.home .faq .secWrap__inner, .page-template-page-areaLp .faq .secWrap__inner {
  border-top: 1px solid #d6d6d6;
  padding-top: 70px;
}

.home .faqWrap, .page-template-page-areaLp .faqWrap {
  margin-top: 70px;
}

.home .faqList, .page-template-page-areaLp .faqList {
  border: 1px solid #aacce6;
}

.home .faqList .q, .page-template-page-areaLp .faqList .q {
  font-size: 2.4rem;
  font-weight: 500;
  color: #1e8edb;
  background: #f0f8fe;
  padding: 20px 80px 24px 105px;
  position: relative;
}

.home .faqList .q::before, .page-template-page-areaLp .faqList .q::before {
  font-family: 'Roboto', sans-serif;
  content: "Q";
  font-size: 2.6rem;
  font-weight: 500;
  color: #1e8edb;
  background: #d0ecff;
  border-radius: 50px;
  display: inline-block;
  width: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.home .faqList .q::after, .page-template-page-areaLp .faqList .q::after {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../img/qa_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.home .faqList .q.open::after, .page-template-page-areaLp .faqList .q.open::after {
  transform: translateY(-50%) rotate(180deg);
}

.home .faqList .a, .page-template-page-areaLp .faqList .a {
  padding: 25px 40px;
  background: #fff;
}

.home .faqList + .faqList, .page-template-page-areaLp .faqList + .faqList {
  margin-top: 20px;
}

.home .faq .btnWrap, .page-template-page-areaLp .faq .btnWrap {
  margin-top: 50px;
}

@media screen and (max-width: 980px) {
  /*=================================
    topPage
  =================================*/
  .home .merit, .page-template-page-areaLp .merit {
    padding-top: 30px !important;
    padding-bottom: 45px !important;
  }
  .home .merit > .ttl--base, .page-template-page-areaLp .merit > .ttl--base {
    margin-bottom: 20px !important;
  }
  .home .merit .deco--merit::before, .page-template-page-areaLp .merit .deco--merit::before {
    font-size: 3rem;
    max-width: 150px;
    margin: 0 auto 10px;
    padding: 5px 5px;
  }
  .home .merit .deco--merit:not(.left)::before, .page-template-page-areaLp .merit .deco--merit:not(.left)::before {
    margin: 0 auto 10px;
  }
  .home .secWrap, .page-template-page-areaLp .secWrap {
    margin: 30px 0 45px;
    padding: 0 3%;
  }
  .home .secWrap .btnWrap, .page-template-page-areaLp .secWrap .btnWrap {
    margin-bottom: 0;
  }
  .home .secWrap .ttl--base, .page-template-page-areaLp .secWrap .ttl--base {
    font-size: 2.2rem;
    margin-bottom: 25px;
    text-align: center;
  }
  .home .secWrap .ttl--base .st, .page-template-page-areaLp .secWrap .ttl--base .st {
    font-size: 2.2rem;
  }
  .home .secWrap .ttl--base .lg, .page-template-page-areaLp .secWrap .ttl--base .lg {
    font-size: 3.5rem;
    line-height: 1;
  }
  .home .secWrap .ttl--base.colorText, .page-template-page-areaLp .secWrap .ttl--base.colorText {
    margin-bottom: 25px;
  }
  .home .secWrap .ttl--base.whiplash, .page-template-page-areaLp .secWrap .ttl--base.whiplash {
    padding-right: 0;
  }
  .home .secWrap .ttl--base.whiplash::after, .page-template-page-areaLp .secWrap .ttl--base.whiplash::after {
    width: 72px;
    height: 72px;
    display: block;
    margin: 12px auto 0;
    background: url(../img/top_img02_deco_sp.png) no-repeat center center;
    background-size: contain;
    position: static;
  }
  .home .secWrap__text, .page-template-page-areaLp .secWrap__text {
    font-size: 1.5rem;
  }
  .home .secWrap__text em, .page-template-page-areaLp .secWrap__text em {
    font-size: 1.5rem;
  }
  .home .secWrap.imgRight .secWrap__inner, .page-template-page-areaLp .secWrap.imgRight .secWrap__inner {
    display: block;
  }
  .home .secWrap.imgRight .textWrap, .page-template-page-areaLp .secWrap.imgRight .textWrap {
    width: 96%;
    margin: 0 auto 30px;
  }
  .home .secWrap.imgRight .img, .page-template-page-areaLp .secWrap.imgRight .img {
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .home .secWrap.imgRight .btnWrap, .page-template-page-areaLp .secWrap.imgRight .btnWrap {
    width: 100%;
    float: none;
    margin: 15px auto 0;
    text-align: center;
    justify-content: center;
  }
  .home .secWrap.imgLeft .secWrap__inner, .page-template-page-areaLp .secWrap.imgLeft .secWrap__inner {
    display: block;
  }
  .home .secWrap.imgLeft .textWrap, .page-template-page-areaLp .secWrap.imgLeft .textWrap {
    width: 96%;
    margin: 0 auto 30px;
  }
  .home .secWrap.imgLeft .img, .page-template-page-areaLp .secWrap.imgLeft .img {
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .home .secWrap.imgLeft .btnWrap, .page-template-page-areaLp .secWrap.imgLeft .btnWrap {
    width: 100%;
    float: none;
    margin: 15px auto 0;
    text-align: center;
    justify-content: center;
  }
  .home .secWrap.imgRight--sm .secWrap__inner, .page-template-page-areaLp .secWrap.imgRight--sm .secWrap__inner {
    display: block;
  }
  .home .secWrap.imgRight--sm .textWrap, .page-template-page-areaLp .secWrap.imgRight--sm .textWrap {
    width: 100%;
    padding-right: 0;
  }
  .home .secWrap.imgRight--sm .img, .page-template-page-areaLp .secWrap.imgRight--sm .img {
    width: 100%;
    margin: 0 0 20px;
    position: static;
  }
  .home .secWrap.imgRight--sm .btnWrap, .page-template-page-areaLp .secWrap.imgRight--sm .btnWrap {
    margin: 20px auto 0;
    justify-content: center;
    text-align: center;
  }
  .home .secWrap.imgLeft--sm .secWrap__inner, .page-template-page-areaLp .secWrap.imgLeft--sm .secWrap__inner {
    display: block;
  }
  .home .secWrap.imgLeft--sm .textWrap, .page-template-page-areaLp .secWrap.imgLeft--sm .textWrap {
    width: 100%;
    margin: initial;
    padding-left: 0;
  }
  .home .secWrap.imgLeft--sm .img, .page-template-page-areaLp .secWrap.imgLeft--sm .img {
    width: 100%;
    margin: 0 0 20px;
    position: static;
  }
  .home .secWrap.imgLeft--sm .btnWrap, .page-template-page-areaLp .secWrap.imgLeft--sm .btnWrap {
    margin: 20px auto 0;
    width: initial;
    justify-content: center;
    text-align: center;
  }
  .home .secWrap.menu, .page-template-page-areaLp .secWrap.menu {
    padding: 30px 0;
  }
  .home .secWrap.menu .menuList, .page-template-page-areaLp .secWrap.menu .menuList {
    margin-top: 15px;
    padding: 8px;
  }
  .home .secWrap.menu .menuList__item, .page-template-page-areaLp .secWrap.menu .menuList__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
  .home .secWrap.menu .menuList__item .text, .page-template-page-areaLp .secWrap.menu .menuList__item .text {
    font-size: 1.5rem;
    line-height: 1.3333;
  }
  .home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before, .home .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::before, .page-template-page-areaLp .secWrap.menu .menuList__item:nth-child(n + 3):nth-child(3n - 1)::after {
    content: none;
  }
  .home .secWrap.menu + .attorneysFee, .page-template-page-areaLp .secWrap.menu + .attorneysFee {
    margin-top: -45px;
  }
  .home .secWrap.komon, .page-template-page-areaLp .secWrap.komon {
    padding-bottom: 0 !important;
  }
  .home .secWrap.bnrArea, .page-template-page-areaLp .secWrap.bnrArea {
    padding: 30px 3% 45px;
  }
  .home .secWrap.bnrArea .bnrList, .page-template-page-areaLp .secWrap.bnrArea .bnrList {
    display: block;
    margin: 40px auto 0;
  }
  .home .secWrap.bnrArea .bnrList__item, .page-template-page-areaLp .secWrap.bnrArea .bnrList__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .home .secWrap.introduction, .page-template-page-areaLp .secWrap.introduction {
    overflow: hidden;
  }
  .home .secWrap.introduction .introduction__text, .page-template-page-areaLp .secWrap.introduction .introduction__text {
    padding: 0 0 calc(18% + 45px);
    border-bottom: 1px solid #d6d6d6;
  }
  .home .secWrap.introduction .introduction__text::after, .page-template-page-areaLp .secWrap.introduction .introduction__text::after {
    width: clamp(200px, 26%, 409px);
    height: clamp(209px, 35%, 409px);
    left: initial;
    right: -35px;
    bottom: 0;
  }
  .home .secWrap.introduction .introduction__text p + p, .page-template-page-areaLp .secWrap.introduction .introduction__text p + p {
    margin-top: 2rem;
  }
  .home .secWrap.introduction .introduction__video, .page-template-page-areaLp .secWrap.introduction .introduction__video {
    margin-top: 40px;
  }
  .home .secWrap.introduction .introduction__video-ttl, .page-template-page-areaLp .secWrap.introduction .introduction__video-ttl {
    font-size: 1.8rem;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .home .secWrap.introduction .introduction__video .videoWrap, .page-template-page-areaLp .secWrap.introduction .introduction__video .videoWrap {
    margin: 30px auto 0;
  }
  .home .secWrap.introduction .introduction__video p + p, .page-template-page-areaLp .secWrap.introduction .introduction__video p + p {
    margin-top: 2rem;
  }
  .home .secWrap.introduction .btnWrap, .page-template-page-areaLp .secWrap.introduction .btnWrap {
    margin-top: 35px;
  }
  .home .secWrap.introduction + .bnrArea, .page-template-page-areaLp .secWrap.introduction + .bnrArea {
    margin-top: -45px;
  }
  .home .secWrap.trafficAccidentFlow, .page-template-page-areaLp .secWrap.trafficAccidentFlow {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__item, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item {
    padding: 30px 5%;
    margin-top: 40px;
    min-height: initial;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__item + .processFlow__item, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item + .processFlow__item {
    margin-top: 100px;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::after, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::after {
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    border-top: 40px solid #1e8edb;
    bottom: -75px;
    left: 50%;
    transform: translateX(-50%);
  }
  .home .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::before, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__item:not(:last-child)::before {
    content: none;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__ttl, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__ttl {
    font-size: 2.2rem;
    padding-left: 50px;
    margin-bottom: 25px;
    position: relative;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__ttl::before, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__ttl::before {
    font-size: 2rem;
    line-height: 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -5px;
    left: 0;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__text, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__text {
    padding-left: 0px;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]), .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__text > p:not([class]) {
    font-size: 1.5rem;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__img, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__img {
    position: static;
  }
  .home .secWrap.trafficAccidentFlow .processFlow__img img, .page-template-page-areaLp .secWrap.trafficAccidentFlow .processFlow__img img {
    width: 80%;
    height: initial;
    display: block;
    border-radius: 0;
    margin: 0 auto 30px;
  }
  .home .attorneysFee, .page-template-page-areaLp .attorneysFee {
    padding: 22px 2.5% 20px;
    background: #f2f2f2 url(../img/top_attorneysFeeBg.jpg) no-repeat center top;
    background-size: inherit;
  }
  .home .attorneysFee__item, .page-template-page-areaLp .attorneysFee__item {
    padding: 25px 4%;
    display: block;
  }
  .home .attorneysFee__item .bnrWrap, .page-template-page-areaLp .attorneysFee__item .bnrWrap {
    margin: 15px 0 20px;
  }
  .home .attorneysFee__item .textWrap, .page-template-page-areaLp .attorneysFee__item .textWrap {
    max-width: 100%;
    width: 100%;
  }
  .home .attorneysFee__item .imgWrap, .page-template-page-areaLp .attorneysFee__item .imgWrap {
    margin: 0 auto 10px;
    display: block;
    text-align: center;
    width: 100%;
  }
  .home .attorneysFee__item .tableWrap, .page-template-page-areaLp .attorneysFee__item .tableWrap {
    margin: 22px 0 14px;
  }
  .home .attorneysFee__item .tableWrap .priceTable, .page-template-page-areaLp .attorneysFee__item .tableWrap .priceTable {
    margin: 20px auto 0;
    display: table;
    white-space: unset;
  }
  .home .attorneysFee__item .tableWrap .btnWrap .btn--border, .page-template-page-areaLp .attorneysFee__item .tableWrap .btnWrap .btn--border {
    margin-top: 10px;
  }
  .home .caseStudy .textWrap, .page-template-page-areaLp .caseStudy .textWrap {
    padding: 0 2%;
  }
  .home .caseStudyList, .page-template-page-areaLp .caseStudyList {
    border-left: none;
    margin-top: 30px;
  }
  .home .caseStudyList__item, .page-template-page-areaLp .caseStudyList__item {
    width: calc(100% / 3);
  }
  .home .caseStudyList__item a, .page-template-page-areaLp .caseStudyList__item a {
    font-size: 1.4rem;
    padding: 15px;
  }
  .home .caseStudyList__item:nth-child(3n), .page-template-page-areaLp .caseStudyList__item:nth-child(3n) {
    border-right: none;
  }
  .home .marketPrice, .page-template-page-areaLp .marketPrice {
    padding-left: 3%;
    padding-right: 3%;
  }
  .home .marketPriceWrap, .page-template-page-areaLp .marketPriceWrap {
    display: block;
    margin-top: 45px;
  }
  .home .marketPriceWrap .textWrap, .page-template-page-areaLp .marketPriceWrap .textWrap {
    width: 100%;
    margin: 0 0 30px;
  }
  .home .marketPrice__box, .page-template-page-areaLp .marketPrice__box {
    width: 100%;
  }
  .home .marketPrice__ttl, .page-template-page-areaLp .marketPrice__ttl {
    font-size: 1.6rem;
    padding: 12px;
  }
  .home .marketPrice__ttl::before, .page-template-page-areaLp .marketPrice__ttl::before {
    width: 20px;
    height: 20px;
  }
  .home .marketPrice__cont, .page-template-page-areaLp .marketPrice__cont {
    padding: 15px 3%;
  }
  .home .marketPriceList, .page-template-page-areaLp .marketPriceList {
    justify-content: space-between;
  }
  .home .marketPriceList__item, .page-template-page-areaLp .marketPriceList__item {
    min-width: inherit;
    width: 48%;
    margin: 10px 0;
  }
  .home .marketPriceList__item a, .page-template-page-areaLp .marketPriceList__item a {
    font-size: 1.3rem;
    padding-left: 17px;
    display: block;
    position: relative;
  }
  .home .marketPriceList__item a::before, .page-template-page-areaLp .marketPriceList__item a::before {
    width: 13px;
    height: 13px;
    margin-right: 0;
    position: absolute;
    top: 3px;
    left: 0;
  }
  .home .faq, .page-template-page-areaLp .faq {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .home .faq .secWrap__inner, .page-template-page-areaLp .faq .secWrap__inner {
    padding: 30px 3% 0;
  }
  .home .faqWrap, .page-template-page-areaLp .faqWrap {
    margin-top: 30px;
  }
  .home .faqList, .page-template-page-areaLp .faqList {
    border: 2px solid #aacce6;
  }
  .home .faqList .q, .page-template-page-areaLp .faqList .q {
    font-size: 1.5rem;
    padding: 11px 45px;
  }
  .home .faqList .q::before, .page-template-page-areaLp .faqList .q::before {
    font-size: 1.6rem;
    width: 33px;
    line-height: 33px;
    left: 7px;
  }
  .home .faqList .q::after, .page-template-page-areaLp .faqList .q::after {
    width: 30px;
    height: 30px;
    right: 7px;
  }
  .home .faqList .a, .page-template-page-areaLp .faqList .a {
    padding: 25px 40px;
    background: #fff;
  }
  .home .faqList + .faqList, .page-template-page-areaLp .faqList + .faqList {
    margin-top: 10px;
  }
  .home .faq .btnWrap, .page-template-page-areaLp .faq .btnWrap {
    text-align: center;
    margin-top: 14px;
  }
}

/*=================================
  下層ページ共通
=================================*/
/* 記事一覧 */
.articleList__item {
  border: 1px solid #92bddd;
  padding: 2.5% 3%;
}

.articleList__item .itemWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.articleList__item .itemWrap .thumb {
  width: 40%;
  max-width: 400px;
  margin-right: 4%;
}

.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
}

.articleList__item .itemWrap .thumb + .textWrap {
  width: calc(60% - 4%);
}

.articleList__item .itemWrap .textWrap {
  width: 100%;
}

.articleList__item .itemWrap .textWrap__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.3846;
  text-decoration: none;
  display: block;
  margin-bottom: 25px;
}

.articleList__item .itemWrap .textWrap__text {
  font-size: 1.8rem;
}

.articleList__item + .articleList__item {
  margin-top: 20px;
}

.articleList--simple .articleList__item {
  padding: 2%;
}

.articleList--simple .articleList__item .thumb {
  width: 290px;
  margin-right: 25px;
}

.articleList--simple .articleList__item .thumb img {
  margin: 0 auto;
}

.articleList--simple .articleList__item .textWrap {
  width: calc(100% - 315px);
}

.articleList--simple .articleList__item .textWrap__ttl {
  font-size: 2.2rem;
  line-height: 1.454545;
  margin-bottom: 25px;
}

.articleList--simple .articleList__item .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.5;
}

/* その他のお悩み事例 */
.otherCase {
  background: #f9f9f9;
  padding: 40px;
  margin: 70px auto;
}

.otherCase__inner {
  box-shadow: 0px 0px 21.25px 3.75px rgba(11, 3, 6, 0.05);
  background: #fff;
}

.otherCase__ttl {
  font-size: 2.2rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding: 22px 15px;
  background: #1e8edb;
}

.otherCase__ttl::before {
  content: "";
  width: 25px;
  height: 19px;
  display: inline-block;
  background: url(../img/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  margin-right: 12px;
}

.otherCaseList {
  padding: 24px;
}

.otherCaseList__item a {
  font-size: 1.6rem;
  color: #303030;
  text-decoration: none;
}

.otherCaseList__item a::before {
  content: "";
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url(../img/link_arrow.png) no-repeat center center;
  background-size: contain;
  margin-right: 8px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .otherCaseList__item a:hover {
    text-decoration: underline;
  }
}

.otherCaseList__item + .otherCaseList__item {
  margin-top: 15px;
}

/* メリット */
.pushBox {
  width: calc(100% + 160px);
  margin-left: -80px;
  margin-top: 50px;
}

.pushBox .merit {
  padding: 50px 120px 45px;
  background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom 30px;
  position: relative;
}

.pushBox .merit__inner {
  background: url(../img/merit_bg_pc.jpg) no-repeat center center;
  background-size: contain;
}

.pushBox .merit__ttl {
  font-size: 3.4rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.pushBox .merit__ttl .num {
  font-family: 'Roboto', sans-serif;
  font-size: 4.4rem;
  color: #ffea00;
}

.pushBox .meritList {
  text-align: center;
  padding: 7% 10px;
  min-height: 500px;
  max-width: 555px;
  margin: 0 auto;
}

.pushBox .meritList__item {
  font-size: 3rem;
  font-weight: 500;
  color: #1e8edb;
  display: inline-block;
}

.pushBox .meritList__item::before {
  content: "";
  width: 28px;
  height: 24px;
  display: inline-block;
  background: url(../img/icon/icon_check_b.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

.pushBox .meritList__item::after {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  background: #1e8edb;
}

.pushBox .meritList__item + .meritList__item {
  margin-top: 10px;
}

.pushBox .merit::after {
  content: "";
  width: 235px;
  height: 430px;
  display: inline-block;
  background: url(../img/merit_img.png) no-repeat center bottom;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 60px;
}

.pushBox .cvBnr__ttl {
  padding: 30px;
}

.pushBox .cvBnr .contact {
  padding: 16px 30px 25px 30px;
}

.pushBox .cvBnr::after {
  width: 31%;
  height: 346px;
  background: url(../img/cvBnr_img02.png) no-repeat center bottom;
  background-size: contain;
  right: 10px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .pushBox .cvBnr::after {
    content: none;
  }
}

.pushBox .pushLink {
  padding: 70px 80px 0;
}

.pushBox .pushLink__ttl {
  text-align: center;
}

.pushBox .pushLink__ttl .text {
  font-size: 3.4rem;
  font-weight: 500;
  padding-bottom: 5px;
  margin-bottom: 35px;
  display: inline-block;
  border-bottom: 3px solid #1e8edb;
}

.pushBox .pushLinkList {
  display: flex;
  flex-wrap: wrap;
}

.pushBox .pushLinkList__item {
  width: calc((100% - 20px) / 2);
  margin-top: 20px;
  position: relative;
}

.pushBox .pushLinkList__item:not(:nth-child(2n - 1)) {
  margin-left: 20px;
}

.pushBox .pushLinkList__item .img img {
  width: 100%;
}

.pushBox .pushLinkList__item .img::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 84, 142, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.pushBox .pushLinkList__item .text {
  font-size: 2.2rem;
  line-height: 1.454545;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  text-align: center;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 767px) {
  .pushBox .pushLinkList__item:hover .img::after {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background: rgba(0, 84, 142, 0.5);
  }
}

.accessWrap {
  display: flex;
  gap: 40px;
  margin-top: 50px;
}

.accessItem {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
}

.accessItem__ttl {
  font-size: 1.7rem;
  font-weight: 600;
  border-bottom: 4px solid #1e8edb;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.accessItem__directions {
  font-size: 1.5rem;
  margin-top: 15px;
  margin-bottom: 30px;
}

.accessItem__gMap {
  margin-top: auto;
}

.accessItem__gMap iframe {
  max-height: 260px;
}

.accessItem__gMap.tokyo {
  position: relative;
  padding-top: 260px;
  overflow: hidden;
}

.accessItem__gMap.tokyo iframe {
  position: absolute;
  top: 0;
  left: 0;
  max-height: initial;
}

@media screen and (min-width: 767px) and (max-width: 1180px) {
  /* メリット */
  .pushBox .meritList {
    padding: 4% 10px;
    min-height: inherit;
  }
  .pushBox .meritList__item {
    font-size: 1.8rem;
  }
  .pushBox .meritList__item + .meritList__item {
    margin-top: 8px;
  }
  .pushBox .merit::after {
    width: 180px;
    height: 338px;
    left: 30px;
  }
  .pushBox .pushLink {
    padding: 50px 40px 0;
  }
  .pushBox .pushLink__ttl .text {
    font-size: 2.8rem;
    margin-bottom: 25px;
  }
  .pushBox .pushLinkList__item {
    width: calc((100% - 10px) / 2);
    margin-top: 10px;
  }
  .pushBox .pushLinkList__item:not(:nth-child(2n - 1)) {
    margin-left: 10px;
  }
  .pushBox .pushLinkList__item .text {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    下層ページ共通
  =================================*/
  /* 記事一覧 */
  .articleList__item {
    padding: 3.5% 3.5%;
  }
  .articleList__item .itemWrap {
    align-items: flex-start;
  }
  .articleList__item .itemWrap .thumb {
    width: 30%;
    max-width: 100%;
    margin-right: 3%;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: calc(70% - 3%);
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  .articleList__item .itemWrap .textWrap__text {
    display: none;
  }
  .articleList__item + .articleList__item {
    margin-top: 15px;
  }
  /* その他のお悩み事例 */
  .otherCase {
    padding: 15px;
    width: calc(100% + 8%);
    margin: 37px auto;
    margin-left: -4%;
  }
  .otherCase__ttl {
    font-size: 1.5rem;
    padding: 12px 10px;
  }
  .otherCase__ttl::before {
    width: 15px;
    height: 12px;
    margin-right: 5px;
  }
  .otherCaseList {
    padding: 20px 13px;
  }
  .otherCaseList__item a {
    font-size: 1.4rem;
    padding-left: 18px;
    display: block;
    position: relative;
  }
  .otherCaseList__item a::before {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    left: 0;
  }
  .otherCaseList__item + .otherCaseList__item {
    margin-top: 10px;
  }
  /* メリット */
  .pushBox {
    width: calc(100% + 8%);
    margin-left: -4%;
  }
  .pushBox .merit {
    padding: 30px 0;
    background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom;
    background-size: 80% auto;
  }
  .pushBox .merit__inner {
    background: url(../img/merit_bg_sp.jpg) no-repeat center center;
    background-size: cover;
  }
  .pushBox .merit__ttl {
    font-size: 2rem;
    margin-bottom: 28px;
  }
  .pushBox .merit__ttl .num {
    font-size: 2.6rem;
  }
  .pushBox .meritList {
    padding: 6% 10px;
    min-height: 250px;
    max-width: inherit;
  }
  .pushBox .meritList__item {
    font-size: 1.7rem;
  }
  .pushBox .meritList__item::before {
    width: 16px;
    height: 14px;
  }
  .pushBox .meritList__item + .meritList__item {
    margin-top: 8px;
  }
  .pushBox .merit::after {
    content: none;
  }
  .pushBox .cvBnr__ttl {
    padding: 15px 15px 15px 90px;
  }
  .pushBox .cvBnr__ttl::after {
    width: 90px;
    height: 165px;
    background: url(../img/merit_img.png) no-repeat center bottom;
    background-size: contain;
    right: inherit;
    left: -10px;
  }
  .pushBox .cvBnr .contact {
    padding: 0;
  }
  .pushBox .pushLink {
    padding: 40px 8% 0;
  }
  .pushBox .pushLink__ttl .text {
    font-size: 1.9rem;
    margin-bottom: 18px;
    border-bottom: 2px solid #1e8edb;
  }
  .pushBox .pushLinkList {
    display: block;
  }
  .pushBox .pushLinkList__item {
    width: 100%;
    margin-top: 12px;
  }
  .pushBox .pushLinkList__item:not(:nth-child(2n - 1)) {
    margin-left: 0;
  }
  .pushBox .pushLinkList__item .text {
    font-size: 1.4rem;
    line-height: 1.4230;
  }
  .accessWrap {
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
  }
  .accessItem {
    width: 100%;
    display: block;
  }
  .accessItem__ttl {
    font-size: 1.6rem;
    border-bottom: 3px solid #1e8edb;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
  .accessItem__directions {
    margin-bottom: 20px;
  }
}

/*=================================
  page--article
=================================*/
/* singlePage */
.singlePage__in {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 0 100px;
  background: #fff;
}

.singlePage__in h1, .singlePage__in h2, .singlePage__in h3, .singlePage__in h4, .singlePage__in h5, .singlePage__in h6 {
  clear: both;
}

.singlePage__in p:not([class]) {
  font-size: 1.8rem;
  line-height: 1.88888;
}

.singlePage__in p:not([class]) + p:not([class]) {
  margin-top: 40px;
}

.singlePage__in h1:not([class]) {
  font-size: 3.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.44444;
  margin-bottom: 30px;
}

.singlePage__in h2:not([class]) {
  font-size: 3.4rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.23529;
  padding: 38px 86px;
  width: calc(100% + 160px);
  background: #4aa6e5;
  border-top: 8px solid #0a73bb;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  margin-top: 78px;
  margin-bottom: 60px;
  margin-left: -80px;
}

.singlePage__in h3:not([class]) {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.41666;
  background: #f0f8fe;
  border-top: 7px solid #1e8edb;
  padding: 25px 30px;
  margin-top: 60px;
  margin-bottom: 50px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.singlePage__in h4:not([class]) {
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.3636;
  color: #1e8edb;
  background: #fff;
  padding: 25px 0;
  margin-top: 35px;
  margin-bottom: 25px;
  border-top: 5px solid #1e8edb;
}

.singlePage__in h5:not([class]) {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.44444;
  background: #fff;
  padding: 8px 12px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 4px solid #1e8edb;
}

.singlePage__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.singlePage__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #1e8edb;
  position: absolute;
  left: 0;
  top: 8px;
}

.singlePage__in blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ccc;
}

.singlePage__in blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #1e8edb;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.singlePage__in blockquote h4, .singlePage__in blockquote h5, .singlePage__in blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.singlePage__in blockquote h4::after, .singlePage__in blockquote h5::after, .singlePage__in blockquote h6::after {
  content: "";
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  background-image: repeating-linear-gradient(-45deg, #f0f8fe, #1e8edb 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.singlePage__in blockquote p {
  font-style: normal;
}

.singlePage__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.singlePage__in .btnWrap {
  clear: both;
}

.singlePage__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}

.singlePage__in ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
}

.singlePage__in ul:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
}

.singlePage__in ul:not([class]) li::before {
  content: "";
  width: 13px;
  height: 3px;
  display: inline-block;
  background: #1e8edb;
  margin: 0 15px 3px 0;
}

.singlePage__in ul:not([class]) li + li {
  margin-top: 15px;
}

.singlePage__in ul:not([class]) h6::before {
  content: none;
}

.singlePage__in ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
  counter-reset: listNum;
}

.singlePage__in ol:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
}

.singlePage__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  width: 22px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  border-radius: 50%;
  margin: 0 15px 3px 0;
}

.singlePage__in ol:not([class]) li + li {
  margin-top: 15px;
}

.singlePage__in ol:not([class]) h6::before {
  content: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .singlePage__in p:not([class]) + p:not([class]) {
    margin-top: 15px;
  }
  .singlePage__in h1:not([class]) {
    font-size: 2.1rem;
    line-height: 1.2380;
    margin-bottom: 15px;
  }
  .singlePage__in h2:not([class]) {
    font-size: 2rem;
    line-height: 1.3;
    padding: 15px;
    width: calc(100% + 8%);
    border-top: 5px solid #0a73bb;
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: -4%;
  }
  .singlePage__in h3:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3125;
    border-top: 4px solid #1e8edb;
    padding: 15px;
    margin-top: 35px;
    margin-bottom: 33px;
  }
  .singlePage__in h4:not([class]) {
    font-size: 1.5rem;
    line-height: 1.30769;
    padding: 12px 0;
    margin-top: 35px;
    margin-bottom: 25px;
    border-top: 4px solid #1e8edb;
  }
  .singlePage__in h5:not([class]) {
    font-size: 1.5rem;
    padding: 3px 10px;
  }
  .singlePage__in h6:not([class]) {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .singlePage__in h6:not([class])::before {
    width: 8px;
    height: 8px;
  }
  .singlePage__in blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .singlePage__in blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .singlePage__in blockquote h4, .singlePage__in blockquote h5, .singlePage__in blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .singlePage__in blockquote h4::after, .singlePage__in blockquote h5::after, .singlePage__in blockquote h6::after {
    height: 7px;
  }
  .singlePage__in img:not([class]) {
    display: block;
    margin: 15px auto;
  }
  .singlePage__in ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .singlePage__in ul:not([class]) li {
    font-size: 1.5rem;
  }
  .singlePage__in ul:not([class]) li::before {
    width: 10px;
    margin: 0 10px 3px 0;
  }
  .singlePage__in ul:not([class]) li + li {
    margin-top: 10px;
  }
  .singlePage__in ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .singlePage__in ol:not([class]) li {
    font-size: 1.5rem;
  }
  .singlePage__in ol:not([class]) li::before {
    font-size: 1.4rem;
    margin: 0 8px 3px 0;
  }
  .singlePage__in ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.singlePage__in iframe {
  width: 100%;
}

.singlePage__in h2:not([class]) {
  width: 100%;
  margin-left: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.singlePage__in h2:not([class]):first-of-type {
  margin-top: 0;
}

.singlePage .tocBox {
  margin: 70px 0 75px;
  padding: 40px;
  background: url(../img/toc_bg.jpg) repeat left top;
}

.singlePage .tocBox__inner {
  background: #fff;
  padding: 30px 38px;
}

.singlePage .tocBox__ttl {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}

.singlePage .tocBox .tocList__item {
  position: relative;
}

.singlePage .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.4rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  color: #303030;
  text-decoration: none;
  display: block;
  padding: 8px 24px;
}

@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
  }
}

.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}

.singlePage .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}

.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}

.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #1e8edb;
  border: 1px solid #1e8edb;
  text-align: center;
  margin-top: 20px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.singlePage .tocBox .tocMore::after {
  content: "";
  width: 12px;
  height: 8px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: rotate(180deg);
}

@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #1e8edb;
    background: #fff;
  }
  .singlePage .tocBox .tocMore:hover::after {
    background: url(../img/icon/icon_arrow_b.png) no-repeat center center;
    background-size: contain;
  }
}

.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.singlePage .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}

.singlePage .supervised {
  border: 1px solid #aacce6;
  max-width: 818px;
  padding: 16px 48px;
  margin: 28px auto 0;
  clear: both;
}

.singlePage .supervised .thumbWrap {
  width: 104px;
  height: 104px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 36px;
}

.singlePage .supervised .thumbWrap img {
  width: 100%;
}

.singlePage .supervised__info {
  display: flex;
}

.singlePage .supervised__cont {
  width: calc(100% - 140px);
}

.singlePage .supervised__label {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  background: #f0f8fe;
  margin-bottom: 12px;
  padding: 3px;
}

.singlePage .supervised__name {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.55555;
  text-align: center;
}

.singlePage .supervised__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.55555;
  padding: 15px;
  margin: 16px 0 0;
  background: #f3f3f3;
}

.singlePage .supervised .btn {
  margin: 20px auto 0;
  text-align: center;
}

.singlePage .supervised .btn a {
  font-size: 1.8rem;
  color: #fff;
  background: #1e8edb;
  text-decoration: none;
  padding: 12px 40px;
  display: inline-block;
}

.singlePage .supervised .btn a::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .singlePage .supervised .btn a:hover {
    opacity: .8;
  }
}

.singlePage .infoWrap {
  margin-bottom: 26px;
}

.singlePage .infoWrap .data {
  display: flex;
}

.singlePage .infoWrap .data .date {
  font-size: 1.8rem;
  padding-left: 20px;
  background: url(../img/icon/icon_clock.png) no-repeat left center;
  background-size: 16px auto;
}

.singlePage .infoWrap .data .date + .date {
  margin-left: 1rem;
  background-size: 16px auto;
}

.singlePage .articleMv {
  margin: 30px auto 60px;
  text-align: center;
}

.singlePage .articleMv .caption {
  font-size: 1.6rem;
  color: #545454;
  margin-top: 25px;
}

.singlePage .articleMv.cover {
  position: relative;
}

.singlePage .articleMv.cover::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(230, 230, 230, 0.6);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.singlePage .articleMv.cover img {
  width: 100%;
  margin: 0;
}

.singlePage .articleMv.cover .understand {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.singlePage .understand {
  margin: 60px auto 30px;
  text-align: center;
}

.singlePage .understand p {
  font-size: 3.4rem;
  font-weight: 500;
}

.singlePage .lead {
  font-size: 2.4rem;
  line-height: 1.4166;
  color: #1e8edb;
  margin: 0 0 30px;
}

.singlePage .point {
  background: #feefef;
  border: 1px solid #fdbec0;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.singlePage .point__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #f84444;
  background: #fff;
  border: 1px solid #f84444;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.singlePage .point__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.singlePage .related {
  background: #f0f8fe;
  border: 1px solid #aacce6;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.singlePage .related__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #fff;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.singlePage .related__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.singlePage .related--simple {
  margin: 70px auto;
  border: 3px solid #b9dbf4;
  padding: 45px 37px;
  position: relative;
}

.singlePage .related--simple__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #f0f8fe;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.singlePage .related--simple__cont {
  display: flex;
  align-items: flex-start;
}

.singlePage .related--simple__cont .thumb {
  width: 300px;
  margin-right: 40px;
}

.singlePage .related--simple__cont .textWrap {
  width: calc(100% - 340px);
}

.singlePage .related--simple__cont .textWrap__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.3846;
  text-decoration: none;
  display: block;
  margin-bottom: 25px;
}

.singlePage .related--simple__cont .textWrap__text {
  font-size: 1.8rem;
  line-height: 1.8888;
}

.singlePage .related--simple__cont .btn {
  text-align: right;
}

.singlePage .related--simple__cont .btn .text {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  text-decoration: none;
  padding: 16px 45px;
  margin-top: 28px;
}

.singlePage .related--simple__cont .btn .text::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .singlePage .related--simple:hover .btn .text {
    opacity: .8;
  }
}

.singlePage .relatedBox {
  max-width: 820px;
  margin: 65px auto 80px;
}

.singlePage .relatedBox__ttl {
  font-size: 3.4rem;
  font-weight: 500;
  color: #1e8edb;
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage .tocBox {
    margin: 35px 0 40px;
    padding: 20px;
  }
  .singlePage .tocBox__inner {
    padding: 25px 15px;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
  .singlePage .tocBox .tocList__item::before {
    top: 8px;
  }
  .singlePage .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 8px 0 8px 20px;
    position: relative;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 8px;
  }
  .singlePage .supervised {
    padding: 17px 20px;
    margin: 28px auto 0;
  }
  .singlePage .supervised .thumbWrap {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .singlePage .supervised__info {
    flex-wrap: wrap;
    position: relative;
  }
  .singlePage .supervised__cont {
    width: 100%;
  }
  .singlePage .supervised__name {
    font-size: 1.5rem;
    padding: 15px 0 0 100px;
    min-height: 80px;
  }
  .singlePage .supervised__text {
    font-size: 1.5rem;
  }
  .singlePage .supervised .btn {
    margin: 12px 0 0;
  }
  .singlePage .supervised .btn a {
    font-size: 1.2rem;
    padding: 10px 25px;
  }
  .singlePage .supervised .btn a::before {
    width: 17px;
    height: 17px;
    margin-right: 6px;
  }
  .singlePage .infoWrap {
    margin-bottom: 30px;
  }
  .singlePage .infoWrap .data .date {
    font-size: 1.4rem;
    padding-left: 17px;
    background-size: 12px auto;
  }
  .singlePage .infoWrap .data .date + .date {
    background-size: 12px auto;
  }
  .singlePage .articleMv {
    margin: 30px auto 45px;
  }
  .singlePage .articleMv .caption {
    font-size: 1.2rem;
    margin-top: 15px;
  }
  .singlePage .articleMv.cover .understand {
    text-align: left;
    padding: 3rem 3% 0;
  }
  .singlePage .understand {
    margin: 45px auto 35px;
  }
  .singlePage .understand p {
    font-size: 2.1rem;
  }
  .singlePage .lead {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .singlePage .point {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .singlePage .point__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .singlePage .point__text {
    font-size: 1.3rem;
  }
  .singlePage .related {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .singlePage .related__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .singlePage .related__text {
    font-size: 1.3rem;
  }
  .singlePage .related--simple {
    margin: 40px auto 30px;
    border: 2px solid #b9dbf4;
    padding: 35px 20px 15px;
  }
  .singlePage .related--simple__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .singlePage .related--simple__cont .thumb {
    width: 125px;
    margin-right: 10px;
  }
  .singlePage .related--simple__cont .textWrap {
    width: calc(100% - 135px);
  }
  .singlePage .related--simple__cont .textWrap__ttl {
    font-size: 1.5rem;
    line-height: 1.5384;
    margin-bottom: 0;
  }
  .singlePage .related--simple__cont .textWrap__text {
    display: none;
  }
  .singlePage .related--simple__cont .btn {
    text-align: center;
  }
  .singlePage .related--simple__cont .btn .text {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .singlePage .related--simple__cont .btn .text::before {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  .singlePage .relatedBox {
    margin: 37px auto 60px;
  }
  .singlePage .relatedBox__ttl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

/* 記事詳細ページ */
.articleDetail {
  padding-bottom: 95px;
  background: #f9f9f9;
}

.articleDetail__in {
  max-width: 1240px;
  margin: 0 auto;
  padding: 60px 80px 100px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  border-top: 9px solid #1e8edb;
}

.articleDetail__in h1, .articleDetail__in h2, .articleDetail__in h3, .articleDetail__in h4, .articleDetail__in h5, .articleDetail__in h6 {
  clear: both;
}

.articleDetail__in p:not([class]) {
  font-size: 1.8rem;
  line-height: 1.88888;
}

.articleDetail__in p:not([class]) + p:not([class]) {
  margin-top: 40px;
}

.articleDetail__in h1:not([class]) {
  font-size: 3.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.44444;
  margin-bottom: 30px;
}

.articleDetail__in h2:not([class]) {
  font-size: 3.4rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.23529;
  padding: 38px 86px;
  width: calc(100% + 160px);
  background: #4aa6e5;
  border-top: 8px solid #0a73bb;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  margin-top: 78px;
  margin-bottom: 60px;
  margin-left: -80px;
}

.articleDetail__in h3:not([class]) {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.41666;
  background: #f0f8fe;
  border-top: 7px solid #1e8edb;
  padding: 25px 30px;
  margin-top: 60px;
  margin-bottom: 50px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.articleDetail__in h4:not([class]) {
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.3636;
  color: #1e8edb;
  background: #fff;
  padding: 25px 0;
  margin-top: 35px;
  margin-bottom: 25px;
  border-top: 5px solid #1e8edb;
}

.articleDetail__in h5:not([class]) {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.44444;
  background: #fff;
  padding: 8px 12px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 4px solid #1e8edb;
}

.articleDetail__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.articleDetail__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #1e8edb;
  position: absolute;
  left: 0;
  top: 8px;
}

.articleDetail__in blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ccc;
}

.articleDetail__in blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #1e8edb;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.articleDetail__in blockquote h4, .articleDetail__in blockquote h5, .articleDetail__in blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.articleDetail__in blockquote h4::after, .articleDetail__in blockquote h5::after, .articleDetail__in blockquote h6::after {
  content: "";
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  background-image: repeating-linear-gradient(-45deg, #f0f8fe, #1e8edb 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.articleDetail__in blockquote p {
  font-style: normal;
}

.articleDetail__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.articleDetail__in .btnWrap {
  clear: both;
}

.articleDetail__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}

.articleDetail__in ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
}

.articleDetail__in ul:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
}

.articleDetail__in ul:not([class]) li::before {
  content: "";
  width: 13px;
  height: 3px;
  display: inline-block;
  background: #1e8edb;
  margin: 0 15px 3px 0;
}

.articleDetail__in ul:not([class]) li + li {
  margin-top: 15px;
}

.articleDetail__in ul:not([class]) h6::before {
  content: none;
}

.articleDetail__in ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
  counter-reset: listNum;
}

.articleDetail__in ol:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
}

.articleDetail__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  width: 22px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  border-radius: 50%;
  margin: 0 15px 3px 0;
}

.articleDetail__in ol:not([class]) li + li {
  margin-top: 15px;
}

.articleDetail__in ol:not([class]) h6::before {
  content: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .articleDetail__in p:not([class]) + p:not([class]) {
    margin-top: 15px;
  }
  .articleDetail__in h1:not([class]) {
    font-size: 2.1rem;
    line-height: 1.2380;
    margin-bottom: 15px;
  }
  .articleDetail__in h2:not([class]) {
    font-size: 2rem;
    line-height: 1.3;
    padding: 15px;
    width: calc(100% + 8%);
    border-top: 5px solid #0a73bb;
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: -4%;
  }
  .articleDetail__in h3:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3125;
    border-top: 4px solid #1e8edb;
    padding: 15px;
    margin-top: 35px;
    margin-bottom: 33px;
  }
  .articleDetail__in h4:not([class]) {
    font-size: 1.5rem;
    line-height: 1.30769;
    padding: 12px 0;
    margin-top: 35px;
    margin-bottom: 25px;
    border-top: 4px solid #1e8edb;
  }
  .articleDetail__in h5:not([class]) {
    font-size: 1.5rem;
    padding: 3px 10px;
  }
  .articleDetail__in h6:not([class]) {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .articleDetail__in h6:not([class])::before {
    width: 8px;
    height: 8px;
  }
  .articleDetail__in blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .articleDetail__in blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .articleDetail__in blockquote h4, .articleDetail__in blockquote h5, .articleDetail__in blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .articleDetail__in blockquote h4::after, .articleDetail__in blockquote h5::after, .articleDetail__in blockquote h6::after {
    height: 7px;
  }
  .articleDetail__in img:not([class]) {
    display: block;
    margin: 15px auto;
  }
  .articleDetail__in ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleDetail__in ul:not([class]) li {
    font-size: 1.5rem;
  }
  .articleDetail__in ul:not([class]) li::before {
    width: 10px;
    margin: 0 10px 3px 0;
  }
  .articleDetail__in ul:not([class]) li + li {
    margin-top: 10px;
  }
  .articleDetail__in ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleDetail__in ol:not([class]) li {
    font-size: 1.5rem;
  }
  .articleDetail__in ol:not([class]) li::before {
    font-size: 1.4rem;
    margin: 0 8px 3px 0;
  }
  .articleDetail__in ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.articleDetail__in iframe {
  width: 100%;
}

.articleDetail__in .cvBnr {
  margin: 80px auto;
}

.articleDetail .tocBox {
  margin: 70px 0 75px;
  padding: 40px;
  background: url(../img/toc_bg.jpg) repeat left top;
}

.articleDetail .tocBox__inner {
  background: #fff;
  padding: 30px 38px;
}

.articleDetail .tocBox__ttl {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}

.articleDetail .tocBox .tocList__item {
  position: relative;
}

.articleDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.4rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  color: #303030;
  text-decoration: none;
  display: block;
  padding: 8px 24px;
}

@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
  }
}

.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}

.articleDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}

.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}

.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #1e8edb;
  border: 1px solid #1e8edb;
  text-align: center;
  margin-top: 20px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.articleDetail .tocBox .tocMore::after {
  content: "";
  width: 12px;
  height: 8px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: rotate(180deg);
}

@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #1e8edb;
    background: #fff;
  }
  .articleDetail .tocBox .tocMore:hover::after {
    background: url(../img/icon/icon_arrow_b.png) no-repeat center center;
    background-size: contain;
  }
}

.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}

.articleDetail .supervised {
  border: 1px solid #aacce6;
  max-width: 818px;
  padding: 16px 48px;
  margin: 28px auto 0;
  clear: both;
}

.articleDetail .supervised .thumbWrap {
  width: 104px;
  height: 104px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 36px;
}

.articleDetail .supervised .thumbWrap img {
  width: 100%;
}

.articleDetail .supervised__info {
  display: flex;
}

.articleDetail .supervised__cont {
  width: calc(100% - 140px);
}

.articleDetail .supervised__label {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  background: #f0f8fe;
  margin-bottom: 12px;
  padding: 3px;
}

.articleDetail .supervised__name {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.55555;
  text-align: center;
}

.articleDetail .supervised__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.55555;
  padding: 15px;
  margin: 16px 0 0;
  background: #f3f3f3;
}

.articleDetail .supervised .btn {
  margin: 20px auto 0;
  text-align: center;
}

.articleDetail .supervised .btn a {
  font-size: 1.8rem;
  color: #fff;
  background: #1e8edb;
  text-decoration: none;
  padding: 12px 40px;
  display: inline-block;
}

.articleDetail .supervised .btn a::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .articleDetail .supervised .btn a:hover {
    opacity: .8;
  }
}

.articleDetail .infoWrap {
  margin-bottom: 26px;
}

.articleDetail .infoWrap .data {
  display: flex;
}

.articleDetail .infoWrap .data .date {
  font-size: 1.8rem;
  padding-left: 20px;
  background: url(../img/icon/icon_clock.png) no-repeat left center;
  background-size: 16px auto;
}

.articleDetail .infoWrap .data .date + .date {
  margin-left: 1rem;
  background-size: 16px auto;
}

.articleDetail .articleMv {
  margin: 30px auto 60px;
  text-align: center;
}

.articleDetail .articleMv .caption {
  font-size: 1.6rem;
  color: #545454;
  margin-top: 25px;
}

.articleDetail .articleMv.cover {
  position: relative;
}

.articleDetail .articleMv.cover::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(230, 230, 230, 0.6);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.articleDetail .articleMv.cover img {
  width: 100%;
  margin: 0;
}

.articleDetail .articleMv.cover .understand {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.articleDetail .understand {
  margin: 60px auto 30px;
  text-align: center;
}

.articleDetail .understand p {
  font-size: 3.4rem;
  font-weight: 500;
}

.articleDetail .lead {
  font-size: 2.4rem;
  line-height: 1.4166;
  color: #1e8edb;
  margin: 0 0 30px;
}

.articleDetail .point {
  background: #feefef;
  border: 1px solid #fdbec0;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.articleDetail .point__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #f84444;
  background: #fff;
  border: 1px solid #f84444;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleDetail .point__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.articleDetail .related {
  background: #f0f8fe;
  border: 1px solid #aacce6;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.articleDetail .related__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #fff;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleDetail .related__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.articleDetail .related--simple {
  margin: 70px auto;
  border: 3px solid #b9dbf4;
  padding: 45px 37px;
  position: relative;
}

.articleDetail .related--simple__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #f0f8fe;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleDetail .related--simple__cont {
  display: flex;
  align-items: flex-start;
}

.articleDetail .related--simple__cont .thumb {
  width: 300px;
  margin-right: 40px;
}

.articleDetail .related--simple__cont .textWrap {
  width: calc(100% - 340px);
}

.articleDetail .related--simple__cont .textWrap__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.3846;
  text-decoration: none;
  display: block;
  margin-bottom: 25px;
}

.articleDetail .related--simple__cont .textWrap__text {
  font-size: 1.8rem;
  line-height: 1.8888;
}

.articleDetail .related--simple__cont .btn {
  text-align: right;
}

.articleDetail .related--simple__cont .btn .text {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  text-decoration: none;
  padding: 16px 45px;
  margin-top: 28px;
}

.articleDetail .related--simple__cont .btn .text::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .articleDetail .related--simple:hover .btn .text {
    opacity: .8;
  }
}

.articleDetail .relatedBox {
  max-width: 820px;
  margin: 65px auto 80px;
}

.articleDetail .relatedBox__ttl {
  font-size: 3.4rem;
  font-weight: 500;
  color: #1e8edb;
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail .tocBox {
    margin: 35px 0 40px;
    padding: 20px;
  }
  .articleDetail .tocBox__inner {
    padding: 25px 15px;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
  .articleDetail .tocBox .tocList__item::before {
    top: 8px;
  }
  .articleDetail .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 8px 0 8px 20px;
    position: relative;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 8px;
  }
  .articleDetail .supervised {
    padding: 17px 20px;
    margin: 28px auto 0;
  }
  .articleDetail .supervised .thumbWrap {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .articleDetail .supervised__info {
    flex-wrap: wrap;
    position: relative;
  }
  .articleDetail .supervised__cont {
    width: 100%;
  }
  .articleDetail .supervised__name {
    font-size: 1.5rem;
    padding: 15px 0 0 100px;
    min-height: 80px;
  }
  .articleDetail .supervised__text {
    font-size: 1.5rem;
  }
  .articleDetail .supervised .btn {
    margin: 12px 0 0;
  }
  .articleDetail .supervised .btn a {
    font-size: 1.2rem;
    padding: 10px 25px;
  }
  .articleDetail .supervised .btn a::before {
    width: 17px;
    height: 17px;
    margin-right: 6px;
  }
  .articleDetail .infoWrap {
    margin-bottom: 30px;
  }
  .articleDetail .infoWrap .data .date {
    font-size: 1.4rem;
    padding-left: 17px;
    background-size: 12px auto;
  }
  .articleDetail .infoWrap .data .date + .date {
    background-size: 12px auto;
  }
  .articleDetail .articleMv {
    margin: 30px auto 45px;
  }
  .articleDetail .articleMv .caption {
    font-size: 1.2rem;
    margin-top: 15px;
  }
  .articleDetail .articleMv.cover .understand {
    text-align: left;
    padding: 3rem 3% 0;
  }
  .articleDetail .understand {
    margin: 45px auto 35px;
  }
  .articleDetail .understand p {
    font-size: 2.1rem;
  }
  .articleDetail .lead {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .articleDetail .point {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .articleDetail .point__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleDetail .point__text {
    font-size: 1.3rem;
  }
  .articleDetail .related {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .articleDetail .related__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleDetail .related__text {
    font-size: 1.3rem;
  }
  .articleDetail .related--simple {
    margin: 40px auto 30px;
    border: 2px solid #b9dbf4;
    padding: 35px 20px 15px;
  }
  .articleDetail .related--simple__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleDetail .related--simple__cont .thumb {
    width: 125px;
    margin-right: 10px;
  }
  .articleDetail .related--simple__cont .textWrap {
    width: calc(100% - 135px);
  }
  .articleDetail .related--simple__cont .textWrap__ttl {
    font-size: 1.5rem;
    line-height: 1.5384;
    margin-bottom: 0;
  }
  .articleDetail .related--simple__cont .textWrap__text {
    display: none;
  }
  .articleDetail .related--simple__cont .btn {
    text-align: center;
  }
  .articleDetail .related--simple__cont .btn .text {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .articleDetail .related--simple__cont .btn .text::before {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  .articleDetail .relatedBox {
    margin: 37px auto 60px;
  }
  .articleDetail .relatedBox__ttl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

.articleDetail .komon {
  background: transparent;
  margin: 65px auto;
  padding: 60px 0 !important;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.articleDetail .komon .secWrap__inner {
  max-width: inherit;
}

/* 記事一覧ページ */
.articleWrap {
  padding-bottom: 95px;
  background: #f9f9f9;
}

.articleWrap .articleList {
  margin-top: 80px;
}

.articleWrap__in {
  max-width: 1240px;
  margin: 0 auto;
  padding: 60px 5.5% 100px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  border-top: 9px solid #1e8edb;
}

.articleWrap__in h1, .articleWrap__in h2, .articleWrap__in h3, .articleWrap__in h4, .articleWrap__in h5, .articleWrap__in h6 {
  clear: both;
}

.articleWrap__in p:not([class]) {
  font-size: 1.8rem;
  line-height: 1.88888;
}

.articleWrap__in p:not([class]) + p:not([class]) {
  margin-top: 40px;
}

.articleWrap__in h1:not([class]) {
  font-size: 3.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.44444;
  margin-bottom: 30px;
}

.articleWrap__in h2:not([class]) {
  font-size: 3.4rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.23529;
  padding: 38px 86px;
  width: calc(100% + 160px);
  background: #4aa6e5;
  border-top: 8px solid #0a73bb;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  margin-top: 78px;
  margin-bottom: 60px;
  margin-left: -80px;
}

.articleWrap__in h3:not([class]) {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.41666;
  background: #f0f8fe;
  border-top: 7px solid #1e8edb;
  padding: 25px 30px;
  margin-top: 60px;
  margin-bottom: 50px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.articleWrap__in h4:not([class]) {
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.3636;
  color: #1e8edb;
  background: #fff;
  padding: 25px 0;
  margin-top: 35px;
  margin-bottom: 25px;
  border-top: 5px solid #1e8edb;
}

.articleWrap__in h5:not([class]) {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.44444;
  background: #fff;
  padding: 8px 12px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-left: 4px solid #1e8edb;
}

.articleWrap__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.articleWrap__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #1e8edb;
  position: absolute;
  left: 0;
  top: 8px;
}

.articleWrap__in blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ccc;
}

.articleWrap__in blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #1e8edb;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.articleWrap__in blockquote h4, .articleWrap__in blockquote h5, .articleWrap__in blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.articleWrap__in blockquote h4::after, .articleWrap__in blockquote h5::after, .articleWrap__in blockquote h6::after {
  content: "";
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  background-image: repeating-linear-gradient(-45deg, #f0f8fe, #1e8edb 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.articleWrap__in blockquote p {
  font-style: normal;
}

.articleWrap__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.articleWrap__in .btnWrap {
  clear: both;
}

.articleWrap__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}

.articleWrap__in ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
}

.articleWrap__in ul:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
}

.articleWrap__in ul:not([class]) li::before {
  content: "";
  width: 13px;
  height: 3px;
  display: inline-block;
  background: #1e8edb;
  margin: 0 15px 3px 0;
}

.articleWrap__in ul:not([class]) li + li {
  margin-top: 15px;
}

.articleWrap__in ul:not([class]) h6::before {
  content: none;
}

.articleWrap__in ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 50px;
  counter-reset: listNum;
}

.articleWrap__in ol:not([class]) li {
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
}

.articleWrap__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  width: 22px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  border-radius: 50%;
  margin: 0 15px 3px 0;
}

.articleWrap__in ol:not([class]) li + li {
  margin-top: 15px;
}

.articleWrap__in ol:not([class]) h6::before {
  content: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleWrap__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .articleWrap__in p:not([class]) + p:not([class]) {
    margin-top: 15px;
  }
  .articleWrap__in h1:not([class]) {
    font-size: 2.1rem;
    line-height: 1.2380;
    margin-bottom: 15px;
  }
  .articleWrap__in h2:not([class]) {
    font-size: 2rem;
    line-height: 1.3;
    padding: 15px;
    width: calc(100% + 8%);
    border-top: 5px solid #0a73bb;
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: -4%;
  }
  .articleWrap__in h3:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3125;
    border-top: 4px solid #1e8edb;
    padding: 15px;
    margin-top: 35px;
    margin-bottom: 33px;
  }
  .articleWrap__in h4:not([class]) {
    font-size: 1.5rem;
    line-height: 1.30769;
    padding: 12px 0;
    margin-top: 35px;
    margin-bottom: 25px;
    border-top: 4px solid #1e8edb;
  }
  .articleWrap__in h5:not([class]) {
    font-size: 1.5rem;
    padding: 3px 10px;
  }
  .articleWrap__in h6:not([class]) {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .articleWrap__in h6:not([class])::before {
    width: 8px;
    height: 8px;
  }
  .articleWrap__in blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .articleWrap__in blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .articleWrap__in blockquote h4, .articleWrap__in blockquote h5, .articleWrap__in blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .articleWrap__in blockquote h4::after, .articleWrap__in blockquote h5::after, .articleWrap__in blockquote h6::after {
    height: 7px;
  }
  .articleWrap__in img:not([class]) {
    display: block;
    margin: 15px auto;
  }
  .articleWrap__in ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleWrap__in ul:not([class]) li {
    font-size: 1.5rem;
  }
  .articleWrap__in ul:not([class]) li::before {
    width: 10px;
    margin: 0 10px 3px 0;
  }
  .articleWrap__in ul:not([class]) li + li {
    margin-top: 10px;
  }
  .articleWrap__in ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleWrap__in ol:not([class]) li {
    font-size: 1.5rem;
  }
  .articleWrap__in ol:not([class]) li::before {
    font-size: 1.4rem;
    margin: 0 8px 3px 0;
  }
  .articleWrap__in ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.articleWrap__in iframe {
  width: 100%;
}

.articleWrap .tocBox {
  margin: 70px 0 75px;
  padding: 40px;
  background: url(../img/toc_bg.jpg) repeat left top;
}

.articleWrap .tocBox__inner {
  background: #fff;
  padding: 30px 38px;
}

.articleWrap .tocBox__ttl {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.articleWrap .tocBox .tocList {
  counter-reset: tocNum;
}

.articleWrap .tocBox .tocList__item {
  position: relative;
}

.articleWrap .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.4rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.articleWrap .tocBox .tocList__item a {
  font-size: 1.6rem;
  color: #303030;
  text-decoration: none;
  display: block;
  padding: 8px 24px;
}

@media screen and (min-width: 767px) {
  .articleWrap .tocBox .tocList__item a:hover {
    text-decoration: underline;
  }
}

.articleWrap .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}

.articleWrap .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}

.articleWrap .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}

.articleWrap .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #1e8edb;
  border: 1px solid #1e8edb;
  text-align: center;
  margin-top: 20px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.articleWrap .tocBox .tocMore::after {
  content: "";
  width: 12px;
  height: 8px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  transform: rotate(180deg);
}

@media screen and (min-width: 767px) {
  .articleWrap .tocBox .tocMore:hover {
    color: #1e8edb;
    background: #fff;
  }
  .articleWrap .tocBox .tocMore:hover::after {
    background: url(../img/icon/icon_arrow_b.png) no-repeat center center;
    background-size: contain;
  }
}

.articleWrap .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.articleWrap .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item > .tocList > .tocList__item:nth-child(n + 4) {
  display: none;
}

.articleWrap .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}

.articleWrap .supervised {
  border: 1px solid #aacce6;
  max-width: 818px;
  padding: 16px 48px;
  margin: 28px auto 0;
  clear: both;
}

.articleWrap .supervised .thumbWrap {
  width: 104px;
  height: 104px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 36px;
}

.articleWrap .supervised .thumbWrap img {
  width: 100%;
}

.articleWrap .supervised__info {
  display: flex;
}

.articleWrap .supervised__cont {
  width: calc(100% - 140px);
}

.articleWrap .supervised__label {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  background: #f0f8fe;
  margin-bottom: 12px;
  padding: 3px;
}

.articleWrap .supervised__name {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.55555;
  text-align: center;
}

.articleWrap .supervised__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.55555;
  padding: 15px;
  margin: 16px 0 0;
  background: #f3f3f3;
}

.articleWrap .supervised .btn {
  margin: 20px auto 0;
  text-align: center;
}

.articleWrap .supervised .btn a {
  font-size: 1.8rem;
  color: #fff;
  background: #1e8edb;
  text-decoration: none;
  padding: 12px 40px;
  display: inline-block;
}

.articleWrap .supervised .btn a::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .articleWrap .supervised .btn a:hover {
    opacity: .8;
  }
}

.articleWrap .infoWrap {
  margin-bottom: 26px;
}

.articleWrap .infoWrap .data {
  display: flex;
}

.articleWrap .infoWrap .data .date {
  font-size: 1.8rem;
  padding-left: 20px;
  background: url(../img/icon/icon_clock.png) no-repeat left center;
  background-size: 16px auto;
}

.articleWrap .infoWrap .data .date + .date {
  margin-left: 1rem;
  background-size: 16px auto;
}

.articleWrap .articleMv {
  margin: 30px auto 60px;
  text-align: center;
}

.articleWrap .articleMv .caption {
  font-size: 1.6rem;
  color: #545454;
  margin-top: 25px;
}

.articleWrap .articleMv.cover {
  position: relative;
}

.articleWrap .articleMv.cover::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(230, 230, 230, 0.6);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.articleWrap .articleMv.cover img {
  width: 100%;
  margin: 0;
}

.articleWrap .articleMv.cover .understand {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.articleWrap .understand {
  margin: 60px auto 30px;
  text-align: center;
}

.articleWrap .understand p {
  font-size: 3.4rem;
  font-weight: 500;
}

.articleWrap .lead {
  font-size: 2.4rem;
  line-height: 1.4166;
  color: #1e8edb;
  margin: 0 0 30px;
}

.articleWrap .point {
  background: #feefef;
  border: 1px solid #fdbec0;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.articleWrap .point__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #f84444;
  background: #fff;
  border: 1px solid #f84444;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleWrap .point__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.articleWrap .related {
  background: #f0f8fe;
  border: 1px solid #aacce6;
  padding: 30px 40px;
  margin: 70px auto 60px;
  position: relative;
}

.articleWrap .related__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #fff;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleWrap .related__text {
  font-size: 1.8rem;
  line-height: 1.77777;
}

.articleWrap .related--simple {
  margin: 70px auto;
  border: 3px solid #b9dbf4;
  padding: 45px 37px;
  position: relative;
}

.articleWrap .related--simple__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #f0f8fe;
  border: 1px solid #1e8edb;
  padding: 10px 40px;
  position: absolute;
  top: -30px;
  left: 38px;
}

.articleWrap .related--simple__cont {
  display: flex;
  align-items: flex-start;
}

.articleWrap .related--simple__cont .thumb {
  width: 300px;
  margin-right: 40px;
}

.articleWrap .related--simple__cont .textWrap {
  width: calc(100% - 340px);
}

.articleWrap .related--simple__cont .textWrap__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #1e8edb;
  line-height: 1.3846;
  text-decoration: none;
  display: block;
  margin-bottom: 25px;
}

.articleWrap .related--simple__cont .textWrap__text {
  font-size: 1.8rem;
  line-height: 1.8888;
}

.articleWrap .related--simple__cont .btn {
  text-align: right;
}

.articleWrap .related--simple__cont .btn .text {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: #1e8edb;
  display: inline-block;
  text-decoration: none;
  padding: 16px 45px;
  margin-top: 28px;
}

.articleWrap .related--simple__cont .btn .text::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/link_arrow_w.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

@media screen and (min-width: 767px) {
  .articleWrap .related--simple:hover .btn .text {
    opacity: .8;
  }
}

.articleWrap .relatedBox {
  max-width: 820px;
  margin: 65px auto 80px;
}

.articleWrap .relatedBox__ttl {
  font-size: 3.4rem;
  font-weight: 500;
  color: #1e8edb;
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleWrap .tocBox {
    margin: 35px 0 40px;
    padding: 20px;
  }
  .articleWrap .tocBox__inner {
    padding: 25px 15px;
  }
  .articleWrap .tocBox__ttl {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
  .articleWrap .tocBox .tocList__item::before {
    top: 8px;
  }
  .articleWrap .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 8px 0 8px 20px;
    position: relative;
  }
  .articleWrap .tocBox .tocMore {
    font-size: 1.3rem;
    margin-top: 15px;
    padding: 5px 8px;
  }
  .articleWrap .supervised {
    padding: 17px 20px;
    margin: 28px auto 0;
  }
  .articleWrap .supervised .thumbWrap {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .articleWrap .supervised__info {
    flex-wrap: wrap;
    position: relative;
  }
  .articleWrap .supervised__cont {
    width: 100%;
  }
  .articleWrap .supervised__name {
    font-size: 1.5rem;
    padding: 15px 0 0 100px;
    min-height: 80px;
  }
  .articleWrap .supervised__text {
    font-size: 1.5rem;
  }
  .articleWrap .supervised .btn {
    margin: 12px 0 0;
  }
  .articleWrap .supervised .btn a {
    font-size: 1.2rem;
    padding: 10px 25px;
  }
  .articleWrap .supervised .btn a::before {
    width: 17px;
    height: 17px;
    margin-right: 6px;
  }
  .articleWrap .infoWrap {
    margin-bottom: 30px;
  }
  .articleWrap .infoWrap .data .date {
    font-size: 1.4rem;
    padding-left: 17px;
    background-size: 12px auto;
  }
  .articleWrap .infoWrap .data .date + .date {
    background-size: 12px auto;
  }
  .articleWrap .articleMv {
    margin: 30px auto 45px;
  }
  .articleWrap .articleMv .caption {
    font-size: 1.2rem;
    margin-top: 15px;
  }
  .articleWrap .articleMv.cover .understand {
    text-align: left;
    padding: 3rem 3% 0;
  }
  .articleWrap .understand {
    margin: 45px auto 35px;
  }
  .articleWrap .understand p {
    font-size: 2.1rem;
  }
  .articleWrap .lead {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .articleWrap .point {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .articleWrap .point__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleWrap .point__text {
    font-size: 1.3rem;
  }
  .articleWrap .related {
    padding: 25px 20px 20px;
    margin: 40px auto 35px;
  }
  .articleWrap .related__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleWrap .related__text {
    font-size: 1.3rem;
  }
  .articleWrap .related--simple {
    margin: 40px auto 30px;
    border: 2px solid #b9dbf4;
    padding: 35px 20px 15px;
  }
  .articleWrap .related--simple__ttl {
    font-size: 1.4rem;
    padding: 6px 18px;
    top: -19px;
    left: 19px;
  }
  .articleWrap .related--simple__cont .thumb {
    width: 125px;
    margin-right: 10px;
  }
  .articleWrap .related--simple__cont .textWrap {
    width: calc(100% - 135px);
  }
  .articleWrap .related--simple__cont .textWrap__ttl {
    font-size: 1.5rem;
    line-height: 1.5384;
    margin-bottom: 0;
  }
  .articleWrap .related--simple__cont .textWrap__text {
    display: none;
  }
  .articleWrap .related--simple__cont .btn {
    text-align: center;
  }
  .articleWrap .related--simple__cont .btn .text {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .articleWrap .related--simple__cont .btn .text::before {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  .articleWrap .relatedBox {
    margin: 37px auto 60px;
  }
  .articleWrap .relatedBox__ttl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage__in {
    padding: 10px 4% 40px;
  }
  .singlePage__in > h2 {
    width: calc(100% + 8%);
    margin-left: -4%;
    padding-left: 15px;
    padding-right: 15px;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding-bottom: 0;
  }
  .articleDetail__in {
    padding: 35px 4% 40px;
    border-top: 5px solid #1e8edb;
  }
  .articleDetail__in .cvBnr {
    margin: 50px auto;
  }
  .articleDetail .komon {
    margin: 30px auto;
    padding: 35px 0 !important;
  }
  /* 記事一覧ページ */
  .articleWrap {
    padding-bottom: 0;
  }
  .articleWrap .articleList {
    margin-top: 35px;
  }
  .articleWrap__in {
    padding: 35px 4% 40px;
    border-top: 5px solid #1e8edb;
  }
}

/*=================================
  お問い合わせ
=================================*/
.contactWrap__in {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 3% 100px;
  background: #fff;
}

.contactWrap .telBox {
  padding: 40px 30% 40px 4%;
  max-width: 90%;
  margin: 80px auto 0;
  border-top: 1px solid #1e8edb;
  border-bottom: 1px solid #1e8edb;
  text-align: center;
  position: relative;
}

.contactWrap .telBox::after {
  content: "";
  width: 30%;
  height: 264px;
  display: inline-block;
  background: url(../img/contact_img01.png) no-repeat center bottom;
  background-size: contain;
  position: absolute;
  right: 4%;
  bottom: 0;
}

.contactWrap .telBox__ttl {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1;
  color: #1e8edb;
  margin-bottom: 20px;
}

.contactWrap .telBox__num {
  font-family: 'Roboto', sans-serif;
  font-size: 5.7rem;
  font-weight: 500;
  line-height: 1;
  color: #1e8edb;
  text-decoration: none;
}

.contactWrap .telBox__num::before {
  content: "";
  width: 36px;
  height: 36px;
  display: inline-block;
  background: url(../img/icon/icon_phone.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 5px;
}

.contactWrap .telBox__text {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
  color: #1e8edb;
  margin-top: 15px;
}

.contactWrap .consulCase {
  margin-top: 75px;
  border: 11px solid #e7e7e7;
  background: #fff url(../img/contact_img02.png) no-repeat right top;
  padding: 40px;
}

.contactWrap .consulCase__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.contactWrap .consulCaseList__item {
  font-size: 2rem;
  padding-left: 44px;
  position: relative;
}

.contactWrap .consulCaseList__item::before {
  content: "";
  width: 27px;
  height: 27px;
  display: inline-block;
  background: url(../img/icon/icon_check_g.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 6px;
  left: 0;
}

.contactWrap .consulCaseList__item + .consulCaseList__item {
  margin-top: 20px;
}

.contactForm {
  background: #1e8edb;
  padding: 0 1% 115px;
}

.contactForm__in {
  max-width: 1000px;
  margin: 0 auto;
}

.contactForm__ttlWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactForm__ttlWrap .contactForm__ttl {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 74px;
  position: relative;
  width: 455px;
}

.contactForm__ttlWrap .contactForm__ttl .text {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.82352;
  color: #fff;
  border-bottom: 3px solid #ffea00;
  display: inline;
}

.contactForm__ttlWrap .contactForm__ttl .text .yellow {
  color: #ffea00;
}

.contactForm__ttlWrap .contactForm__ttl::after {
  content: "";
  width: 39px;
  height: 38px;
  display: inline-block;
  background: url(../img/arrow_yellow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.contactForm__ttlWrap .img {
  max-width: calc(100% - 477px);
  margin-top: -10px;
  margin-left: 22px;
}

.contactForm .formWrap {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  padding: 64px 50px 50px;
}

.contactForm .formWrap__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #1e8edb;
  border-bottom: 1px solid #303030;
  padding-bottom: 20px;
  margin-bottom: 45px;
}

.contactForm .formWrap__text {
  font-size: 1.8rem;
  margin-bottom: 35px;
}

.contactForm .formWrap .formCont__item {
  margin-top: 18px;
  border-bottom: 1px solid #1e8edb;
  display: flex;
}

.contactForm .formWrap .formCont__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  color: #1e8edb;
  background: #f0f8fe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px;
  width: 280px;
}

.contactForm .formWrap .formCont__ttl .required {
  font-size: 1.6rem;
  color: #fff;
  background: #d24147;
  padding: 0 7px;
}

.contactForm .formWrap .formCont__text {
  width: calc(100% - 280px);
  padding: 30px 20px;
}

.contactForm .formWrap .formCont__text input {
  border: 2px solid #ebebeb;
  background: #f6f6f6;
  padding: 13px 20px;
  width: 100%;
}

.contactForm .formWrap .formCont__text textarea {
  border: 2px solid #ebebeb;
  background: #f6f6f6;
  padding: 13px 20px;
  width: 100%;
}

.contactForm .formWrap .btnWrap {
  margin-top: 40px;
}

.contactForm .formWrap .btn--form {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background: #1e8edb;
}

@media screen and (min-width: 767px) {
  .contactForm .formWrap .btn--form:hover {
    opacity: .7;
  }
}

.contactForm .formWrap .btn--form input {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  min-width: 350px;
  padding: 37px 10px;
  background: url(../img/arrow_btn.png) no-repeat 5rem center;
  display: block;
}

.contactForm .formWrap .btn--form.back {
  background: #484848;
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactWrap__in {
    padding: 25px 3% 40px;
  }
  .contactWrap .telBox {
    padding: 25px 30% 25px 5px;
    max-width: 100%;
    margin: 35px auto 0;
  }
  .contactWrap .telBox::after {
    height: 124px;
    right: 5px;
  }
  .contactWrap .telBox__ttl {
    font-size: 1.5rem;
    margin-bottom: 18px;
  }
  .contactWrap .telBox__num {
    font-size: 3.4rem;
  }
  .contactWrap .telBox__num::before {
    width: 21px;
    height: 21px;
    margin-right: 3px;
  }
  .contactWrap .telBox__text {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .contactWrap .consulCase {
    margin-top: 33px;
    border: 5px solid #e7e7e7;
    padding: 35px 15px;
    background-size: 55% auto;
  }
  .contactWrap .consulCase__ttl {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  .contactWrap .consulCaseList__item {
    font-size: 1.3rem;
    padding-left: 24px;
  }
  .contactWrap .consulCaseList__item::before {
    width: 17px;
    height: 17px;
    top: 2px;
  }
  .contactWrap .consulCaseList__item + .consulCaseList__item {
    margin-top: 15px;
  }
  .contactForm {
    padding: 0 3% 40px;
  }
  .contactForm__ttlWrap {
    display: block;
  }
  .contactForm__ttlWrap .contactForm__ttl {
    padding-top: 25px;
    padding-bottom: 60px;
    width: inherit;
    margin-left: -10%;
  }
  .contactForm__ttlWrap .contactForm__ttl .text {
    font-size: 2.1rem;
    line-height: 1.47619;
    border-bottom: 2px solid #ffea00;
  }
  .contactForm__ttlWrap .contactForm__ttl::after {
    width: 35px;
    height: 34px;
  }
  .contactForm__ttlWrap .img {
    display: block;
    max-width: 50%;
    margin: -65px 0 0 auto;
  }
  .contactForm .formWrap {
    padding: 25px 15px 40px;
  }
  .contactForm .formWrap__ttl {
    font-size: 1.7rem;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  .contactForm .formWrap__text {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  .contactForm .formWrap .formCont__item {
    margin-top: 0;
    border-bottom: none;
    display: block;
  }
  .contactForm .formWrap .formCont__ttl {
    font-size: 1.6rem;
    border-bottom: 2px solid #1e8edb;
    justify-content: flex-start;
    padding: 13px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .contactForm .formWrap .formCont__ttl .required {
    font-size: 1.3rem;
    padding: 0 4px;
    margin-left: 10px;
  }
  .contactForm .formWrap .formCont__text {
    width: 100%;
    padding: 17px 0;
  }
  .contactForm .formWrap .formCont__text input {
    padding: 10px 12px;
  }
  .contactForm .formWrap .formCont__text textarea {
    padding: 10px 12px;
  }
  .contactForm .formWrap .btnWrap {
    margin-top: 20px;
    text-align: center;
  }
  .contactForm .formWrap .btn--form {
    width: 80%;
  }
  .contactForm .formWrap .btn--form input {
    font-size: 1.5rem;
    padding: 22px 10px;
    min-width: 100%;
    background: url(../img/arrow_btn.png) no-repeat 3rem center;
    background-size: auto 26px;
  }
}

/*=================================
  page-qa
=================================*/
.qaNav {
  background: #f9f9f9;
  padding: 40px;
  margin: 70px auto;
}

.qaNav__inner {
  box-shadow: 0px 0px 21.25px 3.75px rgba(11, 3, 6, 0.05);
  background: #fff;
}

.qaNav__ttl {
  font-size: 2.2rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding: 22px 15px;
  background: #1e8edb;
}

.qaNav__ttl::before {
  content: "";
  width: 25px;
  height: 19px;
  display: inline-block;
  background: url(../img/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  margin-right: 12px;
}

.qaNav .qaMenu {
  display: flex;
  flex-wrap: wrap;
}

.qaNav .qaMenu li {
  text-align: center;
}

.qaNav .qaMenu li a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #303030;
  text-decoration: none;
  padding: 13px 24px;
  display: inline-block;
}

.qaNav .qaMenu li a::before {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #1e8edb;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
}

.qaNav .qaMenu li:not(.current-cat) {
  cursor: pointer;
}

.qaNav .qaMenu li.current-cat {
  background: #19476e;
}

.qaNav .qaMenu li.current-cat a {
  color: #fff;
}

.qaNav .qaMenu li.current-cat a::before {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 767px) {
  .qaNav .qaMenu li:hover:not(.current-cat) {
    background: #f0f8fe;
  }
}

@media screen and (max-width: 767px) {
  /* その他のお悩み事例 */
  .qaNav {
    padding: 15px;
    width: calc(100% + 8%);
    margin: 37px auto;
    margin-left: -4%;
  }
  .qaNav__ttl {
    font-size: 1.5rem;
    padding: 12px 10px;
  }
  .qaNav__ttl::before {
    width: 15px;
    height: 12px;
    margin-right: 5px;
  }
  .qaNav .qaMenu {
    flex-wrap: wrap;
  }
  .qaNav .qaMenu li a {
    font-size: 1.2rem;
    padding: 10px 18px;
  }
}

/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  padding: 0 1%;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.header__inner {
  max-width: 1240px;
  padding: 10px 0;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .ttl--site {
  max-width: 303px;
  display: block;
  margin-right: auto;
}

@media screen and (min-width: 767px) {
  .header .ttl--site:hover .logo {
    opacity: .8;
  }
}

.header .contact {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

.header .contact__wrap {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.header .contact__wrap + .contact__wrap {
  margin-right: 0;
}

.header .contact__ttl {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.125;
  color: #3f3f3f;
  border-radius: 50px;
  width: 70px;
  text-align: center;
}

.header .contact__ttl .num {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

.header .contact__cont {
  display: inline-block;
}

.header .contact .tel .contact__ttl {
  background: #e4f4ff;
  padding: 8px 0;
  margin-right: 4px;
  min-height: 70px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.header .contact .tel .contact__ttl .national {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1;
  color: #ee063c;
  display: block;
}

.header .contact .tel .telNum {
  font-family: 'Roboto', sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  line-height: 1;
  color: #1e8edb;
  text-decoration: none;
  display: block;
}

.header .contact .tel .telNum::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/icon/icon_phone.png) no-repeat center center;
  background-size: contain;
  margin-right: 2px;
}

.header .contact .tel .telText {
  font-size: 1.4rem;
  line-height: 1;
  color: #484848;
  display: block;
  width: 100%;
  margin-left: 30px;
}

.header .contact .reservation .contact__ttl {
  background: #faeacf;
  padding: 11px 0;
  margin-right: 16px;
}

.header .contact .reservation .btn--mail {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: #f4b038;
  box-shadow: 0 5px 0 #e49102;
  border-radius: 5px;
  text-decoration: none;
  padding: 14px 20px;
  display: inline-block;
  vertical-align: middle;
  transition: none;
}

.header .contact .reservation .btn--mail::before {
  content: "";
  width: 25px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .header .contact .reservation .btn--mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.header .contact .reservation .btn--line {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  background: #19d24e;
  box-shadow: 0 5px 0 #15b944;
  border-radius: 5px;
  text-decoration: none;
  padding: 14px 20px;
  display: inline-block;
  vertical-align: middle;
  transition: none;
}

.header .contact .reservation .btn--line::before {
  content: "";
  width: 29px;
  height: 28px;
  display: inline-block;
  background: url(../img/icon/icon_line.png) no-repeat center center;
  background-size: contain;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .header .contact .reservation .btn--line:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.header .contact .reservation a + a {
  margin-left: 8px;
}

.header .contactBtn {
  display: flex !important;
  gap: 8px;
  margin: 0 10px 0 5px;
}

.header .contactBtn .mail {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  background: #f4b038;
  box-shadow: 0 5px 0 #e49102;
  border-radius: 5px;
  text-decoration: none;
  width: 60px;
  height: 60px;
  padding: 12px 0;
  display: block;
  transition: none;
  text-align: center;
}

.header .contactBtn .mail::before {
  content: "";
  width: 22px;
  height: 17px;
  display: block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin: 0 auto 8px;
}

@media screen and (min-width: 767px) {
  .header .contactBtn .mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.header .contactBtn .line {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  background: #19d24e;
  box-shadow: 0 5px 0 #15b944;
  border-radius: 5px;
  text-decoration: none;
  width: 60px;
  height: 60px;
  padding: 12px 0;
  display: block;
  transition: none;
  text-align: center;
}

.header .contactBtn .line::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url(../img/icon/icon_line.png) no-repeat center center;
  background-size: contain;
  text-align: center;
  margin: 0 auto 2px;
}

@media screen and (min-width: 767px) {
  .header .contactBtn .line:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.header .kuchikomi img {
  box-shadow: 0px 1px 8px 0px rgba(27, 27, 27, 0.15);
}

@media screen and (min-width: 767px) {
  .header .kuchikomi a:hover {
    opacity: .8;
  }
}

.header .pc {
  display: inline-block;
}

.header .sp {
  display: none;
}

.header .gNav {
  background: #fff url(../img/gNav_line.png) no-repeat center top;
  text-align: center;
}

.header .gNavList {
  max-width: 1240px;
  margin: 0 auto;
}

.header .gNavList__item {
  display: inline-block;
}

.header .gNavList__item a {
  font-size: 1.8rem;
  font-weight: 500;
  color: #303030;
  padding: 18px 20px;
  text-decoration: none;
  display: block;
  position: relative;
}

.header .gNavList__item a::after {
  content: "";
  width: 100%;
  height: 0;
  display: block;
  background: #1e8edb;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .header .gNavList__item a:hover {
    color: #1e8edb;
  }
  .header .gNavList__item a:hover::after {
    height: 4px;
  }
}

.header .gNavList__item.current-menu-item a {
  color: #1e8edb;
}

.header .gNavList__item.current-menu-item a::after {
  height: 4px;
}

@media screen and (min-width: 920px) {
  .gNav.fixedNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    background: #fff;
    z-index: 999;
  }
}

@media screen and (min-width: 921px) and (max-width: 1200px) {
  /* =================================
    header
  ================================= */
  .header .ttl--site {
    max-width: 180px;
  }
  .header .contact {
    padding: 0 10px;
  }
  .header .contact__ttl {
    font-size: 1.2rem;
    width: 70px;
  }
  .header .contact .tel .contact__ttl {
    min-height: 70px;
  }
  .header .contact .tel .telNum {
    font-size: 3rem;
  }
  .header .contact .tel .telNum::before {
    width: 22px;
    height: 22px;
  }
  .header .contact .tel .telText {
    font-size: 1.4rem;
    margin-left: 28px;
  }
  .header .contact .reservation .contact__ttl {
    padding: 9px 0;
    margin-right: 10px;
  }
  .header .contact .reservation .btn--mail {
    font-size: 1.5rem;
    box-shadow: 0 3px 0 #e49102;
    padding: 10px 12px;
  }
  .header .contact .reservation .btn--mail::before {
    width: 20px;
    height: 15px;
  }
  .header .contact .reservation .btn--line {
    font-size: 1.5rem;
    box-shadow: 0 3px 0 #15b944;
    padding: 10px 12px;
  }
  .header .contact .reservation .btn--line::before {
    width: 22px;
    height: 21px;
  }
  .header .contact .reservation a + a {
    margin-left: 5px;
  }
  .header .contactBtn .mail {
    font-size: 1rem;
    box-shadow: 0 3px 0 #e49102;
    width: 45px;
    height: 45px;
    padding: 10px 0;
  }
  .header .contactBtn .mail::before {
    width: 18px;
    height: 13px;
    margin: 0 auto 5px;
  }
}

@media screen and (min-width: 921px) and (max-width: 1200px) and (min-width: 767px) {
  .header .contactBtn .mail:hover {
    transform: translateY(3px);
  }
}

@media screen and (min-width: 921px) and (max-width: 1200px) {
  .header .contactBtn .line {
    font-size: 1rem;
    box-shadow: 0 3px 0 #15b944;
    width: 45px;
    height: 45px;
    padding: 6px 0;
  }
  .header .contactBtn .line::before {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto 3px;
  }
}

@media screen and (min-width: 921px) and (max-width: 1200px) and (min-width: 767px) {
  .header .contactBtn .line:hover {
    transform: translateY(3px);
  }
}

@media screen and (min-width: 921px) and (max-width: 1200px) {
  .header .kuchikomi {
    max-width: 250px;
  }
}

@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    position: fixed;
    padding: 0 2.5%;
    z-index: 99;
    /*バーガーボタン設定*/
  }
  .header__inner {
    width: calc(100% - 45px);
    padding: 10px 0;
    margin: 0;
    position: relative;
  }
  .header .ttl--site {
    max-width: initial;
    width: 40%;
    margin: 0;
  }
  .header .contactBtn {
    display: none !important;
  }
  .header .kuchikomi {
    width: 58%;
    margin-left: 1%;
  }
  .header .contact {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
  }
  .header .contact__wrap {
    display: block;
    margin-right: 0;
    margin-bottom: 0;
  }
  .header .contact__ttl {
    display: none !important;
  }
  .header .contact__cont {
    display: flex;
    align-items: flex-start;
    margin-left: 7px;
  }
  .header .contact .tel .telNum {
    font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    font-size: 1.2rem;
    color: #fff;
    padding: 10px 0 7px;
    border-radius: 5px;
    background: #47adf3;
    box-shadow: 0 4px 0 #1e8edb;
    text-align: center;
    width: 52px;
  }
  .header .contact .tel .telNum::before {
    width: 17px;
    height: 17px;
    display: block;
    background: url(../img/icon/icon_phone_w.png) no-repeat center center;
    background-size: contain;
    margin: 0 auto 5px;
  }
  .header .contact .tel .telText {
    display: none;
  }
  .header .contact .reservation .btn--mail {
    font-size: 1.2rem;
    line-height: 1;
    box-shadow: 0 4px 0 #e49102;
    padding: 12px 0 7px;
    text-align: center;
    width: 52px;
  }
  .header .contact .reservation .btn--mail::before {
    width: 17px;
    height: 14px;
    display: block;
    margin: 0 auto 6px;
  }
  .header .contact .reservation .btn--line {
    font-size: 1.2rem;
    line-height: 1;
    box-shadow: 0 4px 0 #15b944;
    padding: 10px 0 6px;
    text-align: center;
    width: 52px;
  }
  .header .contact .reservation .btn--line::before {
    width: 20px;
    height: 18px;
    display: block;
    margin: 0 auto 5px;
  }
  .header .contact .reservation a + a {
    margin-left: 7px;
  }
  .header .pc {
    display: none;
  }
  .header .sp {
    display: block;
    line-height: 1;
  }
  .header .gNav {
    width: 80%;
    background: #fff url(../img/burger_bg.png) repeat-x center bottom -15px;
    background-size: contain;
    overflow-y: scroll;
    max-height: 80vh;
    position: fixed;
    top: 35px;
    left: 50%;
    margin: 0;
    padding: 35px 5% 150px;
    z-index: 9998;
    text-align: left;
    transform: translateX(-50%) scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
  }
  .header .gNav__ttl {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    color: #1e8edb;
  }
  .header .gNavList__item {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
  .header .gNavList__item a {
    font-size: 1.5rem;
    padding: 10px 10px 10px 40px;
    border: 1px solid #1e8edb;
    color: #1e8edb;
    background: #fff;
  }
  .header .gNavList__item a::after {
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(../img/btn_arrow.png) no-repeat center center;
    background-size: contain;
    top: 50%;
    bottom: inherit;
    left: 15px;
    transform: translate(0, -50%);
  }
  .header .gNavList__item.current-menu-item a {
    color: #fff;
    background: #1e8edb;
  }
  .header .gNavList__item.current-menu-item a::after {
    background: url(../img/arrow_btn.png) no-repeat center center;
    background-size: contain;
    height: 17px;
  }
  .header .gNav.show {
    transform: translateX(-50%) scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: translateX(-50%) scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: url(../img/burgerCover_bg.jpg) no-repeat center center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9997;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show .2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide .2s linear 0s;
  }
  .header .burger {
    width: 55px;
    height: 55px;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -55px;
    cursor: pointer;
    z-index: 9999;
    background: url(../img/btn_burger_open.png) no-repeat center center;
    background-size: contain;
    /*クリック後、バツボタンになる*/
  }
  .header .burger.is-open {
    background: url(../img/btn_burger_close.png) no-repeat center center;
    background-size: contain;
  }
}

/*=================================
footer
=================================*/
.footer {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 80px 1% 70px;
  background: #303030;
}

.footer__inner {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer .infoWrap {
  width: 40%;
  min-width: 415px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 80px;
}

.footer .infoWrap .logo {
  margin-right: 20px;
}

.footer .infoWrap .snsList__item {
  display: inline-block;
  margin: 10px 5px 0;
}

.footer .infoWrap .snsList__item .icon {
  font-size: 2rem;
  color: #00000e;
  line-height: 38px;
  width: 38px;
  display: block;
  text-align: center;
  background: #484848;
  border-radius: 50px;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .footer .infoWrap .snsList__item .icon:hover {
    transform: rotateY(-360deg);
  }
}

.footer .infoWrap .addWrap {
  color: #dfdfdf;
  margin-top: 15px;
}

.footer .infoWrap .addWrap .tel__label {
  line-height: 1;
  display: inline-block;
  background: #484848;
  margin-right: 3px;
  padding: 5px 7px;
}

.footer .infoWrap .addWrap .tel__num {
  display: inline-block;
}

.footer .infoWrap .addWrap .tel__num a {
  color: #dfdfdf;
  text-decoration: none;
}

.footer .fNavList {
  background: #484848;
  padding: 28px 16px;
  width: 60%;
}

.footer .fNavList__item {
  display: inline-block;
  margin: 0 1rem;
}

.footer .fNavList__item a {
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
  }
}

.footer .copy {
  font-size: 1.6rem;
  letter-spacing: .06em;
  color: #dfdfdf;
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer .pageTop {
  position: fixed;
  bottom: 40px;
  right: calc((100% - 1240px) / 2);
  z-index: 98;
  cursor: pointer;
}

.footer .pageTop::after {
  content: "";
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  display: block;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .footer .pageTop:hover::after {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
  }
}

.fixBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: fixed;
  right: -100%;
  bottom: 120px;
  z-index: 9999;
  transition: right 1.2s ease-out;
  display: none;
}

.fixBnr .contact__wrap.tel .contact__ttl {
  font-size: 1.9rem;
  font-weight: 500;
  color: #1e8edb;
  background: #fdf7b3;
  padding: 9px 70px 9px 18px;
  position: relative;
}

.fixBnr .contact__wrap.tel .contact__ttl::after {
  content: "";
  width: 80px;
  height: 85px;
  display: inline-block;
  background: url(../img/cvBnr_img.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
}

.fixBnr .contact__wrap.tel .contact__ttl .national {
  font-size: 1.6rem;
  color: #ee063c;
  line-height: 1;
  display: block;
}

.fixBnr .contact__wrap.tel .contact__cont {
  padding: 10px 8px;
}

.fixBnr .contact__wrap.tel .contact__cont .telNum {
  font-family: 'Roboto', sans-serif;
  font-size: 3.9rem;
  font-weight: 500;
  line-height: 1;
  color: #1e8edb;
  text-decoration: none;
  display: block;
}

.fixBnr .contact__wrap.tel .contact__cont .telNum::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(../img/icon/icon_phone.png) no-repeat center center;
  background-size: contain;
  margin: -8px 2px 0 0;
  vertical-align: middle;
  animation: vibrate02 3s infinite;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .fixBnr .contact__wrap.tel .contact__cont .telNum:hover {
    color: #e49102;
  }
}

.fixBnr .contact__wrap.tel .contact__cont .telText {
  font-size: 1.4rem;
  text-align: center;
}

.fixBnr .contact__wrap.reservation {
  background: #fcf3e5;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fcf3e5 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fcf3e5 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fcf3e5 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcf3e5', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
  padding: 10px;
}

.fixBnr .contact__wrap.reservation .contact__ttl {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  color: #e49102;
  text-align: center;
  margin-bottom: 10px;
}

.fixBnr .contact__wrap.reservation .contact__cont {
  display: flex;
  justify-content: space-between;
}

.fixBnr .contact__wrap.reservation .contact__cont .btn--mail {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  width: calc(50% - 3px);
  text-align: center;
  display: block;
  padding: 13px 0;
  border-radius: 5px;
  background: #f4b038;
  box-shadow: 0 5px 0 #e49102;
  text-decoration: none;
  transition: none;
}

.fixBnr .contact__wrap.reservation .contact__cont .btn--mail::before {
  content: "";
  width: 20px;
  height: 15px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .fixBnr .contact__wrap.reservation .contact__cont .btn--mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.fixBnr .contact__wrap.reservation .contact__cont .btn--line {
  font-family: 'Roboto', sans-serif;
  font-size: 2.1rem;
  font-weight: 500;
  color: #fff;
  width: calc(50% - 3px);
  text-align: center;
  display: block;
  padding: 10px 0;
  border-radius: 5px;
  background: #19d24e;
  box-shadow: 0 5px 0 #15b944;
  text-decoration: none;
  transition: none;
}

.fixBnr .contact__wrap.reservation .contact__cont .btn--line::before {
  content: "";
  width: 24px;
  height: 23px;
  display: inline-block;
  margin-right: 6px;
  background: url(../img/icon/icon_line.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .fixBnr .contact__wrap.reservation .contact__cont .btn--line:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.fixBnr.show {
  right: 0;
}

@media screen and (min-width: 767px) and (max-width: 1300px) {
  /*=================================
  footer
  =================================*/
  .footer .pageTop {
    right: 3%;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
  footer
  =================================*/
  .footer {
    font-size: 1.2rem;
    padding: 40px 5% 15px;
  }
  .footer__inner {
    display: block;
  }
  .footer .infoWrap {
    width: 100%;
    min-width: inherit;
    display: block;
    padding-bottom: 0;
  }
  .footer .infoWrap .logo {
    width: 222px;
    margin: 0 auto;
    display: block;
  }
  .footer .infoWrap .snsList {
    margin-top: 20px;
    text-align: center;
  }
  .footer .infoWrap .snsList__item {
    margin: 0 5px;
  }
  .footer .infoWrap .addWrap {
    margin-top: 25px;
  }
  .footer .infoWrap .addWrap .tel {
    margin-top: 10px;
  }
  .footer .infoWrap .addWrap .tel__label {
    border-radius: 3px;
  }
  .footer .fNavList {
    margin-top: 17px;
    padding: 20px 12px;
    width: 100%;
  }
  .footer .fNavList__item {
    margin: 0 .5rem 1rem;
  }
  .footer .copy {
    font-size: 1.1rem;
    position: static;
    display: block;
    margin-top: 25px;
    text-align: center;
  }
  .footer .pageTop {
    width: 46px;
    bottom: 70px;
    right: 3%;
  }
  .footer .pageTop::after {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
  }
  .fixBnr {
    box-shadow: none;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
  }
  .fixBnr .contact {
    display: flex;
  }
  .fixBnr .contact__wrap.tel {
    background: #47adf3;
    padding: 6px 44px 6px 3px;
    text-align: center;
    position: relative;
    width: 55%;
  }
  .fixBnr .contact__wrap.tel::after {
    content: "";
    width: 45px;
    height: 100%;
    display: inline-block;
    background: url(../img/cvBnr_img.png) no-repeat center bottom;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .fixBnr .contact__wrap.tel .contact__ttl {
    font-size: 1.2rem;
    color: #fff;
    background: transparent;
    padding: 0;
  }
  .fixBnr .contact__wrap.tel .contact__ttl::after {
    content: none;
  }
  .fixBnr .contact__wrap.tel .contact__ttl .national {
    font-size: 1.2rem;
    display: inline;
  }
  .fixBnr .contact__wrap.tel .contact__cont {
    padding: 0;
  }
  .fixBnr .contact__wrap.tel .contact__cont .telNum {
    font-size: 2rem;
    color: #fff;
  }
  .fixBnr .contact__wrap.tel .contact__cont .telNum::before {
    width: 12px;
    height: 12px;
    background: url(../img/icon/icon_phone_w.png) no-repeat center center;
    background-size: contain;
    margin: -3px 2px 0 0;
  }
  .fixBnr .contact__wrap.tel .contact__cont .telText {
    font-size: .7rem;
    color: #fff;
  }
  .fixBnr .contact__wrap.reservation {
    background: transparent;
    padding: 0;
    width: 45%;
  }
  .fixBnr .contact__wrap.reservation .contact__ttl {
    display: none;
  }
  .fixBnr .contact__wrap.reservation .contact__cont {
    height: 100%;
  }
  .fixBnr .contact__wrap.reservation .contact__cont .btn--mail {
    font-size: 1.1rem;
    width: 50%;
    padding: 12px 0;
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: 100%;
  }
  .fixBnr .contact__wrap.reservation .contact__cont .btn--mail::before {
    width: 18px;
    height: 20px;
    margin: 0 auto 5px;
    display: block;
  }
  .fixBnr .contact__wrap.reservation .contact__cont .btn--line {
    font-size: 1.1rem;
    font-weight: 600;
    width: 50%;
    padding: 12px 0;
    border-radius: 0;
    box-shadow: none;
    display: block;
    height: 100%;
  }
  .fixBnr .contact__wrap.reservation .contact__cont .btn--line::before {
    width: 20px;
    height: 20px;
    margin: 0 auto 5px;
    display: block;
  }
}

/*=================================
  visual
=================================*/
.mv {
  background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom 30px;
  padding: 30px 1% 34px;
}

.mv__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.mv .cvBnr {
  top: 34px;
  margin-bottom: -24px;
}

@media screen and (max-width: 767px) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom 5px;
    background-size: auto 100px;
    padding: 3% 3%;
  }
  .mv .cvBnr {
    top: 0;
    margin: 17px 0 0;
  }
}

/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom 30px;
  padding: 55px 1% 120px;
}

.cvBnrWrap__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.cvBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: relative;
}

.cvBnr__ttl {
  font-size: 2.6rem;
  font-weight: 600;
  background: #fff697;
  padding: 6px 42px;
}

.cvBnr__ttl .text--red {
  color: #d10717;
}

.cvBnr__ttl .summary {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6666;
}

.cvBnr .contact {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 25px 65px;
  position: relative;
  z-index: 1;
}

.cvBnr .contact::after {
  content: "";
  width: 234px;
  height: 253px;
  display: inline-block;
  background: url(../img/cvBnr_img.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.cvBnr .contact__wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 60px;
  position: relative;
  z-index: 2;
}

.cvBnr .contact__wrap + .contact__wrap {
  margin-right: 0;
}

.cvBnr .contact__ttl {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.125;
  color: #3f3f3f;
  border-radius: 50px;
  width: 100px;
  text-align: center;
}

.cvBnr .contact__ttl .num {
  font-family: 'Roboto', sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
}

.cvBnr .contact__cont {
  display: inline-block;
}

.cvBnr .contact .tel .contact__ttl {
  background: #e4f4ff;
  padding: 19px 0 17px;
  margin-right: 20px;
  min-height: 100px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.cvBnr .contact .tel .contact__ttl .national {
  font-size: 1.3rem;
  font-weight: 700;
  color: #ee063c;
  margin: 5px 0 0;
  display: block;
}

.cvBnr .contact .tel .telNum {
  font-family: 'Roboto', sans-serif;
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 1;
  color: #1e8edb;
  text-decoration: none;
  display: block;
}

.cvBnr .contact .tel .telNum::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_phone.png) no-repeat center center;
  background-size: contain;
  margin-right: 2px;
}

.cvBnr .contact .tel .telText {
  font-size: 1.6rem;
  line-height: 1;
  color: #303030;
  display: block;
  width: 100%;
  margin-left: 30px;
}

.cvBnr .contact .reservation .contact__ttl {
  background: #faeacf;
  padding: 14px 0;
  margin-right: 20px;
}

.cvBnr .contact .reservation .btn--mail {
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  background: #f4b038;
  box-shadow: 0 5px 0 #e49102;
  border-radius: 5px;
  text-decoration: none;
  padding: 14px 20px;
  display: inline-block;
  vertical-align: middle;
  transition: none;
}

.cvBnr .contact .reservation .btn--mail::before {
  content: "";
  width: 25px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_mail.png) no-repeat center center;
  background-size: contain;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .cvBnr .contact .reservation .btn--mail:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.cvBnr .contact .reservation .btn--line {
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  background: #19d24e;
  box-shadow: 0 5px 0 #15b944;
  border-radius: 5px;
  text-decoration: none;
  padding: 14px 20px;
  display: inline-block;
  vertical-align: middle;
  transition: none;
}

.cvBnr .contact .reservation .btn--line::before {
  content: "";
  width: 29px;
  height: 28px;
  display: inline-block;
  background: url(../img/icon/icon_line.png) no-repeat center center;
  background-size: contain;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .cvBnr .contact .reservation .btn--line:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.cvBnr .contact .reservation a + a {
  margin-left: 8px;
}

.cvBnr .office {
  background: #fffac9;
  display: flex;
  justify-content: center;
  gap: 80px;
  padding: 20px 42px;
  border-top: 1px solid #eee8b2;
}

.cvBnr .office__item {
  text-align: center;
}

.cvBnr .office__item--ttl {
  font-weight: 600;
  margin-bottom: 5px;
}

.cvBnr .office__item--btn {
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  background: #3da7f0;
  box-shadow: 0 3px 0 #1e8edb;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .cvBnr .office__item--btn:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
  .cvBnr .office {
    justify-content: flex-start;
    gap: 50px;
    padding: 15px 0 20px 65px;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    background: #1e8edb url(../img/deco_repeat.png) repeat-x center bottom 15px;
    background-size: auto 80px;
    padding: 25px 3% 20px;
  }
  .cvBnr__ttl {
    font-size: 1.6rem;
    padding: 8px 70px 8px 12px;
    position: relative;
  }
  .cvBnr__ttl .summary {
    font-size: 1.3rem;
    line-height: 1.41666;
    margin-top: 5px;
  }
  .cvBnr__ttl::after {
    content: "";
    width: 86px;
    height: 76px;
    display: inline-block;
    background: url(../img/cvBnr_img.png) no-repeat center top;
    background-size: 86px auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .cvBnr .contact {
    padding: 0;
  }
  .cvBnr .contact::after {
    content: none;
  }
  .cvBnr .contact__wrap {
    width: 50%;
    display: block;
    margin: 0;
  }
  .cvBnr .contact__ttl {
    font-size: 1.4rem;
    border-radius: 0;
    width: 100%;
  }
  .cvBnr .contact__ttl .num {
    font-size: 1.4rem;
  }
  .cvBnr .contact__cont {
    display: block;
  }
  .cvBnr .contact .tel {
    background: #e4f4ff;
    padding: 8px 5px;
  }
  .cvBnr .contact .tel .contact__ttl {
    font-size: 1.4rem;
    font-weight: 600;
    color: #1e8edb;
    padding: 0;
    margin: 0 0 8px;
    min-height: initial;
  }
  .cvBnr .contact .tel .contact__ttl .national {
    font-size: clamp(1rem, 1vw, 1.2rem);
    margin: 0;
  }
  .cvBnr .contact .tel .contact__cont {
    background: #47adf3;
    box-shadow: 0 3px 0 #1e8edb;
    border-radius: 5px;
    text-align: center;
    padding: 15px 6px;
  }
  .cvBnr .contact .tel .telNum {
    font-size: 2rem;
    line-height: 1;
    color: #fff;
    display: block;
  }
  .cvBnr .contact .tel .telNum::before {
    width: 14px;
    height: 14px;
    background: url(../img/icon/icon_phone_w.png) no-repeat center center;
    background-size: contain;
  }
  .cvBnr .contact .tel .telText {
    font-size: .8rem;
    color: #fff;
    margin: 5px 0 0;
  }
  .cvBnr .contact .reservation {
    background: #faeacf;
    padding: 8px 5px;
  }
  .cvBnr .contact .reservation .contact__ttl {
    font-size: 1.4rem;
    font-weight: 600;
    color: #f29a00;
    padding: 0;
    margin: 0 0 8px;
  }
  .cvBnr .contact .reservation .contact__cont {
    display: flex;
  }
  .cvBnr .contact .reservation .btn--mail {
    font-size: 1.4rem;
    box-shadow: 0 3px 0 #e49102;
    padding: 7px 6px;
    width: 50%;
    text-align: center;
  }
  .cvBnr .contact .reservation .btn--mail span {
    line-height: 1;
  }
  .cvBnr .contact .reservation .btn--mail::before {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto 6px;
  }
  .cvBnr .contact .reservation .btn--line {
    font-size: 1.4rem;
    box-shadow: 0 3px 0 #15b944;
    padding: 7px 6px;
    width: 50%;
    text-align: center;
  }
  .cvBnr .contact .reservation .btn--line::before {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto 6px;
  }
  .cvBnr .contact .reservation a + a {
    margin-left: 7px;
  }
  .cvBnr .office {
    gap: 0;
    padding: 8px 5px 12px;
  }
  .cvBnr .office__item {
    width: 50%;
  }
  .cvBnr .office__item--ttl {
    font-size: 1.4rem;
    margin-bottom: 3px;
  }
}

/*=================================
  flow
=================================*/
.flow {
  padding: 75px 1% 80px;
  background: url(../img/flow_bg.jpg) no-repeat center top;
  background-size: cover;
}

.flow__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.flowItem {
  padding: 40px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.flowItem .step {
  min-width: 380px;
  margin-right: 40px;
  position: relative;
}

.flowItem .step .label {
  font-family: 'Roboto', sans-serif;
  font-size: 2.2rem;
  color: #fff;
  text-align: center;
  background: url(../img/step_bg.png) no-repeat center center;
  background-size: contain;
  width: 120px;
  line-height: 120px;
  position: absolute;
  top: -30px;
  left: -30px;
}

.flowItem .flowCont {
  width: calc(100% - 420px);
  min-width: 430px;
}

.flowItem .flowCont__ttl {
  font-size: 2.8rem;
  font-weight: 500;
  color: #1e8edb;
  margin: 15px 0;
}

.flowItem .flowCont__text {
  font-size: 1.8rem;
  line-height: 1.8888;
}

.flowItem .flowCont__text em {
  background: none;
}

.flowItem:not(:last-child) .step .label::after {
  content: "";
  width: 39px;
  height: 28px;
  display: inline-block;
  background: url(../img/step_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -36px;
  margin: 0 auto;
}

.flowItem + .flowItem {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  /*=================================
    flow
  =================================*/
  .flow {
    padding: 35px 3% 30px;
    background: #f1f1f1 url(../img/flow_bg.jpg) no-repeat center top;
    background-size: inherit;
  }
  .flowItem {
    padding: 20px;
    display: block;
  }
  .flowItem .step {
    min-width: inherit;
    margin: 0 0 13px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .flowItem .step .label {
    font-size: 1.4rem;
    width: 80px;
    line-height: 80px;
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
  }
  .flowItem .step .img {
    width: calc(100% - 105px);
  }
  .flowItem .flowCont {
    width: 100%;
    min-width: inherit;
  }
  .flowItem .flowCont__ttl {
    font-size: 1.6rem;
    margin: 0 0 12px;
  }
  .flowItem .flowCont__text {
    font-size: 1.4rem;
  }
  .flowItem:not(:last-child) .step .label::after {
    width: 25px;
    height: 18px;
    bottom: -23px;
  }
  .flowItem + .flowItem {
    margin-top: 15px;
  }
}

/*=================================
  access
=================================*/
.access {
  padding: 84px 0 0;
}

.access__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.access .imgList {
  display: flex;
  justify-content: center;
  margin: 0 auto 60px;
}

.access .gMap {
  height: 600px;
  overflow: hidden;
  position: relative;
}

.access .gMap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  /*=================================
    access
  =================================*/
  .access {
    padding: 38px 0 0;
  }
  .access__inner {
    padding: 0 3% 0;
  }
  .access .imgList {
    margin: 0 auto 25px;
  }
  .access .gMap {
    height: 350px;
  }
}

/*=================================
  overview
=================================*/
.overview {
  padding: 75px 1% 80px;
  background: url(../img/img_overview_pc.png) no-repeat right bottom #f0f8fe;
  background-size: 42% auto;
}

.overview__inner {
  max-width: 1240px;
  margin: 0 auto;
}

.overview__cont {
  width: 60%;
}

.overview .ttl--base {
  display: inline-block;
  border-bottom: 4px solid #1e8edb;
  letter-spacing: .1em;
  padding: 0 0 12px;
}

.overview p + p {
  margin-top: 40px;
}

.overview .overviewTable {
  background: none;
  box-shadow: none;
}

.overview .overviewTable tr:not(:first-child) {
  border-top: 1px solid #1e8edb;
}

.overview .overviewTable tr:first-child {
  border-top: 1px solid #1e8edb;
}

.overview .overviewTable tr:last-child {
  border-bottom: 1px solid #1e8edb;
}

.overview .overviewTable th {
  font-weight: 500;
  color: #1e8edb;
  background: none;
  border-right: none;
  padding: 20px 15px;
  width: 8em;
}

.overview .overviewTable th::after {
  content: none !important;
}

.overview .overviewTable td {
  text-align: left;
  padding: 20px 15px;
}

@media screen and (min-width: 1700px) {
  /*=================================
    overview
  =================================*/
  .overview {
    background: url(../img/img_overview_pc.png) no-repeat right top #f0f8fe;
    background-size: 40% auto;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    overview
  =================================*/
  .overview {
    padding: 35px 5% 30px;
    background: #f0f8fe;
    position: relative;
  }
  .overview::before {
    content: "";
    width: 170px;
    height: 206px;
    display: inline-block;
    background: url(../img/img_overview_sp.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 1%;
  }
  .overview__cont {
    width: 100%;
  }
  .overview .ttl--base {
    font-size: 2.2rem;
    text-align: left;
    padding: 50px 0;
  }
  .overview p + p {
    margin-top: 30px;
  }
  .overview .overviewTable {
    display: table;
    white-space: unset;
    margin-top: 50px;
  }
  .overview .overviewTable th {
    padding: 15px 10px;
  }
  .overview .overviewTable td {
    padding: 15px 10px;
  }
}

/*=================================
  komon
=================================*/
.komon {
  background: #f0f8fe;
  padding: 90px 1% 75px;
}

.komon .ttl--base {
  margin-bottom: 60px;
}

.komon .secWrap__inner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
}

.komon__text {
  width: calc(100% - 295px);
}

.komon__text .secWrap__text + .secWrap__text {
  margin-top: 40px;
}

.komon__img {
  width: 255px;
  margin: 0 40px 40px 0;
}

.komon__img .caption {
  margin: 20px 0 0;
}

.komon__table {
  width: 100%;
}

.komon__table th {
  color: #fff;
  text-align: center;
  background: #1e8edb;
}

.komon__table td {
  text-align: left;
}

@media screen and (max-width: 767px) {
  /*=================================
    komon
  =================================*/
  .komon {
    padding: 30px 2% 45px !important;
  }
  .komon .ttl--base {
    margin-bottom: 25px;
  }
  .komon .secWrap__inner {
    display: block;
  }
  .komon__text {
    width: 100%;
  }
  .komon__text .secWrap__text + .secWrap__text {
    margin-top: 30px;
  }
  .komon__img {
    width: 100%;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
  }
  .komon__img .img {
    width: 30%;
    margin-right: 20px;
  }
  .komon__img .caption {
    width: calc(70% - 20px);
    margin: 0;
  }
}

/*=================================
  isharyo
=================================*/
.isharyoWrap .isharyoTtl--lg {
  font-size: 3.4rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.23529;
  padding: 38px 86px;
  width: calc(100% + 160px);
  background: #4aa6e5;
  border-top: 8px solid #0a73bb;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  margin-top: 78px;
  margin-bottom: 60px;
  margin-left: -80px;
}

.isharyoWrap .isharyoTtl--md {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.41666;
  background: #f0f8fe;
  border-top: 7px solid #1e8edb;
  padding: 25px 30px;
  margin-top: 60px;
  margin-bottom: 50px;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.isharyoWrap .isharyoContent {
  border: 50px solid #f0f8fe;
  padding: 50px 3%;
}

.isharyoWrap .explanatoryText {
  margin: 0 0 50px;
}

.isharyoWrap .explanatoryText .red {
  font-weight: 600;
  color: #d13a46;
}

.isharyoWrap .required {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  width: fit-content;
  background: #d13a46;
  border-radius: 3px;
  padding: 5px 8px;
  position: absolute;
  top: 5px;
  right: 0;
}

.isharyoWrap .requiredError {
  font-size: 1.4rem;
  font-weight: 700;
  color: #d13a46;
  margin-top: 5px;
}

.isharyoWrap .calcItem {
  display: flex;
  margin: 50px 0;
}

.isharyoWrap .calcItem__title {
  font-size: 1.6rem;
  font-weight: 600;
  width: 250px;
  margin-right: 30px;
  padding-right: 48px;
  position: relative;
}

.isharyoWrap .calcItem__title .sm {
  font-weight: 500;
  display: block;
}

.isharyoWrap .calcItem__content {
  width: calc(100% - 280px);
}

.isharyoWrap .calcItem__content .notes {
  font-size: 1.4rem;
  margin-top: 5px;
}

.isharyoWrap input[type="text"] {
  border: 1px solid #828282;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 5px;
}

.isharyoWrap .radioBtn {
  position: relative;
  margin-right: 10px;
  margin-bottom: 5px;
  display: inline-block;
}

.isharyoWrap .radioBtn__item {
  padding-left: 30px;
  position: relative;
}

.isharyoWrap .radioBtn__item::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: block;
  border: 1px solid #828282;
  position: absolute;
  top: 0;
  left: 0;
}

.isharyoWrap .radioBtn__item::after {
  content: "";
  width: 14px;
  height: 14px;
  background: #1e8edb;
  border-radius: 50px;
  display: block;
  opacity: 0;
  position: absolute;
  top: 5px;
  left: 5px;
}

.isharyoWrap .radioBtn input[type="radio"] {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.isharyoWrap .radioBtn input[type="radio"]:checked + .radioBtn__item::after {
  opacity: 1;
}

.isharyoWrap select {
  border: 1px solid #828282;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 5px;
  background: #fff;
}

.isharyoWrap .resultBtn {
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  display: block;
  width: fit-content;
  text-align: center;
  background: #f4b038;
  box-shadow: 0 5px 0 #e49102;
  border-radius: 4px;
  padding: 20px 80px;
  transition: none;
  margin: 80px auto 0;
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .isharyoWrap .resultBtn:hover {
    transform: translateY(5px);
    box-shadow: none;
  }
}

.isharyoWrap .isharyoResult {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.isharyoWrap .isharyoResult.show {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.isharyoWrap .isharyoResult .resultNotes {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0;
}

.isharyoWrap .isharyoResult .resultTotal {
  background: #fffbcd;
  max-width: 700px;
  margin: 0 auto;
  padding: 10px 60px;
}

.isharyoWrap .isharyoResult .resultTotal .resultRow {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 20px 0;
}

.isharyoWrap .isharyoResult .resultTotal .resultRow__title {
  font-size: 2.8rem;
  font-weight: 500;
}

.isharyoWrap .isharyoResult .resultTotal .resultRow__value {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.8;
  margin-left: 20px;
}

.isharyoWrap .isharyoResult .resultTotal .resultRow__value .num {
  font-size: 5.5rem;
  font-weight: 700;
  line-height: 1;
  color: #1e8edb;
  display: inline-block;
  margin-right: 10px;
}

.isharyoWrap .isharyoResult .resultTotal .resultRow__value .text {
  font-size: 1.6rem;
  font-weight: 400;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow__title {
  font-size: 2rem;
  font-weight: 500;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow__value {
  font-size: 2rem;
  font-weight: 500;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow__value .num {
  font-size: 4rem;
  line-height: 1;
  color: #1e8edb;
  display: inline-block;
  margin: -5px 5px 0;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow__value .text {
  font-size: 1.6rem;
  font-weight: 400;
}

.isharyoWrap .isharyoResult .resultDetail .resultRow + .resultRow {
  margin-top: 20px;
}

.isharyoWrap .isharyoCvWrap {
  padding: 30px 30px 75px;
  margin-top: 80px;
  background: #f0f8fe url(../img/deco_repeat.png) repeat-x center bottom -5px;
  background-size: 60% auto;
  border: 30px solid #3dabf7;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
}

.isharyoWrap .isharyoCvWrap .cvBnr {
  margin: 0 auto;
}

.isharyoWrap .isharyoCvWrap .cvBnr::after {
  width: 200px;
  height: 216px;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact {
  padding-left: 30px;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact__wrap {
  margin-right: 20px;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact__ttl {
  width: 80px;
  font-size: 1.5rem;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact__ttl .num {
  font-size: 2rem;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact .tel .contact__ttl {
  min-height: 80px;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact .tel .telNum {
  font-size: 3.8rem;
}

.isharyoWrap .isharyoCvWrap .cvBnr .contact .reservation .contact__ttl {
  min-height: 80px;
}

.isharyoWrap .isharyoCvWrap .isharyoCvText {
  padding: 30px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
}

.isharyoWrap .isharyoCvWrap .isharyoCvText__item {
  padding-left: 1em;
  text-indent: -1em;
}

.isharyoWrap .isharyoCvWrap .isharyoCvText__item::before {
  content: "・";
  margin-right: .5em;
}

@media screen and (max-width: 767px) {
  /*=================================
    isharyo
  =================================*/
  .isharyoWrap .isharyoTtl--lg {
    font-size: 2rem;
    line-height: 1.3;
    padding: 15px;
    width: calc(100% + 8%);
    border-top: 5px solid #0a73bb;
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: -4%;
  }
  .isharyoWrap .isharyoTtl--md {
    font-size: 1.6rem;
    line-height: 1.3125;
    border-top: 4px solid #1e8edb;
    padding: 15px;
    margin-top: 35px;
    margin-bottom: 33px;
  }
  .isharyoWrap .isharyoContent {
    border: 15px solid #f0f8fe;
    padding: 30px 0;
  }
  .isharyoWrap .explanatoryText {
    margin: 0 0 40px;
    padding: 0 5%;
  }
  .isharyoWrap .required {
    font-size: 1.2rem;
    padding: 5px 6px;
    position: static;
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .isharyoWrap .requiredError {
    font-size: 1.3rem;
  }
  .isharyoWrap .calcItem {
    display: block;
    margin: 0;
  }
  .isharyoWrap .calcItem__title {
    font-size: 1.5rem;
    width: 100%;
    margin: 0;
    padding: 10px 10px;
    background: #f3f3f3;
  }
  .isharyoWrap .calcItem__title .sm {
    font-size: 1.4rem;
    display: inline-block;
  }
  .isharyoWrap .calcItem__content {
    width: 100%;
    padding: 20px 10px;
    font-weight: 600;
  }
  .isharyoWrap .calcItem__content .notes {
    font-weight: 500;
  }
  .isharyoWrap input[type="text"] {
    font-size: 1.6rem;
    padding: 5px 10px;
    margin-right: 5px;
    text-align: center;
    max-width: 70%;
  }
  .isharyoWrap .radioBtn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .isharyoWrap .radioBtn__item {
    padding-left: 25px;
  }
  .isharyoWrap .radioBtn__item::before {
    width: 20px;
    height: 20px;
  }
  .isharyoWrap .radioBtn__item::after {
    width: 10px;
    height: 10px;
  }
  .isharyoWrap .resultBtn {
    font-size: 2.2rem;
    box-shadow: 0 3px 0 #e49102;
    padding: 15px 10%;
    margin: 15px auto 0;
  }
  .isharyoWrap .isharyoResult .resultTotal {
    padding: 5px 5% 30px;
  }
  .isharyoWrap .isharyoResult .resultTotal .resultRow {
    text-align: center;
    display: block;
    margin: 30px 0 0;
  }
  .isharyoWrap .isharyoResult .resultTotal .resultRow__title {
    font-size: 2.4rem;
    line-height: 1;
  }
  .isharyoWrap .isharyoResult .resultTotal .resultRow__value {
    font-size: 2.4rem;
    line-height: 1;
    margin: 5px 0 0;
  }
  .isharyoWrap .isharyoResult .resultTotal .resultRow__value .num {
    font-size: 4.5rem;
  }
  .isharyoWrap .isharyoResult .resultTotal .resultRow__value .text {
    font-size: 1.5rem;
  }
  .isharyoWrap .isharyoResult .resultDetail .resultRow__title {
    font-size: 1.7rem;
    min-width: 125px;
  }
  .isharyoWrap .isharyoResult .resultDetail .resultRow__value {
    font-size: 1.7rem;
  }
  .isharyoWrap .isharyoResult .resultDetail .resultRow__value .num {
    font-size: 3.5rem;
    margin: 0 5px 0;
  }
  .isharyoWrap .isharyoResult .resultDetail .resultRow__value .text {
    font-size: 1.5rem;
  }
  .isharyoWrap .isharyoResult .resultDetail .resultRow + .resultRow {
    margin-top: 20px;
  }
  .isharyoWrap .isharyoCvWrap {
    padding: 0 0 45px;
    margin-top: 50px;
    background: #f0f8fe url(../img/deco_repeat.png) repeat-x center bottom -5px;
    background-size: 100% auto;
    border: 12px solid #3dabf7;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr {
    box-shadow: none;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact {
    padding-left: 0;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact__wrap {
    margin-right: 0;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact__ttl {
    width: initial;
    font-size: 1.4rem;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact__ttl .num {
    font-size: 1.4rem;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact .tel .contact__ttl {
    min-height: initial;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact .tel .telNum {
    font-size: 1.8rem;
  }
  .isharyoWrap .isharyoCvWrap .cvBnr .contact .reservation .contact__ttl {
    min-height: initial;
  }
  .isharyoWrap .isharyoCvWrap .isharyoCvText {
    padding: 15px 5%;
  }
}
