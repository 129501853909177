@charset "utf-8";
/*=================================
  visual
=================================*/
.mv {
  background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom 30px;
  padding: 30px 1% 34px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .cvBnr {
    top: 34px;
    margin-bottom: -24px;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom 5px;
    background-size: auto 100px;
    padding: 3% 3%;
    .cvBnr {
      top: 0;
      margin: 17px 0 0;
    }
  }
}