@charset "utf-8";
/*=================================
  topPage
=================================*/
.home,.page-template-page-areaLp {
  .mv {
    & + .secWrap {
      margin-top: 0;
    }
  }
  .merit {
    counter-reset: merit;
    padding-top: 90px!important;
    padding-bottom: 75px!important;
    background: $color-blue;
    > {
      .ttl--base {
        margin-bottom: 50px!important;
      }
    }
    .deco--merit {
      position: relative;
      &::before {
        counter-increment: merit;
        content: "MERIT0"counter(merit);
        font-size: 7rem;
        font-weight: 700;
        font-style: italic;
        color: #fff;
        line-height: 1;
        background: linear-gradient(90deg, #7ae8ff, #dae986);
        display: block;
        margin-bottom: 20px;
        max-width: 350px;
        text-align: center;
        padding: 5px 0;
      }
      &:not(.left) {
        &::before {
          text-align: center;
          margin: 0 auto 20px;
        }
      }
    }
  }
  .secWrap {
    .secWrap {
      padding-left: 0;
      padding-right: 0;
    }
    padding: 0 1%;
    margin: 90px 0 75px;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
    .btnWrap {
      margin-bottom: 0;
    }
    .textWrap {
      position: relative;
    }
    .ttl--base {
      font-size: 3.8rem;
      line-height: 1.36842;
      margin-bottom: 30px;
      .st {
        font-size: 3.8rem;
      }
      .lg {
        font-size: 5rem;
      }
      .marker {
        background: linear-gradient(transparent 60%, #fff697 0%);
      }
      &.colorText {
        color: $color-theme;
        margin-bottom: 50px;
      }
      &.whiplash {
        padding-right: 15%;
        position: relative;
        &::after {
          content: "";
          width: 18%;
          height: 86px;
          display: inline-block;
          background: url(../img/top_img02_deco_pc.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    &__text {
      font-size: 1.8rem;
      & + .secWrap__text {
        margin-top: 2rem;
      }
      em {
        background: none;
      }
    }
    &.imgRight {
      .secWrap__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0 40px;
      }
      .textWrap {
        width: calc(45% - 20px);
      }
      .img {
        width: calc(55% - 20px);
      }
      .btnWrap {
        margin: 30px 0 0;
        justify-content: flex-start;
      }
    }
    &.imgLeft {
      .secWrap__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        gap: 0 40px;
      }
      .textWrap {
        width: calc(45% - 20px);
        margin: 0 0 0 auto;
      }
      .img {
        width: calc(55% - 20px);
      }
      .btnWrap {
        margin: 30px 0 0 auto;
        width: calc(45% - 20px);
        justify-content: flex-start;
      }
    }
    &.imgRight--sm {
      .secWrap__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 0 40px;
      }
      .textWrap {
        padding-right: 40%;
      }
      .img {
        width: calc(40% - 40px);
        position: absolute;
        top: .7em;
        right: 0;
      }
      .btnWrap {
        margin: 30px 0 0;
        justify-content: flex-start;
      }
    }
    &.imgLeft--sm {
      .secWrap__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        gap: 0 40px;
      }
      .textWrap {
        margin: 0 0 0 auto;
        padding-left: 40%;
      }
      .img {
        width: calc(40% - 40px);
        position: absolute;
        top: .7em;
        left: 0;
      }
      .btnWrap {
        margin: 30px 0 0 auto;
        width: calc(60% - 20px);
        justify-content: flex-start;
      }
    }
    &.menu { //メニュー
      background: $color-theme;
      padding-top: 64px;
      padding-bottom: 80px;
      .menuList {
        background: #f2faff;
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        &__item {
          width: calc((100% - 120px) / 3);
          margin: 20px;
          position: relative;
          .img {
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 100%;
              background: rgba(0, 84, 142, .7);
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              @include animation-base;
            }
            img {
              width: 100%;
            }
          }
          .text {
            font-size: 2.4rem;
            line-height: 1.41666;
            color: #fff;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 1;
          }
          &:nth-child(n + 3):nth-child(3n - 1) {
            position: relative;
            &::before,&::after {
              content: "";
              width: 100px;
              height: 100px;
              display: inline-block;
              background: url(../img/top_menu_cross.png) no-repeat center center;
              position: absolute;
              top: -65px;
            }
            &::before {
              left: -70px;
            }
            &::after {
              right: -70px;
            }
          }
          @include mq-desktop {
            &:hover {
              .img {
                &::after {
                  width: 80%;
                  height: 80%;
                  background: rgba(0, 84, 142, .3);
                }
              }
            }
          }
        }
      }
      & + .attorneysFee {
        margin-top: -90px;
      }
    }
    &.komon {
      background: transparent;
      margin-bottom: 0;
      .secWrap__inner {
        max-width: $base-width;
      }
    }
    &.bnrArea {
      background: #f9f9f9;
      padding-top: 90px;
      padding-bottom: 75px;
      .bnrList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;
        &__item {
          width: calc((100% - 30px) / 2);
          margin-bottom: 30px;
          @include mq-desktop {
            a:hover {
              opacity: .8;
            }
          }
        }
      }
    }
    &.introduction {
      .introduction__text {
        padding-left: 30%;
        position: relative;
        z-index: 1;
        &::after {
          content: "";
          width: clamp(199px, 28%, 399px);
          height: clamp(209px, 73%, 409px);
          display: inline-block;
          background: url(../img/introduction_main.png) no-repeat center bottom;
          background-size: contain;
          position: absolute;
          left: 0;
          bottom: -5px;
          z-index: 0;
        }
        p {
          & + p {
            margin-top: 20px;
          }
        }
      }
      .introduction__video {
        margin-top: 80px;
        &-ttl {
          font-size: 2.4rem;
          font-weight: 500;
          border-left: 5px solid $color-theme;
          padding-left: 20px;
          margin-bottom: 40px;
        }
        .videoWrap {
          width: 100%;
          max-width: 980px;
          margin: 40px auto 0;
          aspect-ratio: 16 / 9;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
        p {
          & + p {
            margin-top: 20px;
          }
        }
      }
      .btnWrap {
        justify-content: flex-start;
        margin-top: 50px;
      }
      & + .bnrArea {
        margin-top: -75px;
      }
    }
    &.trafficAccidentFlow {
      background: $color-blue;
      padding-top: 80px;
      padding-bottom: 80px;
      margin-bottom: 0;
      .processFlow {
        counter-reset: processFlowNum;
        &__item {
          width: 100%;
          background: #fff;
          position: relative;
          padding: 40px 30px 50px;
          margin-top: 80px;
          min-height: 350px;
          box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.08);
          & + .processFlow__item {
            margin-top: 150px;
          }
          &:not(:last-child) {
            &::after {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-right: 45px solid transparent;
              border-left: 45px solid transparent;
              border-top: 50px solid $color-theme;
              border-bottom: 0;
              display: inline-block;
              position: absolute;
              bottom: -100px;
              left: 155px;
              transform: translateX(-50%);
            }
            &::before {
              content: "";
              width: 40px;
              height: 100%;
              display: inline-block;
              background: $color-theme;
              position: absolute;
              bottom: -50px;
              left: 135px;
              z-index: 0;
            }
          }
        }
        &__ttl {
          font-size: 3rem;
          font-weight: 700;
          color: $color-theme;
          padding-left: 280px;
          margin-bottom: 25px;
          &::before {
            counter-increment: processFlowNum;
            content: "0"counter(processFlowNum);
            font-size: 3rem;
            font-weight: 500;
            line-height: 50px;
            color: #fff;
            width: 50px;
            height: 50px;
            display: inline-block;
            text-align: center;
            background: $color-theme;
            border-radius: 50%;
            margin-right: 15px;
          }
        }
        &__text {
          padding-left: 280px;
          > p:not([class]) {
            font-size: 1.8rem;
            & + p {
              margin-top: 1em;
            }
          }
        }
        &__img {
          position: absolute;
          top: 30px;
          left: 30px;
          img {
            width: 250px;
            height: 250px;
            display: inline-block;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
  .attorneysFee { //弁護士費用
    padding-top: 70px;
    padding-bottom: 75px;
    background: url(../img/top_attorneysFeeBg.jpg) no-repeat center center;
    background-size: cover;
    &__box {
      background: #fff;
      box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    }
    &__item {
      padding: 50px 40px 45px 50px;
      display: flex;
      &:nth-child(2n) {
        background: #f9f9f9; /* Old browsers */
        background: -moz-linear-gradient(top,  #f9f9f9 0%, #ffffff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #f9f9f9 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #f9f9f9 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }
      .bnrWrap {
        margin-top: 20px;
        a {
          @include mq-desktop {
            &:hover {
              opacity: .8;
            }
          }
        }
      }
      .imgWrap {
        margin-right: 40px;
        width: 50%;
      }
      .textWrap {
        max-width: 545px;
        width: 50%;
      }
      .tableWrap {
        margin-left: 55px;
        .priceTable {
          tbody {
            th {
              color: $color-theme;
              background: transparent;
            }
          }
        }
        .btnWrap {
          justify-content: flex-start;
          .btn--border {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .caseStudy {
    &List {
      border-left: 1px solid #d6d6d6;
      border-top: 1px solid #d6d6d6;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 70px;
      &__item {
        width: calc(100% / 6);
        text-align: center;
        display: inline-block;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        a {
          font-size: 1.8rem;
          color: $font-color-base;
          text-decoration: none;
          display: block;
          padding: 20px;
          @include mq-desktop {
            &:hover {
              color: $color-theme;
              background: $color-blue;
              .img {
                transform: scale(.9);
              }
            }
          }
        }
        .img {
          display: block;
          margin: 0 auto 10px;
          @include animation-base;
        }
      }
    }
  }
  .marketPrice {
    &Wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      .textWrap {
        width: 35%;
        margin-right: 3%;
      }
    }
    &__box {
      width: 62%;
    }
    &__ttl {
      font-size: 2.2rem;
      color: #fff;
      letter-spacing: .2em;
      padding: 18px 15px;
      text-align: center;
      background: $color-theme;
      &::before {
        content: "";
        width: 28px;
        height: 28px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
        background: url(../img/icon/icon_legal.png) no-repeat center center;
        background-size: contain;
      }
    }
    &__cont {
      background: $color-blue;
      padding: 15px 3%;
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      &__item {
        margin: 10px 0;
        width: calc(100% / 3);
        a {
          color: $font-color-base;
          text-decoration: none;
          padding-left: 24px;
          display: block;
          position: relative;
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
              color: $color-theme;
            }
          }
          &::before {
            content: "";
            width: 19px;
            height: 19px;
            display: inline-block;
            background: url(../img/link_arrow.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 0;
          }
        }
      }
    }
  }
  .faq {
    padding-top: 0;
    .secWrap__inner {
      border-top: 1px solid #d6d6d6;
      padding-top: 70px;
    }
    &Wrap {
      margin-top: 70px;
    }
    &List {
      border: 1px solid #aacce6;
      .q {
        font-size: 2.4rem;
        font-weight: 500;
        color: $color-theme;
        background: $color-blue;
        padding: 20px 80px 24px 105px;
        position: relative;
        &::before {
          @include font-roboto;
          content: "Q";
          font-size: 2.6rem;
          font-weight: 500;
          color: $color-theme;
          background: #d0ecff;
          border-radius: 50px;
          display: inline-block;
          width: 50px;
          line-height: 50px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
        }
        &::after {
          content: "";
          width: 40px;
          height: 40px;
          display: inline-block;
          background: url(../img/qa_arrow.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
          @include animation-base;
        }
        &.open {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .a {
        padding: 25px 40px;
        background: #fff;
      }
      & + .faqList {
        margin-top: 20px;
      }
    }
    .btnWrap {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 980px) {
  /*=================================
    topPage
  =================================*/
  .home,.page-template-page-areaLp {
    .merit {
      padding-top: 30px!important;
      padding-bottom: 45px!important;
      > {
        .ttl--base {
          margin-bottom: 20px!important;
        }
      }
      .deco--merit {
        &::before {
          font-size: 3rem;
          max-width: 150px;
          margin: 0 auto 10px;
          padding: 5px 5px;
        }
        &:not(.left) {
          &::before {
            margin: 0 auto 10px;
          }
        }
      }
    }
    .secWrap {
      margin: 30px 0 45px;
      padding: 0 3%;
      .btnWrap {
        margin-bottom: 0;
      }
      .ttl--base {
        font-size: 2.2rem;
        margin-bottom: 25px;
        text-align: center;
        .st {
          font-size: 2.2rem;
        }
        .lg {
          font-size: 3.5rem;
          line-height: 1;
        }
        &.colorText {
          margin-bottom: 25px;
        }
        &.whiplash {
          padding-right: 0;
          &::after {
            width: 72px;
            height: 72px;
            display: block;
            margin: 12px auto 0;
            background: url(../img/top_img02_deco_sp.png) no-repeat center center;
            background-size: contain;
            position: static;
          }
        }
      }
      &__text {
        font-size: 1.5rem;
        em {
          font-size: 1.5rem;
        }
      }
      &.imgRight {
        .secWrap__inner {
          display: block;
        }
        .textWrap {
          width: 96%;
          margin: 0 auto 30px;
        }
        .img {
          width: 100%;
          margin: 0 auto;
          display: block;
          text-align: center;
        }
        .btnWrap {
          width: 100%;
          float: none;
          margin: 15px auto 0;
          text-align: center;
          justify-content: center;
        }
      }
      &.imgLeft {
        .secWrap__inner {
          display: block;
        }
        .textWrap {
          width: 96%;
          margin: 0 auto 30px;
        }
        .img {
          width: 100%;
          margin: 0 auto;
          display: block;
          text-align: center;
        }
        .btnWrap {
          width: 100%;
          float: none;
          margin: 15px auto 0;
          text-align: center;
          justify-content: center;
        }
      }
      &.imgRight--sm {
        .secWrap__inner {
          display: block;
        }
        .textWrap {
          width: 100%;
          padding-right: 0;
        }
        .img {
          width: 100%;
          margin: 0 0 20px;
          position: static;
        }
        .btnWrap {
          margin: 20px auto 0;
          justify-content: center;
          text-align: center;
        }
      }
      &.imgLeft--sm {
        .secWrap__inner {
          display: block;
        }
        .textWrap {
          width: 100%;
          margin: initial;
          padding-left: 0;
        }
        .img {
          width: 100%;
          margin: 0 0 20px;
          position: static;
        }
        .btnWrap {
          margin: 20px auto 0;
          width: initial;
          justify-content: center;
          text-align: center;
        }
      }
      &.menu { //メニュー
        padding: 30px 0;
        .menuList {
          margin-top: 15px;
          padding: 8px;
          &__item {
            width: calc((100% - 32px) / 2);
            margin: 8px;
            .text {
              font-size: 1.5rem;
              line-height: 1.3333;
            }
            &:nth-child(n + 3):nth-child(3n - 1) {
              &::before,&::after {
                content: none;
              }
            }
          }
        }
        & + .attorneysFee {
          margin-top: -45px;
        }
      }
      &.komon {
        padding-bottom: 0!important;
      }
      &.bnrArea {
        padding: 30px 3% 45px;
        .bnrList {
          display: block;
          margin: 40px auto 0;
          &__item {
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }
      &.introduction {
        overflow: hidden;
        .introduction__text {
          padding: 0 0 calc(18% + 45px);
          border-bottom: 1px solid #d6d6d6;
          &::after {
            width: clamp(200px, 26%, 409px);
            height: clamp(209px, 35%, 409px);
            left: initial;
            right: -35px;
            bottom: 0;
          }
          p {
            & + p {
              margin-top: 2rem;
            }
          }
        }
        .introduction__video {
          margin-top: 40px;
          &-ttl {
            font-size: 1.8rem;
            padding-left: 15px;
            margin-bottom: 30px;
          }
          .videoWrap {
            margin: 30px auto 0;
          }
          p {
            & + p {
              margin-top: 2rem;
            }
          }
        }
        .btnWrap {
          margin-top: 35px;
        }
        & + .bnrArea {
          margin-top: -45px;
        }
      }
      &.trafficAccidentFlow {
        padding-top: 50px;
        padding-bottom: 50px;
        .processFlow {
          &__item {
            padding: 30px 5%;
            margin-top: 40px;
            min-height: initial;
            & + .processFlow__item {
              margin-top: 100px;
            }
            &:not(:last-child) {
              &::after {
                border-right: 30px solid transparent;
                border-left: 30px solid transparent;
                border-top: 40px solid $color-theme;
                bottom: -75px;
                left: 50%;
                transform: translateX(-50%);
              }
              &::before {
                content: none;
              }
            }
          }
          &__ttl {
            font-size: 2.2rem;
            padding-left: 50px;
            margin-bottom: 25px;
            position: relative;
            &::before {
              font-size: 2rem;
              line-height: 40px;
              width: 40px;
              height: 40px;
              position: absolute;
              top: -5px;
              left: 0;
            }
          }
          &__text {
            padding-left: 0px;
            > p:not([class]) {
              font-size: 1.5rem;
            }
          }
          &__img {
            position: static;
            img {
              width: 80%;
              height: initial;
              display: block;
              border-radius: 0;
              margin: 0 auto 30px;
            }
          }
        }
      }
    }
    .attorneysFee { //弁護士費用
      padding: 22px 2.5% 20px;
      background: #f2f2f2 url(../img/top_attorneysFeeBg.jpg) no-repeat center top;
      background-size: inherit;
      &__item {
        padding: 25px 4%;
        display: block;
        .bnrWrap {
          margin: 15px 0 20px;
        }
        .textWrap {
          max-width: 100%;
          width: 100%;
        }
        .imgWrap {
          margin: 0 auto 10px;
          display: block;
          text-align: center;
          width: 100%;
        }
        .tableWrap {
          margin: 22px 0 14px;
          .priceTable {
            margin: 20px auto 0;
            display: table;
            white-space: unset;
          }
          .btnWrap {
            .btn--border {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .caseStudy {
      .textWrap {
        padding: 0 2%;
      }
      &List {
        border-left: none;
        margin-top: 30px;
        &__item {
          width: calc(100% / 3);
          a {
            font-size: 1.4rem;
            padding: 15px;
          }
          &:nth-child(3n) {
            border-right: none;
          }
        }
      }
    }
    .marketPrice {
      padding-left: 3%;
      padding-right: 3%;
      &Wrap {
        display: block;
        margin-top: 45px;
        .textWrap {
          width: 100%;
          margin: 0 0 30px;
        }
      }
      &__box {
        width: 100%;
      }
      &__ttl {
        font-size: 1.6rem;
        padding: 12px;
        &::before {
          width: 20px;
          height: 20px;
        }
      }
      &__cont {
        padding: 15px 3%;
      }
      &List {
        justify-content: space-between;
        &__item {
          min-width: inherit;
          width: 48%;
          margin: 10px 0;
          a {
            font-size: 1.3rem;
            padding-left: 17px;
            display: block;
            position: relative;
            &::before {
              width: 13px;
              height: 13px;
              margin-right: 0;
              position: absolute;
              top: 3px;
              left: 0;
            }
          }
        }
      }
    }
    .faq {
      padding-top: 0;
      padding-bottom: 15px;
      .secWrap__inner {
        padding: 30px 3% 0;
      }
      &Wrap {
        margin-top: 30px;
      }
      &List {
        border: 2px solid #aacce6;
        .q {
          font-size: 1.5rem;
          padding: 11px 45px;
          &::before {
            font-size: 1.6rem;
            width: 33px;
            line-height: 33px;
            left: 7px;
          }
          &::after {
            width: 30px;
            height: 30px;
            right: 7px;
          }
        }
        .a {
          padding: 25px 40px;
          background: #fff;
        }
        & + .faqList {
          margin-top: 10px;
        }
      }
      .btnWrap {
        text-align: center;
        margin-top: 14px;
      }
    }
  }
}