@charset "utf-8";

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 50px 0 30px;
  width: 100%;
  box-shadow: 0px 0px 21.25px 3.75px rgba(11, 3, 6, 0.05);
  background: #fff;
  caption {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  thead {
    th {
      font-size: 1.8rem;
      font-weight: 500;
      color: #fff;
      text-align: center;
      background: $color-theme;
      &:not(:last-child) {
        border-right: 1px solid #89cbf8;
      }
    }
  }
  th {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    background: #f1f9fe;
    padding: 12px 15px;
  }
  td {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.25;
    padding: 12px 15px;
  }
  tbody {
    th,td {
      &:not(:last-child) {
        border-right: 1px solid #d6d6d6;
      }
    }
    tr {
      &:not(:first-child) {
        border-top: 1px solid #d6d6d6;
      }
      &:not(:last-child) {
        th {
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 1px;
            display: block;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    ::-webkit-scrollbar-thumb {
      background: #BCBCBC;
    }
  }
}
.scrollAnnounce {
  display: none;
}
@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
    caption {
      font-size: 1.5rem;
      margin: 15px 0;
      width: 94vw;
    }
    th {
      font-size: 1.3rem;
      padding: 10px;
    }
    td {
      font-size: 1.3rem;
      padding: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: none;
      background: #d6d6d6;
    }
    /* スクロールのつまみ部分の設定 */
    &::-webkit-scrollbar-thumb {
      background: #f4b038;
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    .text {
      font-size: 1.3rem;
      font-weight: 600;
      color: #fff;
      background: #f4b038;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: #f4b038 transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}