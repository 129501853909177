@charset "utf-8";

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.52941;
  color: #000;
  margin-bottom: 70px;
  .st {
    font-style: normal;
    display: inline;
    color: $color-theme;
    background: linear-gradient(transparent 60%, #fff697 0%);
  }
  &:not(.left) {
    text-align: center;
  }
  &.white {
    color: #fff;
    .line {
      display: inline-block;
      border-bottom: 3px solid #ffea00;
    }
  }
  .yellow {
    color: #ffea00;
  }
}
.ttl--page {
  font-size: 3.6rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  background: $color-theme url(../img/deco_repeat.png) repeat-x left bottom 15px;
  background-size: auto 155px;
  padding: 79px 1%;
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    margin-bottom: 30px;
    .st {
      font-size: 2rem;
      background: linear-gradient(transparent 60%, #fff697 0%);
    }
    &.left {
      text-align: center;
    }
  }
  .ttl--page {
    font-size: 2.1rem;
    background: $color-theme url(../img/deco_repeat.png) repeat-x left bottom 6px;
    background-size: auto 100px;
    padding: 38px 3%;
  }
}