@charset "utf-8";
/*=================================
  isharyo
=================================*/
.isharyoWrap {
  .isharyoTtl--lg {
    font-size: 3.4rem;
    font-weight: 400;
    color: #fff;
    line-height: 1.23529;
    padding: 38px 86px;
    width: calc(100% + 160px);
    background: #4aa6e5;
    border-top: 8px solid #0a73bb;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    margin-top: 78px;
    margin-bottom: 60px;
    margin-left: -80px;
  }
  .isharyoTtl--md {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.41666;
    background: $color-blue;
    border-top: 7px solid $color-theme;
    padding: 25px 30px;
    margin-top: 60px;
    margin-bottom: 50px;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  }
  .isharyoContent {
    border: 50px solid $color-blue;
    padding: 50px 3%;
  }
  .explanatoryText {
    margin: 0 0 50px;
    .red {
      font-weight: 600;
      color: $color-red;
    }
  }
  .required {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    width: fit-content;
    background: $color-red;
    border-radius: 3px;
    padding: 5px 8px;
    position: absolute;
    top: 5px;
    right: 0;
  }
  .requiredError {
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-red;
    margin-top: 5px;
  }
  .calcItem {
    display: flex;
    margin: 50px 0;
    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      width: 250px;
      margin-right: 30px;
      padding-right: 48px;
      position: relative;
      .sm {
        font-weight: 500;
        display: block;
      }
    }
    &__content {
      width: calc(100% - 280px);
      .notes {
        font-size: 1.4rem;
        margin-top: 5px;
      }
    }
  }
  input[type="text"] {
    border: 1px solid #828282;
    border-radius: 3px;
    padding: 5px 10px;
    margin-right: 5px;
  }
  .radioBtn {
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
    &__item {
      padding-left: 30px;
      position: relative;
      &::before {
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 50px;
        display: block;
        border: 1px solid #828282;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after {
        content: "";
        width: 14px;
        height: 14px;
        background: $color-theme;
        border-radius: 50px;
        display: block;
        opacity: 0;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
    input[type="radio"] {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      margin: -1px;
      &:checked + .radioBtn__item::after {
        opacity: 1;
      }
    }
  }
  select {
    border: 1px solid #828282;
    border-radius: 3px;
    padding: 5px 10px;
    margin-right: 5px;
    background: #fff;
  }
  .resultBtn {
    font-size: 2.6rem;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    display: block;
    width: fit-content;
    text-align: center;
    background: #f4b038;
    box-shadow: 0 5px 0 #e49102;
    border-radius: 4px;
    padding: 20px 80px;
    transition: none;
    margin: 80px auto 0;
    cursor: pointer;
    @include mq-desktop {
      &:hover {
        transform: translateY(5px);
        box-shadow: none;
      }
    }
  }
  .isharyoResult {
    visibility: hidden;
    opacity: 0;
    height: 0;
    &.show {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
    .resultNotes {
      font-size: 1.5rem;
      text-align: center;
      margin: 20px 0;
    }
    .resultTotal {
      background: #fffbcd;
      max-width: 700px;
      margin: 0 auto;
      padding: 10px 60px;
      .resultRow {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 20px 0;
        &__title {
          font-size: 2.8rem;
          font-weight: 500;
        }
        &__value {
          font-size: 2.8rem;
          font-weight: 500;
          line-height: 1.8;
          margin-left: 20px;
          .num {
            font-size: 5.5rem;
            font-weight: 700;
            line-height: 1;
            color: $color-theme;
            display: inline-block;
            margin-right: 10px;
          }
          .text {
            font-size: 1.6rem;
            font-weight: 400;
          }
        }
      }
    }
    .resultDetail {
      .resultRow {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 700px;
        margin: 0 auto;
        &__title {
          font-size: 2rem;
          font-weight: 500;
        }
        &__value {
          font-size: 2rem;
          font-weight: 500;
          .num {
            font-size: 4rem;
            line-height: 1;
            color: $color-theme;
            display: inline-block;
            margin: -5px 5px 0;
          }
          .text {
            font-size: 1.6rem;
            font-weight: 400;
          }
        }
        & + .resultRow {
          margin-top: 20px;
        }
      }
    }
  }
  .isharyoCvWrap {
    padding: 30px 30px 75px;
    margin-top: 80px;
    background: $color-blue url(../img/deco_repeat.png) repeat-x center bottom -5px;
    background-size: 60% auto;
    border: 30px solid #3dabf7;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    .cvBnr {
      margin: 0 auto;
      &::after {
        width: 200px;
        height: 216px;
      }
      .contact {
        padding-left: 30px;
        &__wrap {
          margin-right: 20px;
        }
        &__ttl {
          width: 80px;
          font-size: 1.5rem;
          .num {
            font-size: 2rem;
          }
        }
        .tel {
          .contact {
            &__ttl {
              min-height: 80px;
            }
          }
          .telNum {
            font-size: 3.8rem;
          }
        }
        .reservation {
          .contact {
            &__ttl {
              min-height: 80px;
            }
          }
        }
      }
    }
    .isharyoCvText {
      padding: 30px 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 0;
      &__item {
        padding-left: 1em;
        text-indent: -1em;
        &::before {
          content: "・";
          margin-right: .5em;
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    isharyo
  =================================*/
  .isharyoWrap {
    .isharyoTtl--lg {
      font-size: 2rem;
      line-height: 1.3;
      padding: 15px;
      width: calc(100% + 8%);
      border-top: 5px solid #0a73bb;
      margin-top: 40px;
      margin-bottom: 30px;
      margin-left: -4%;
    }
    .isharyoTtl--md {
      font-size: 1.6rem;
      line-height: 1.3125;
      border-top: 4px solid $color-theme;
      padding: 15px;
      margin-top: 35px;
      margin-bottom: 33px;
    }
    .isharyoContent {
      border: 15px solid $color-blue;
      padding: 30px 0;
    }
    .explanatoryText {
      margin: 0 0 40px;
      padding: 0 5%;
    }
    .required {
      font-size: 1.2rem;
      padding: 5px 6px;
      position: static;
      margin: 0 10px 0 0;
      display: inline-block;
    }
    .requiredError {
      font-size: 1.3rem;
    }
    .calcItem {
      display: block;
      margin: 0;
      &__title {
        font-size: 1.5rem;
        width: 100%;
        margin: 0;
        padding: 10px 10px;
        background: #f3f3f3;
        .sm {
          font-size: 1.4rem;
          display: inline-block;
        }
      }
      &__content {
        width: 100%;
        padding: 20px 10px;
        font-weight: 600;
        .notes {
          font-weight: 500;
        }
      }
    }
    input[type="text"] {
      font-size: 1.6rem;
      padding: 5px 10px;
      margin-right: 5px;
      text-align: center;
      max-width: 70%;
    }
    .radioBtn {
      margin-right: 10px;
      margin-bottom: 10px;
      &__item {
        padding-left: 25px;
        &::before {
          width: 20px;
          height: 20px;
        }
        &::after {
          width: 10px;
          height: 10px;
        }
      }
    }
    .resultBtn {
      font-size: 2.2rem;
      box-shadow: 0 3px 0 #e49102;
      padding: 15px 10%;
      margin: 15px auto 0;
    }
    .isharyoResult {
      .resultTotal {
        padding: 5px 5% 30px;
        .resultRow {
          text-align: center;
          display: block;
          margin: 30px 0 0;
          &__title {
            font-size: 2.4rem;
            line-height: 1;
          }
          &__value {
            font-size: 2.4rem;
            line-height: 1;
            margin: 5px 0 0;
            .num {
              font-size: 4.5rem;
            }
            .text {
              font-size: 1.5rem;
            }
          }
        }
      }
      .resultDetail {
        .resultRow {
          &__title {
            font-size: 1.7rem;
            min-width: 125px;
          }
          &__value {
            font-size: 1.7rem;
            .num {
              font-size: 3.5rem;
              margin: 0 5px 0;
            }
            .text {
              font-size: 1.5rem;
            }
          }
          & + .resultRow {
            margin-top: 20px;
          }
        }
      }
    }
    .isharyoCvWrap {
      padding: 0 0 45px;
      margin-top: 50px;
      background: $color-blue url(../img/deco_repeat.png) repeat-x center bottom -5px;
      background-size: 100% auto;
      border: 12px solid #3dabf7;
      .cvBnr {
        box-shadow: none;
        .contact {
          padding-left: 0;
          &__wrap {
            margin-right: 0;
          }
          &__ttl {
            width: initial;
            font-size: 1.4rem;
            .num {
              font-size: 1.4rem;
            }
          }
          .tel {
            .contact {
              &__ttl {
                min-height: initial;
              }
            }
            .telNum {
              font-size: 1.8rem;
            }
          }
          .reservation {
            .contact {
              &__ttl {
                min-height: initial;
              }
            }
          }
        }
      }
      .isharyoCvText {
        padding: 15px 5%;
      }
    }
  }
}