@charset "utf-8";
/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom 30px;
  padding: 55px 1% 120px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
}
.cvBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: relative;
  &__ttl {
    font-size: 2.6rem;
    font-weight: 600;
    background: #fff697;
    padding: 6px 42px;
    .text--red {
      color: #d10717;
    }
    .summary {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.6666;
    }
  }
  .contact {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 25px 65px;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      width: 234px;
      height: 253px;
      display: inline-block;
      background: url(../img/cvBnr_img.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    &__wrap {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-right: 60px;
      position: relative;
      z-index: 2;
      & + .contact__wrap {
        margin-right: 0;
      }
    }
    &__ttl {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.125;
      color: #3f3f3f;
      border-radius: 50px;
      width: 100px;
      text-align: center;
      .num {
        @include font-roboto;
        font-size: 2.6rem;
        font-weight: 600;
      }
    }
    &__cont {
      display: inline-block;
    }
    .tel {
      .contact__ttl {
        background: #e4f4ff;
        padding: 19px 0 17px;
        margin-right: 20px;
        min-height: 100px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .national {
          font-size: 1.3rem;
          font-weight: 700;
          color: $color-pink;
          margin: 5px 0 0;
          display: block;
        }
      }
      .telNum {
        @include font-roboto;
        font-size: 4.8rem;
        font-weight: 500;
        line-height: 1;
        color: $color-theme;
        text-decoration: none;
        display: block;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          display: inline-block;
          background: url(../img/icon/icon_phone.png) no-repeat center center;
          background-size: contain;
          margin-right: 2px;
        }
      }
      .telText {
        font-size: 1.6rem;
        line-height: 1;
        color: $font-color-base;
        display: block;
        width: 100%;
        margin-left: 30px;
      }
    }
    .reservation {
      .contact__ttl {
        background: #faeacf;
        padding: 14px 0;
        margin-right: 20px;
      }
      .btn {
        &--mail {
          font-size: 2.2rem;
          font-weight: 600;
          color: #fff;
          background: #f4b038;
          box-shadow: 0 5px 0 #e49102;
          border-radius: 5px;
          text-decoration: none;
          padding: 14px 20px;
          display: inline-block;
          vertical-align: middle;
          transition: none;
          &::before {
            content: "";
            width: 25px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/icon_mail.png) no-repeat center center;
            background-size: contain;
            margin-right: 6px;
            vertical-align: middle;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
        }
        &--line {
          font-size: 2.2rem;
          font-weight: 600;
          color: #fff;
          background: #19d24e;
          box-shadow: 0 5px 0 #15b944;
          border-radius: 5px;
          text-decoration: none;
          padding: 14px 20px;
          display: inline-block;
          vertical-align: middle;
          transition: none;
          &::before {
            content: "";
            width: 29px;
            height: 28px;
            display: inline-block;
            background: url(../img/icon/icon_line.png) no-repeat center center;
            background-size: contain;
            margin-right: 6px;
            vertical-align: middle;
          }
          @include mq-desktop {
            &:hover {
              box-shadow: none;
              transform: translateY(5px);
            }
          }
        }
      }
      a + a {
        margin-left: 8px;
      }
    }
  }
  .office {
    background: #fffac9;
    display: flex;
    justify-content: center;
    gap: 80px;
    padding: 20px 42px;
    border-top: 1px solid #eee8b2;
    &__item {
      text-align: center;
      &--ttl {
        font-weight: 600;
        margin-bottom: 5px;
      }
      &--btn {
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        background: $color-blue-dark;
        box-shadow: 0 3px 0 $color-theme;
        border-radius: 5px;
        padding: 5px 10px;
        display: inline-block;
        @include animation-base(all,0s);
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $display-width-s) and (max-width: 900px) {
  .cvBnr {
    .office {
      justify-content: flex-start;
      gap: 50px;
      padding: 15px 0 20px 65px;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    background: $color-theme url(../img/deco_repeat.png) repeat-x center bottom 15px;
    background-size: auto 80px;
    padding: 25px 3% 20px;
  }
  .cvBnr {
    &__ttl {
      font-size: 1.6rem;
      padding: 8px 70px 8px 12px;
      position: relative;
      .summary {
        font-size: 1.3rem;
        line-height: 1.41666;
        margin-top: 5px;
      }
      &::after {
        content: "";
        width: 86px;
        height: 76px;
        display: inline-block;
        background: url(../img/cvBnr_img.png) no-repeat center top;
        background-size: 86px auto;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .contact {
      padding: 0;
      &::after {
        content: none;
      }
      &__wrap {
        width: 50%;
        display: block;
        margin: 0;
      }
      &__ttl {
        font-size: 1.4rem;
        border-radius: 0;
        width: 100%;
        .num {
          font-size: 1.4rem;
        }
      }
      &__cont {
        display: block;
      }
      .tel {
        background: #e4f4ff;
        padding: 8px 5px;
        .contact__ttl {
          font-size: 1.4rem;
          font-weight: 600;
          color: $color-theme;
          padding: 0;
          margin: 0 0 8px;
          min-height: initial;
          .national {
            font-size: clamp(1rem,1vw,1.2rem);
            margin: 0;
          }
        }
        .contact__cont {
          background: #47adf3;
          box-shadow: 0 3px 0 $color-theme;
          border-radius: 5px;
          text-align: center;
          padding: 15px 6px;
        }
        .telNum {
          font-size: 2rem;
          line-height: 1;
          color: #fff;
          display: block;
          &::before {
            width: 14px;
            height: 14px;
            background: url(../img/icon/icon_phone_w.png) no-repeat center center;
            background-size: contain;
          }
        }
        .telText {
          font-size: .8rem;
          color: #fff;
          margin: 5px 0 0;
        }
      }
      .reservation {
        background: #faeacf;
        padding: 8px 5px;
        .contact__ttl {
          font-size: 1.4rem;
          font-weight: 600;
          color: #f29a00;
          padding: 0;
          margin: 0 0 8px
        }
        .contact__cont {
          display: flex;
        }
        .btn {
          &--mail {
            font-size: 1.4rem;
            box-shadow: 0 3px 0 #e49102;
            padding: 7px 6px;
            width: 50%;
            text-align: center;
            span {
              line-height: 1;
            }
            &::before {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto 6px;
            }
          }
          &--line {
            font-size: 1.4rem;
            box-shadow: 0 3px 0 #15b944;
            padding: 7px 6px;
            width: 50%;
            text-align: center;
            &::before {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto 6px;
            }
          }
        }
        a + a {
          margin-left: 7px;
        }
      }
    }
    .office {
      gap: 0;
      padding: 8px 5px 12px;
      &__item {
        width: 50%;
        &--ttl {
          font-size: 1.4rem;
          margin-bottom: 3px;
        }
      }
    }
  }
}