@charset "utf-8";

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: $color-theme;
  padding: 15px;
}
.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: $color-blue-dark;
  box-shadow: 0 3px 0 $color-theme;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}
.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: $color-theme;
  border: 3px solid $color-theme;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(../img/btn_arrow.png) no-repeat center center;
    background-size: contain;
    vertical-align: middle;
  }
}

@media screen and (min-width: $display-width-s){
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 0 0 20px;
    }
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
    &:hover {
      opacity: .9;
    }
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 $color-theme;
    padding: 24px 30px;
    transition: none;
    &:hover {
      transform: translateY(5px);
      box-shadow: none;
    }
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
    &::before {
      width: 24px;
      height: 24px;
      margin: 0 6px 3px 0;
    }
    &:hover {
      background: $color-blue;
    }
  }
}