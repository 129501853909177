@charset "utf-8";
/*=================================
  flow
=================================*/
.flow {
  padding: 75px 1% 80px;
  background: url(../img/flow_bg.jpg) no-repeat center top;
  background-size: cover;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &Item {
    padding: 40px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .step {
      min-width: 380px;
      margin-right: 40px;
      position: relative;
      .label {
        @include font-roboto;
        font-size: 2.2rem;
        color: #fff;
        text-align: center;
        background: url(../img/step_bg.png) no-repeat center center;
        background-size: contain;
        width: 120px;
        line-height: 120px;
        position: absolute;
        top: -30px;
        left: -30px;
      }
    }
    .flowCont {
      width: calc(100% - 420px);
      min-width: 430px;
      &__ttl {
        font-size: 2.8rem;
        font-weight: 500;
        color: $color-theme;
        margin: 15px 0;
      }
      &__text {
        font-size: 1.8rem;
        line-height: 1.8888;
        em {
          background: none;
        }
      }
    }
    &:not(:last-child) {
      .step {
        .label::after {
          content: "";
          width: 39px;
          height: 28px;
          display: inline-block;
          background: url(../img/step_arrow.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -36px;
          margin: 0 auto;
        }
      }
    }
    & + .flowItem {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    flow
  =================================*/
  .flow {
    padding: 35px 3% 30px;
    background: #f1f1f1 url(../img/flow_bg.jpg) no-repeat center top;
    background-size: inherit;
    &Item {
      padding: 20px;
      display: block;
      .step {
        min-width: inherit;
        margin: 0 0 13px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .label {
          font-size: 1.4rem;
          width: 80px;
          line-height: 80px;
          display: inline-block;
          position: relative;
          top: 0;
          left: 0;
        }
        .img {
          width: calc(100% - 105px);
        }
      }
      .flowCont {
        width: 100%;
        min-width: inherit;
        &__ttl {
          font-size: 1.6rem;
          margin: 0 0 12px;
        }
        &__text {
          font-size: 1.4rem;
        }
      }
      &:not(:last-child) {
        .step {
          .label::after {
            width: 25px;
            height: 18px;
            bottom: -23px;
          }
        }
      }
      & + .flowItem {
        margin-top: 15px;
      }
    }
  }
}