@charset "utf-8";
/*=================================
footer
=================================*/
.footer {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 80px 1% 70px;
  background: $font-color-base;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .infoWrap {
    width: 40%;
    min-width: 415px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 80px;
    .logo {
      margin-right: 20px;
    }
    .snsList {
      &__item {
        display: inline-block;
        margin: 10px 5px 0;
        .icon {
          font-size: 2rem;
          color: #00000e;
          line-height: 38px;
          width: 38px;
          display: block;
          text-align: center;
          background: #484848;
          border-radius: 50px;
          cursor: pointer;
          @include animation-base(transform,.5s);
          @include mq-desktop {
            &:hover {
              transform: rotateY(-360deg);
            }
          }
        }
      }
    }
    .addWrap {
      color: #dfdfdf;
      margin-top: 15px;
      .tel {
        &__label {
          line-height: 1;
          display: inline-block;
          background: #484848;
          margin-right: 3px;
          padding: 5px 7px;
        }
        &__num {
          display: inline-block;
          a {
            color: #dfdfdf;
            text-decoration: none;
          }
        }
      }
    }
  }
  .fNavList {
    background: #484848;
    padding: 28px 16px;
    width: 60%;
    &__item {
      display: inline-block;
      margin: 0 1rem;
      a {
        color: #fff;
        text-decoration: none;
        @include mq-desktop {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .copy {
    font-size: 1.6rem;
    letter-spacing: .06em;
    color: #dfdfdf;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .pageTop {
    position: fixed;
    bottom: 40px;
    right: calc((100% - #{$base-width}) / 2);
    z-index: 98;
    cursor: pointer;
    &::after {
      content: "";
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      display: block;
      border-radius: 50px;
      background: rgba(255, 255, 255, .3);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @include animation-base;
    }
    @include mq-desktop {
      &:hover::after {
        width: calc(100% + 24px);
        height: calc(100% + 24px);
      }
    }
  }
}

.fixBnr {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  position: fixed;
  right: -100%;
  bottom: 120px;
  z-index: 9999;
  transition: right 1.2s ease-out;
  display: none;
  .contact {
    &__wrap {
      &.tel {
        .contact__ttl {
          font-size: 1.9rem;
          font-weight: 500;
          color: $color-theme;
          background: #fdf7b3;
          padding: 9px 70px 9px 18px;
          position: relative;
          &::after {
            content: "";
            width: 80px;
            height: 85px;
            display: inline-block;
            background: url(../img/cvBnr_img.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .national {
            font-size: 1.6rem;
            color: $color-pink;
            line-height: 1;
            display: block;
          }
        }
        .contact__cont {
          padding: 10px 8px;
          .telNum {
            @include font-roboto;
            font-size: 3.9rem;
            font-weight: 500;
            line-height: 1;
            color: $color-theme;
            text-decoration: none;
            display: block;
            &::before {
              content: "";
              width: 24px;
              height: 24px;
              display: inline-block;
              background: url(../img/icon/icon_phone.png) no-repeat center center;
              background-size: contain;
              margin: -8px 2px 0 0;
              vertical-align: middle;
              animation: vibrate02 3s infinite;
              @include animation-base;
            }
            @include mq-desktop {
              &:hover {
                color: #e49102;
              }
            }
          }
          .telText {
            font-size: 1.4rem;
            text-align: center;
          }
        }
      }
      &.reservation {
        background: #fcf3e5; /* Old browsers */
        background: -moz-linear-gradient(top,  #fcf3e5 0%, #ffffff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #fcf3e5 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #fcf3e5 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcf3e5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        padding: 10px;
        .contact__ttl {
          font-size: 2rem;
          font-weight: 500;
          line-height: 1;
          color: #e49102;
          text-align: center;
          margin-bottom: 10px;
        }
        .contact__cont {
          display: flex;
          justify-content: space-between;
          .btn {
            &--mail {
              font-size: 1.8rem;
              font-weight: 600;
              color: #fff;
              width: calc(50% - 3px);
              text-align: center;
              display: block;
              padding: 13px 0;
              border-radius: 5px;
              background: #f4b038;
              box-shadow: 0 5px 0 #e49102;
              text-decoration: none;
              transition: none;
              &::before {
                content: "";
                width: 20px;
                height: 15px;
                display: inline-block;
                background: url(../img/icon/icon_mail.png) no-repeat center center;
                background-size: contain;
                margin-right: 6px;
                vertical-align: middle;
              }
              @include mq-desktop {
                &:hover {
                  box-shadow: none;
                  transform: translateY(5px)
                }
              }
            }
            &--line {
              @include font-roboto;
              font-size: 2.1rem;
              font-weight: 500;
              color: #fff;
              width: calc(50% - 3px);
              text-align: center;
              display: block;
              padding: 10px 0;
              border-radius: 5px;
              background: #19d24e;
              box-shadow: 0 5px 0 #15b944;
              text-decoration: none;
              transition: none;
              &::before {
                content: "";
                width: 24px;
                height: 23px;
                display: inline-block;
                margin-right: 6px;
                background: url(../img/icon/icon_line.png) no-repeat center center;
                background-size: contain;
                vertical-align: middle;
              }
              @include mq-desktop {
                &:hover {
                  box-shadow: none;
                  transform: translateY(5px)
                }
              }
            }
          }
        }
      }
    }
  }
  &.show {
    right: 0;
  }
}
@media screen and (min-width: $display-width-s) and (max-width: 1300px) {
  /*=================================
  footer
  =================================*/
  .footer {
    .pageTop {
      right: 3%;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
  footer
  =================================*/
  .footer {
    font-size: 1.2rem;
    padding: 40px 5% 15px;
    &__inner {
      display: block;
    }
    .infoWrap {
      width: 100%;
      min-width: inherit;
      display: block;
      padding-bottom: 0;
      .logo {
        width: 222px;
        margin: 0 auto;
        display: block;
      }
      .snsList {
        margin-top: 20px;
        text-align: center;
        &__item {
          margin: 0 5px;
        }
      }
      .addWrap {
        margin-top: 25px;
        .tel {
          margin-top: 10px;
          &__label {
            border-radius: 3px;
          }
        }
      }
    }
    .fNavList {
      margin-top: 17px;
      padding: 20px 12px;
      width: 100%;
      &__item {
        margin: 0 .5rem 1rem;
      }
    }
    .copy {
      font-size: 1.1rem;
      position: static;
      display: block;
      margin-top: 25px;
      text-align: center;
    }
    .pageTop {
      width: 46px;
      bottom: 70px;
      right: 3%;
      &::after {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
      }
    }
  }
  .fixBnr {
    box-shadow: none;
    width: 100%;
    right: 0;
    left: 0;
    // bottom: -100%;
    bottom: 0;
    // transition: bottom 1s ease-out;
    display: block;
    .contact {
      display: flex;
      &__wrap {
        &.tel {
          background: #47adf3;
          padding: 6px 44px 6px 3px;
          text-align: center;
          position: relative;
          width: 55%;
          &::after {
            content: "";
            width: 45px;
            height: 100%;
            display: inline-block;
            background: url(../img/cvBnr_img.png) no-repeat center bottom;
            background-size: contain;
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .contact__ttl {
            font-size: 1.2rem;
            color: #fff;
            background: transparent;
            padding: 0;
            &::after {
              content: none;
            }
            .national {
              font-size: 1.2rem;
              display: inline;
            }
          }
          .contact__cont {
            padding: 0;
            .telNum {
              font-size: 2rem;
              color: #fff;
              &::before {
                width: 12px;
                height: 12px;
                background: url(../img/icon/icon_phone_w.png) no-repeat center center;
                background-size: contain;
                margin: -3px 2px 0 0;
              }
            }
            .telText {
              font-size: .7rem;
              color: #fff;
            }
          }
        }
        &.reservation {
          background: transparent;
          padding: 0;
          width: 45%;
          .contact__ttl {
            display: none;
          }
          .contact__cont {
            height: 100%;
            .btn {
              &--mail {
                font-size: 1.1rem;
                width: 50%;
                padding: 12px 0;
                border-radius: 0;
                box-shadow: none;
                display: block;
                height: 100%;
                &::before {
                  width: 18px;
                  height: 20px;
                  margin: 0 auto 5px;
                  display: block;
                }
              }
              &--line {
                font-size: 1.1rem;
                font-weight: 600;
                width: 50%;
                padding: 12px 0;
                border-radius: 0;
                box-shadow: none;
                display: block;
                height: 100%;
                &::before {
                  width: 20px;
                  height: 20px;
                  margin: 0 auto 5px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    // &.show {
    //   bottom: 0;
    //   right: inherit;
    // }
  }
}