@charset "utf-8";
/*=================================
  お問い合わせ
=================================*/
.contactWrap {
  &__in {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 3% 100px;
    background: #fff;
  }
  .telBox {
    padding: 40px 30% 40px 4%;
    max-width: 90%;
    margin: 80px auto 0;
    border-top: 1px solid $color-theme;
    border-bottom: 1px solid $color-theme;
    text-align: center;
    position: relative;
    &::after {
      content: "";
      width: 30%;
      height: 264px;
      display: inline-block;
      background: url(../img/contact_img01.png) no-repeat center bottom;
      background-size: contain;
      position: absolute;
      right: 4%;
      bottom: 0;
    }
    &__ttl {
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1;
      color: $color-theme;
      margin-bottom: 20px;
    }
    &__num {
      @include font-roboto;
      font-size: 5.7rem;
      font-weight: 500;
      line-height: 1;
      color: $color-theme;
      text-decoration: none;
      &::before {
        content: "";
        width: 36px;
        height: 36px;
        display: inline-block;
        background: url(../img/icon/icon_phone.png) no-repeat center center;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    &__text {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1;
      color: $color-theme;
      margin-top: 15px;
    }
  }
  .consulCase {
    margin-top: 75px;
    border: 11px solid #e7e7e7;
    background: #fff url(../img/contact_img02.png) no-repeat right top;
    padding: 40px;
    &__ttl {
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 30px;
    }
    &List {
      &__item {
        font-size: 2rem;
        padding-left: 44px;
        position: relative;
        &::before {
          content: "";
          width: 27px;
          height: 27px;
          display: inline-block;
          background: url(../img/icon/icon_check_g.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 6px;
          left: 0;
        }
        & + .consulCaseList__item {
          margin-top: 20px;
        }
      }
    }
  }
}
.contactForm {
  background: $color-theme;
  padding: 0 1% 115px;
  &__in {
    max-width: 1000px;
    margin: 0 auto;
  }
  &__ttlWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .contactForm__ttl {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 74px;
      position: relative;
      width: 455px;
      .text {
        font-size: 3.4rem;
        font-weight: 500;
        line-height: 1.82352;
        color: #fff;
        border-bottom: 3px solid #ffea00;
        display: inline;
        .yellow {
          color: #ffea00;
        }
      }
      &::after {
        content: "";
        width: 39px;
        height: 38px;
        display: inline-block;
        background: url(../img/arrow_yellow.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }
    }
    .img {
      max-width: calc(100% - 477px);
      margin-top: -10px;
      margin-left: 22px;
    }
  }
  .formWrap {
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    padding: 64px 50px 50px;
    &__ttl {
      font-size: 2.6rem;
      font-weight: 500;
      color: $color-theme;
      border-bottom: 1px solid #303030;
      padding-bottom: 20px;
      margin-bottom: 45px;
    }
    &__text {
      font-size: 1.8rem;
      margin-bottom: 35px;
    }
    .formCont {
      &__item {
        margin-top: 18px;
        border-bottom: 1px solid $color-theme;
        display: flex;
      }
      &__ttl {
        font-size: 1.8rem;
        font-weight: 500;
        color: $color-theme;
        background: $color-blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 25px;
        width: 280px;
        .required {
          font-size: 1.6rem;
          color: #fff;
          background: #d24147;
          padding: 0 7px;
        }
      }
      &__text {
        width: calc(100% - 280px);
        padding: 30px 20px;
        input {
          border: 2px solid #ebebeb;
          background: #f6f6f6;
          padding: 13px 20px;
          width: 100%;
        }
        textarea {
          border: 2px solid #ebebeb;
          background: #f6f6f6;
          padding: 13px 20px;
          width: 100%;
        }
      }
    }
    .btnWrap {
      margin-top: 40px;
    }
    .btn--form {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      background: $color-theme;
      @include mq-desktop {
        &:hover {
          opacity: .7;
        }
      }
      input {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1;
        color: #fff;
        min-width: 350px;
        padding: 37px 10px;
        background: url(../img/arrow_btn.png) no-repeat 5rem center;
        display: block;
      }
      &.back {
        background: #484848;
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactWrap {
    &__in {
      padding: 25px 3% 40px;
    }
    .telBox {
      padding: 25px 30% 25px 5px;
      max-width: 100%;
      margin: 35px auto 0;
      &::after {
        height: 124px;
        right: 5px;
      }
      &__ttl {
        font-size: 1.5rem;
        margin-bottom: 18px;
      }
      &__num {
        font-size: 3.4rem;
        &::before {
          width: 21px;
          height: 21px;
          margin-right: 3px;
        }
      }
      &__text {
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }
    .consulCase {
      margin-top: 33px;
      border: 5px solid #e7e7e7;
      padding: 35px 15px;
      background-size: 55% auto;
      &__ttl {
        font-size: 1.8rem;
        margin-bottom: 30px;
      }
      &List {
        &__item {
          font-size: 1.3rem;
          padding-left: 24px;
          &::before {
            width: 17px;
            height: 17px;
            top: 2px;
          }
          & + .consulCaseList__item {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .contactForm {
    padding: 0 3% 40px;
    &__ttlWrap {
      display: block;
      .contactForm__ttl {
        padding-top: 25px;
        padding-bottom: 60px;
        width: inherit;
        margin-left: -10%;
        .text {
          font-size: 2.1rem;
          line-height: 1.47619;
          border-bottom: 2px solid #ffea00;
        }
        &::after {
          width: 35px;
          height: 34px;
        }
      }
      .img {
        display: block;
        max-width: 50%;
        margin: -65px 0 0 auto;
      }
    }
    .formWrap {
      padding: 25px 15px 40px;
      &__ttl {
        font-size: 1.7rem;
        padding-bottom: 15px;
        margin-bottom: 20px;
      }
      &__text {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }
      .formCont {
        &__item {
          margin-top: 0;
          border-bottom: none;
          display: block;
        }
        &__ttl {
          font-size: 1.6rem;
          border-bottom: 2px solid $color-theme;
          justify-content: flex-start;
          padding: 13px 15px;
          width: calc(100% + 30px);
          margin-left: -15px;
          .required {
            font-size: 1.3rem;
            padding: 0 4px;
            margin-left: 10px;
          }
        }
        &__text {
          width: 100%;
          padding: 17px 0;
          input {
            padding: 10px 12px;
          }
          textarea {
            padding: 10px 12px;
          }
        }
      }
      .btnWrap {
        margin-top: 20px;
        text-align: center;
      }
      .btn--form {
        width: 80%;
        input {
          font-size: 1.5rem;
          padding: 22px 10px;
          min-width: 100%;
          background: url(../img/arrow_btn.png) no-repeat 3rem center;
          background-size: auto 26px;
        }
      }
    }
  }
}